import React from "react";

const Index = ({ order }) => {
    // Function to format date in "24 Nov 2022" pattern
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-6">
          <h6>Order Details</h6>
          <p className="mb-1 lh-lg">
            Order Date: <span className="text-dark">{formatDate(order.date)}</span>
            <br />
            Order Total: <span className="text-dark">Rs {order.summary.grandTotal}</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
