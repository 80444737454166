import React from "react";

const Images = ({ review, setReview }) => {
  // Function to handle image deletion
  const handleDeleteImage = (index) => {
    // Create a copy of the review object
    const updatedReview = { ...review };
    // Remove the image at the specified index from the images array
    updatedReview.images.splice(index, 1);
    // Update the state with the modified review object
    setReview(updatedReview);
  };

  // Function to handle image upload
  const handleImageUpload = (event) => {
    // Get the uploaded files
    const files = event.target.files;
    // Create a copy of the review object
    const updatedReview = { ...review };
    // Iterate over uploaded files
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // Create a FileReader object to read the file
      const reader = new FileReader();
      // Callback function to handle the file reading
      reader.onload = () => {
        // Push the base64 encoded image data to the images array
        updatedReview.images.push(reader.result);
        // Update the state with the modified review object
        setReview(updatedReview);
      };
      // Read the file as a data URL (base64 encoded string)
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="border-bottom py-4 mb-4">
        <h5>Add photos (Optional)</h5>
        <p>Shoppers find images more helpful than text alone.</p>
        {/* <!-- Display uploaded images --> */}
        <div className="row">
          {review.images.map((image, index) => (
            <div className="col-md-3 mb-3" key={index}>
              <div className="position-relative">
                {/* Display image */}
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  className="img-fluid"
                />
                {/* Delete icon */}
                <button
                  type="button"
                  className="btn btn-sm btn-danger position-absolute top-0 start-0 m-2"
                  onClick={() => handleDeleteImage(index)}
                >
                  <i className="bi bi-trash"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
        {/* <!-- form --> */}
        <form action="#" className="dropzone profile-dropzone">
          <div className="fallback">
            <input
              name="file"
              type="file"
              multiple
              onChange={handleImageUpload}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Images;
