import React from "react";
import Search from "./Search";
import Filters from "./Filters";

const Index = ({ setOrders, orders, defaultOrders, getOrders }) => {
  return (
    <>
      <div className="px-6 py-6 ">
        <div className="row justify-content-between">
          {/* <!-- form --> */}
          <Search
            setOrders={setOrders}
            orders={orders}
            defaultOrders={defaultOrders}
            getOrders={getOrders}
          />
          {/* <!-- select option --> */}
          <Filters
            setOrders={setOrders}
            defaultOrders={defaultOrders}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
