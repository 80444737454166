import React, { useState } from "react";
import Delete from "./Delete";
import Edit from "./Edit";

const Index = ({
  category,
  setCategories,
  categories,
  setDefaultCategories,
  defaultCategories,
}) => {
  const [action, setAction] = useState(null);

  const handleDelete = (id) => {
    // Handle delete action here
    setAction("delete");
  };

  const handleEdit = () => {
    // Handle edit action here
    setAction("edit");
  };

  return (
    <div className="dropdown">
      {action ? null : (
        <a
          href="#"
          className="text-reset"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="feather-icon icon-more-vertical fs-5"></i>
        </a>
      )}
      {action ? (
        action === "delete" ? (
          <>
            <Delete
              category={category}
              setAction={setAction}
              setCategories={setCategories}
              categories={categories}
              setDefaultCategories={setDefaultCategories}
              defaultCategories={defaultCategories}
            />
          </>
        ) : (
          <>
            <Edit category={category} setAction={setAction} />
          </>
        )
      ) : (
        <ul className="dropdown-menu">
          <li>
            <button
              className="dropdown-item"
              onClick={() => {
                handleDelete(category._id);
              }}
            >
              <i className="bi bi-trash me-3"></i>Delete
            </button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={() => {
                handleEdit();
              }}
            >
              <i className="bi bi-pencil-square me-3"></i>Edit
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Index;
