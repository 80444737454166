import React from "react";
import Loader from "../Loader";
import Error from "../Error";
import Order from "./Order";

const Index = ({ loading, error, orders, setOrders }) => {
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Error />
      </>
    );
  }

  return (
    <>
      <div className="card-body p-0">
        {/* <!-- table --> */}
        <div className="table-responsive">
          <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
            <thead className="bg-light">
              <tr>
                <th>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="checkAll"
                    />
                    <label className="form-check-label" for="checkAll"></label>
                  </div>
                </th>
                <th>Customer</th>
                <th>Product(s)</th>
                <th>Status</th>
                <th>Address</th>
                <th>Grand Total</th>
                <th>Ordered at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <>
                  <Order order={order} setOrders={setOrders} />
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Index;
