import React, { useContext, useEffect } from "react";
import SelectContext from "../../../../../context/SelectContext";

const Index = ({ product }) => {
  const { checkedProducts, toggleCheckedSingle } = useContext(SelectContext);

  const handleChange = () => {
    toggleCheckedSingle(product);
  };

  const isChecked = checkedProducts.some(
    (checkedProduct) => checkedProduct._id === product._id
  );

  return (
    <>
      <td>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={handleChange}
            value=""
            checked={isChecked}
            id={`product_${product._id}`}
          />
          <label className="form-check-label" for="productOne"></label>
        </div>
      </td>
    </>
  );
};

export default Index;
