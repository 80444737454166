import React, { useState } from "react";

const Variants = ({
  productImages,
  currentImage,
  setProductImages,
  rowIndex,
  index,
}) => {
  const [showVariant, setVarianShow] = useState(false);
  const [color, setColor] = useState("");

  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleInputChange = (e) => {
    setColor(capitalizeFirstLetter(e.target.value));
  };

  const handleAddColor = () => {
    const updatedImage = {
      ...currentImage,
      variant: color,
    };

    setProductImages((prevProductImages) => {
      // Find the index of the image to update
      const imageIndex = prevProductImages.findIndex(
        (img) => img.image === currentImage.image
      );

      if (imageIndex !== -1) {
        // Create a new array with the updated image
        const updatedImages = [...prevProductImages];
        updatedImages[imageIndex] = updatedImage;
        return updatedImages;
      }

      // If the image is not found, return the previous state
      return prevProductImages;
    });

    setColor("");
    setVarianShow(false);
  };

  const handleAbortColor = () => {
    setVarianShow(false);
  };

  const addVariant = () => {
    setVarianShow(true);
  };
  return (
    <>
      {currentImage.variant && (
        <>
          <div
            className="mx-8"
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              background: "black",
              color: "white",
              padding: "2px",
              border: "none",
              cursor: "pointer",
            }}
          >
            {currentImage.variant}
          </div>
        </>
      )}

      {!currentImage.variant && (
        <>
          <div
            className="mx-8"
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              background: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => addVariant(rowIndex, index)}
          >
            ✏️
          </div>
          {showVariant && (
            <>
              <div className="p-2">
                <input
                  type="text"
                  className="form-control"
                  value={color}
                  onChange={handleInputChange}
                  placeholder="Type a color"
                  style={{ marginBottom: "10px" }}
                />
                <button
                  onClick={handleAddColor}
                  className="btn btn-success btn-sm"
                >
                  Add Variant
                </button>
                <button
                  onClick={handleAbortColor}
                  className="btn btn-secondary btn-sm mx-2"
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Variants;
