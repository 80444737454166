import React, { useContext, useState } from "react";
import ImaegeUpload from "../../../utils/ImageUpload";
import CategoriesContext from "../../../context/CategoriesContext";
import CatProContext from "../../../context/CatProContext";

const Submit = ({ categoryInfo, setCategoryInfo, setCategories }) => {
  // State variables for errors and success message
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  //Context
  const { createCategory } = useContext(CategoriesContext);
  const { uploadImagesOnCloudStorage } = useContext(CatProContext);

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        // No validation errors, proceed with submission
        setSuccessMessage("Uploading the text data on database");
        const response = await createCategory({
          name: categoryInfo.name,
          metaTitle: categoryInfo.metaTitle,
          metaDescription: categoryInfo.metaDescription,
        });
        if (response.success) {
          // Category creation successful

          // Update categories in client end
          setCategories((prevParents) => [...prevParents, response.category]);

          // Now upload images f needed
          if (categoryInfo.image) {
            setSuccessMessage("Now uploading image data on cloud storage...");
            const image = [categoryInfo.image];
            const uploadId = response.uploadId;
            await ImaegeUpload(
              uploadImagesOnCloudStorage,
              image,
              "Categories",
              uploadId,
              handleImageUpload
            );
          } else {
            setSuccessMessage("Category has been created");
          }
        } else {
          // Category creation failed, update errors state
          setErrors({ server: response.message || "Category creation failed" });
          setSuccessMessage("");
        }
      } catch (error) {
        // Handle API call error
        setErrors({ server: error.message });
        setSuccessMessage("");
      }
    } else {
      // Validation errors found, update errors state
      setErrors(validationErrors);
      setSuccessMessage("");
    }
  };

  const handleImageUpload = (response) => {
    if (response.success) {
      setSuccessMessage("New Category Created");
      setErrors({});
      setCategoryInfo({
        image: null,
        type: "Select Category Type",
        parentCategory: "Select a parent",
        name: "",
        metaTitle: "",
        metaDescription: "",
      });
    } else {
      // Category creation failed, update errors state
      setErrors({
        server:
          response.message ||
          "Some error occure while trying to upload image on cloud sotrage",
      });
    }
  };

  const validateForm = () => {
    let errors = {};

    // Validation checks
    // if (!categoryInfo.image) {
    //   errors.image = "Image is required";
    // }
    if (!categoryInfo.name.trim()) {
      errors.name = "Name is required";
    }
    // if (!categoryInfo.metaTitle.trim()) {
    //   errors.metaTitle = "Meta Title is required";
    // }
    // if (!categoryInfo.metaDescription.trim()) {
    //   errors.metaDescription = "Meta Description is required";
    // }

    return errors;
  };

  //
  return (
    <>
      <button className="btn btn-primary ms-2" onClick={handleSubmit}>
        Save
      </button>
      {/* Display error messages */}
      {Object.keys(errors).length > 0 && (
        <div className="text-danger mt-2">
          {Object.values(errors).map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}
      {/* Display success message */}
      {successMessage && (
        <div className="text-success mt-2">{successMessage}</div>
      )}
    </>
  );
};

export default Submit;
