import React from "react";
import Loader from "../Loader";
import Error from "../Error";

const Index = ({ error }) => {
  
  if (error) {
    return <Error error={error} />;
  }

};

export default Index;
