import React, { useContext } from "react";
import ImageUpload from "../../../utils/ImageUpload.js";
import CatProContext from "../../../context/CatProContext.js";
import SliderContext from "../../../context/SliderContext.js";

const Submit = ({
  message,
  selectedImage,
  category,
  setMessage,
  setSelectedImage,
}) => {
  const { uploadImagesOnCloudStorage } = useContext(CatProContext);
  const { createCategory } = useContext(SliderContext);

  const checkDimensions = async () => {
    if (!selectedImage) {
      setMessage("No image selected");
      return;
    }

    if (!category) {
      setMessage("Invalid category");
      return;
    }

    const { width, height } = selectedImage;

    if (width === 1980 && height === 821) {
      setMessage("New slider image is being uploaded. Please wait...");
      const response = await createCategory(category);
      if (response.success) {
        const uploadId = response.uploadId;
        await ImageUpload(
          uploadImagesOnCloudStorage,
          [selectedImage.base64],
          "Slider",
          uploadId,
          handleUpload
        );
      } else {
        setMessage("Some error occurred while uploading category");
      }
    } else {
      setMessage(
        `Invalid image width & height to upload. Slider images must be 1980 * 821 pixels. The current image width & height is ${width} * ${height}.`
      );
    }
  };

  const handleUpload = (response) => {
    if (!response.success) {
      setMessage(<div className="text-danger">{response.error}</div>);
    } else {
      setMessage(
        <div className="text-success">
          Image has been uploaded successfully!
        </div>
      );
      setSelectedImage(null);
    }
  };

  return (
    <>
      <div className="text-center">
        <button className="btn btn-primary" onClick={checkDimensions}>
          Upload Slider Image
        </button>
      </div>
      {message && (
        <div className="text-center mt-3">
          <p>{message}</p>
        </div>
      )}
    </>
  );
};

export default Submit;
