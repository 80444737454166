import { createContext, useState, useEffect } from "react";
import host from "./host";

const CommonContext = createContext();

export const CommonProvider = ({ children }) => {
  // Brands
  // osc
  // minnie
  const brand = "osc";

  // Active user
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userData")) || ""
  );

  // Render user
  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await getUser();
        if (response.success) {
          setUser(response.user);
        } else {
          if (response.error === "User not found") {
            localStorage.removeItem("userData");
            setUser("");
          }
        }
      } catch (error) {
        //
      } finally {
        //
      }
    }

    fetchUser();
  }, []);

  // Update Live Tv
  const liveTV = async (source) => {
    // API Call
    const response = await fetch(`${host}/admin/liveTv/updateSource`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ source }),
    });
    const json = await response.json();
    return json;
  };

  //get list of Orders
  const getUser = async () => {
    //API Call
    const response = await fetch(
      `${host}/auth/user/userDetails/getUser/${user?._id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    return json;
  };

  // Potentially update user information
  const updateUser = (updatedUser) => {
    localStorage.setItem("userData", JSON.stringify(updatedUser));
    setUser(updatedUser);
  };

  // Potentially delete user information
  const deleteUser = () => {
    localStorage.clear();
    setUser("");
  };

  return (
    <CommonContext.Provider
      value={{ brand, user, setUser, updateUser, deleteUser, liveTV }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
