import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { CartProvider } from "./Components/context/CatProContext";
import { OrdersProvider } from "./Components/context/OrdersContext";
import { CommentsProvider } from "./Components/context/CommentsContext";
import { CategoriesProvider } from "./Components/context/CategoriesContext";
import { SelectProvider } from "./Components/context/SelectContext";
import { BadgesProvider } from "./Components/context/BadgesContext";
import { SliderProvider } from "./Components/context/SliderContext";
import { ImagesProvider } from "./Components/context/ImagesContext";
import { CommonProvider } from "./Components/context/CommonContext";
import { EmailProvider } from "./Components/context/EmailContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CartProvider>
      <OrdersProvider>
        <CommentsProvider>
          <CategoriesProvider>
            <SelectProvider>
              <BadgesProvider>
                <SliderProvider>
                  <ImagesProvider>
                    <CommonProvider>
                      <EmailProvider>
                        <HashRouter>
                          <App />
                        </HashRouter>
                      </EmailProvider>
                    </CommonProvider>
                  </ImagesProvider>
                </SliderProvider>
              </BadgesProvider>
            </SelectProvider>
          </CategoriesProvider>
        </CommentsProvider>
      </OrdersProvider>
    </CartProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
