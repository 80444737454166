const base64ToBlobObj = async (base64Images) => {
  // Convert base64 strings to Blob objects
  const files = base64Images.map((item, index) => {
    const base64String = item.image;
    const byteCharacters = atob(base64String.split(",")[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/jpeg" });
    const file = new File([blob], `image_${index}.jpg`, { type: "image/jpeg" });

    return {
      blobObj: file,
      variant: item.variant,
    };
  });

  return files;
};

export default base64ToBlobObj;
