import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div className="row mb-8">
        <div className="col-md-12">
          {/* <!-- page header --> */}
          <div className="d-md-flex justify-content-between align-items-center">
            <div>
              <h2>Orders</h2>
              {/* <!-- breacrumb --> */}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#" className="text-inherit">
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Orders
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
