import React, { useContext, useState } from "react";
import CategoriesContext from "../../../context/CategoriesContext";
import { FaSync } from "react-icons/fa";

const Search = ({ setCategories, categories, defaultCategories }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { getCategories } = useContext(CategoriesContext);

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.trim() === "") {
      // If the search query is empty, display all original categories without filtering
      setCategories(defaultCategories);
    } else {
      // Otherwise, filter the categories based on the search query
      const filteredCategories = categories.filter((category) =>
        new RegExp(query, "i").test(category.name)
      );
      setCategories(filteredCategories);
    }
  };

  const handleRefresh = () => {
    getCategories();
  };

  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-lg-0">
        <form className="d-flex" role="search">
          <input
            className="form-control"
            type="search"
            placeholder="Search a category"
            aria-label="Search"
            value={searchQuery}
            onChange={handleSearch}
          />
          <button
            type="button"
            onClick={handleRefresh}
            className="btn btn-link"
          >
            <FaSync />
          </button>
        </form>
      </div>
    </>
  );
};

export default Search;
