import React from "react";
import Top from "./Top/Index";
import CustomerDetail from "./CustomerDetail/Index";
import Address from "./Address/Index";
import OrderDetail from "./Order/Index";

const Index = ({ order, setLoading, setError }) => {
  return (
    <>
      <div className="card-body p-6">
        <Top order={order} setLoading={setLoading} setError={setError} />
        <div className="mt-8">
          <div className="row">
            <CustomerDetail order={order} />
            <Address order={order} />
            <OrderDetail order={order} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
