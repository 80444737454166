import React from "react";
import { Link } from "react-router-dom";
import Products from "../Common/Products/Products";
import matchingProducts from "../Common/Functions/matchingProducts";

const Index = ({ product, products }) => {
  const relatedProducts = matchingProducts(product.Categories, products);

  return (
    <section className="my-lg-14 my-14">
      <div className="container">
        {/* <!-- row --> */}
        <div className="row">
          <div className="col-12">
            {/* <!-- heading --> */}
            <h3>Related Items</h3>
          </div>
        </div>
        {/* <!-- row --> */}
        <div className="row g-4 row-cols-lg-5 row-cols-2 row-cols-md-2 mt-2">
          <Products
            products={relatedProducts}
            loadingProducts={false}
            productsError={false}
            id="related_products"
          />
        </div>
      </div>
    </section>
  );
};

export default Index;
