import React, { useState, useEffect, useContext } from "react";
import CategoriesContext from "../../../../../../context/CategoriesContext";

const Index = ({ product, setProducts }) => {
  const initialCategory = product.Categories[0] || "";
  const [category, setCategory] = useState(initialCategory);
  const [isCategoryChanged, setIsCategoryChanged] = useState(false);
  const [loading, setLoading] = useState(false);

  const { updateCategory } = useContext(CategoriesContext);

  useEffect(() => {
    if (category !== initialCategory) {
      setIsCategoryChanged(true);
    } else {
      setIsCategoryChanged(false);
    }
  }, [category, initialCategory]);

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const handleCategoryChange = (e) => {
    const capitalizedCategory = capitalizeWords(e.target.value);
    setCategory(capitalizedCategory);
  };

  const handleRename = async () => {
    setLoading(true);
    const response = await updateCategory(category, product._id);
    if (response.success) {
      setLoading(false);
      // Update products state client-side
      setProducts((prevProducts) =>
        prevProducts.map((p) =>
          p._id === product._id
            ? { ...p, Categories: [category, ...p.Categories.slice(1)] }
            : p
        )
      );
    }
  };

  return (
    <div className="container mt-3">
      <div className="mb-3">
        <label htmlFor="category" className="form-label">
          Category
        </label>
        <input
          type="text"
          id="category"
          className="form-control"
          value={category}
          onChange={handleCategoryChange}
        />
      </div>
      <button
        className="btn btn-primary"
        onClick={handleRename}
        disabled={!isCategoryChanged}
      >
        Rename
      </button>
      {loading && <label className="mx-2">Updating...</label>}
    </div>
  );
};

export default Index;
