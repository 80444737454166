import React, { useState } from "react";
import FilterCategories from "./Categories/Index";

const Index = ({ setProducts, defaultProducts, products }) => {
  const [selectedOption, setSelectedOption] = useState("ascending");
  const [category, setCategory] = useState(false);

  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    const allProducts = defaultProducts;

    switch (selectedValue) {
      case "ascending":
        // Sort products in ascending order
        setProducts(defaultProducts);
        setCategory(false);
        break;
      case "descending":
        // Sort products in descending order
        setProducts([...allProducts].reverse());
        setCategory(false);
        break;
      case "active":
        // Filter products with status: active
        setProducts(allProducts.filter((product) => product.InStock === true));
        setCategory(false);
        break;
      case "disabled":
        // Filter products with status: disabled
        setProducts(allProducts.filter((product) => product.InStock === false));
        setCategory(false);
        break;
      case "categories":
        setCategory(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="col-lg-2 col-md-4 col-12">
        <select
          className="form-select"
          value={selectedOption}
          onChange={handleSortChange}
        >
          <option value="ascending">Default</option>
          <option value="descending">Descending</option>
          <option value="active">Status: Active</option>
          <option value="disabled">Status: Disabled</option>
          <option value="categories">Filter By Categories</option>
        </select>
      </div>
      {category && (
        <FilterCategories
          setProducts={setProducts}
          products={products}
          defaultProducts={defaultProducts}
        />
      )}
    </>
  );
};

export default Index;
