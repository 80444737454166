import React, { useState } from "react";

const Index = ({ categoryInfo, setCategoryInfo }) => {
  // Function to handle file input change
  const handleFileChange = (e) => {
    const selectedImage = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const imageUrl = reader.result; // Get the URL of the selected image
      setCategoryInfo({
        ...categoryInfo,
        image: { link: imageUrl, type: "New" },
      }); // Set the image URL in the parent component state
    };

    reader.readAsDataURL(selectedImage); // Read the selected image as data URL
  };
  return (
    <>
      <h4 className="mb-5 h5">Category Image (Optional)</h4>
      <div className="mb-4 d-flex">
        <div className="position-relative">
          <img
            className="image  icon-shape icon-xxxl bg-light rounded-4"
            src="../assets/images/icons/bakery.svg"
            alt="Image"
          />

          <div className="file-upload position-absolute end-0 top-0 mt-n2 me-n1">
            <input
              type="file"
              className="file-input "
              onChange={handleFileChange}
            />
            <span className="icon-shape icon-sm rounded-circle bg-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="currentColor"
                className="bi bi-pencil-fill text-muted"
                viewBox="0 0 16 16"
              >
                <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
              </svg>
            </span>
          </div>
        </div>
        {/* Conditionally render the uploaded image */}
        {categoryInfo.image.link && (
          <img
            className="image icon-shape bg-light rounded-4 ms-3"
            src={categoryInfo.image.link}
            alt="Uploaded Image"
            style={{ width: "100px", height: "100px" }} // Specify width and height
          />
        )}
      </div>
    </>
  );
};

export default Index;
