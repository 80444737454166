import React, { useContext, useEffect, useState } from "react";
import CatProContext from "../../../../../context/CatProContext";
import Products from "../../../List/Index";
import TopBar from "../../../TopBar/Index";

const Index = ({ productsIds }) => {
  const { defaultProducts, loadingProducts, productsError, getProducts } =
    useContext(CatProContext);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Filter the defaultProducts to only include those products whose IDs are not in productsIds
    const filteredProducts = defaultProducts.filter(
      (product) => !productsIds.includes(product._id)
    );
    setProducts(filteredProducts);
  }, [defaultProducts, productsIds]);

  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <div className="accordion" id="moreProductsAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className={`accordion-button ${expanded ? "" : "collapsed"}`}
              type="button"
              onClick={handleToggleExpand}
              aria-expanded={expanded}
            >
              Add More Products
            </button>
          </h2>
          <div className={`accordion-collapse collapse ${expanded ? "show" : ""}`}>
            <div className="accordion-body">
              <TopBar
                setProducts={setProducts}
                products={products}
                defaultProducts={defaultProducts}
                getProducts={getProducts}
              />
              <Products
                loading={loadingProducts}
                error={productsError}
                products={products}
                setProducts={setProducts}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
