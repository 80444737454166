const functionToAddChild = (
  childName,
  setTreeData,
  selectedNode,
  treeData,
  setAction,
  leaf
) => {
  if (childName !== "") {
    // Check if the childName is already used anywhere in the treeData or uniqueCategories
    if (isNameUnique(treeData, childName)) {
      // Find the node in the treeData and update its name
      const updatedChart = addChild(
        treeData,
        selectedNode.data.name,
        childName,
        leaf
      );

      setTreeData(updatedChart);
      setAction(null);
    } else {
      alert(
        "Child name must be unique within the entire tree structure."
      );
    }
  }
};

// Helper function to check if a name is unique within the treeData
const isNameUnique = (tree, nameToCheck) => {
  if (tree.name === nameToCheck) {
    return false; // Found duplicate name at current node
  }

  if (tree.children) {
    for (let child of tree.children) {
      if (!isNameUnique(child, nameToCheck)) {
        return false; // Found duplicate name in one of the children
      }
    }
  }

  return true; // Name is unique in this subtree
};

//   Helper function
const addChild = (tree, nodeName, childName, leaf) => {
  if (tree.name === nodeName) {
    const updatedNode = {
      ...tree,
      children: [...(tree.children || []), { name: childName, leaf: leaf }],
    };
    return updatedNode;
  }

  if (tree.children) {
    return {
      ...tree,
      children: tree.children.map((child) =>
        addChild(child, nodeName, childName, leaf)
      ),
    };
  }

  return tree;
};

export default functionToAddChild;
