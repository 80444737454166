import React, { useState } from "react";

const Filters = ({ setOrders, defaultOrders }) => {
  const [selectedOption, setSelectedOption] = useState("ascending");

  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    const allOrders = defaultOrders

    switch (selectedValue) {
      case "ascending":
        // Sort products in ascending order
        setOrders(defaultOrders);
        break;
      case "descending":
        // Sort products in descending order
        setOrders([...allOrders].reverse());
        break;
      case "Success":
        // Filter products with status: active
        setOrders(allOrders.filter((order) => order.status === selectedValue));
        break;
      case "Processing":
        // Filter products with status: disabled
        setOrders(allOrders.filter((order) => order.status === selectedValue));
        break;
        case "Cancel":
        // Filter products with status: disabled
        setOrders(allOrders.filter((order) => order.status === selectedValue));
        break;
      default:
        break;
    }
  };

  return (
    <div className="col-lg-2 col-md-4 col-12">
      <select
        className="form-select"
        value={selectedOption}
        onChange={handleSortChange}
      >
        <option value="ascending">Default</option>
        <option value="descending">Descending</option>
        <option value="Success">Status: Success</option>
        <option value="Processing">Status: Processing</option>
        <option value="Cancel">Status: Cancel</option>
      </select>
    </div>
  );
};

export default Filters;
