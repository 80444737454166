import React, { useState } from "react";
import Top from "./Top";
import Image from "./Image/Index";
import Information from "./CategoryInfo/Index";
import Submit from "./Submit";
import { useLocation } from "react-router-dom";

const Index = () => {
  const location = useLocation();
  const { category } = location.state;

  const [categoryInfo, setCategoryInfo] = useState({
    image: { link: category.icon[0]?.link, type: "Old" },
    name: category.name,
    metaTitle: category.metaData.title,
    metaDescription: category.metaData.description,
  });

  return (
    <main className="main-content-wrapper">
      <div className="container">
        <Top />
        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="card mb-6 shadow border-0">
              <div className="card-body p-6 ">
                <Image
                  categoryInfo={categoryInfo}
                  setCategoryInfo={setCategoryInfo}
                />
                <Information
                  categoryInfo={categoryInfo}
                  setCategoryInfo={setCategoryInfo}
                />
                <div className="col-lg-12">
                  <Submit
                    categoryInfo={categoryInfo}
                    setCategoryInfo={setCategoryInfo}
                    category={category}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Index;
