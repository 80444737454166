import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Images from "./Images";
import Off from "./Off";
import Unrelated from "./Unrelated";

const Index = ({ product }) => {
  const [selectedImage, setSelectedImage] = useState(
    product.ProductImages.length > 0 ? product.ProductImages[0].link : ""
  );

  // Watch for changes in the product or selectedImage state
  useEffect(() => {
    // Update selectedImage whenever the product changes
    setSelectedImage(
      product.ProductImages.length > 0 ? product.ProductImages[0].link : ""
    );
  }, [product]);


  return (
    <section className="mt-8">
      <div className="container">
        <div className="row">
          <Images
            product={product}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
          />
          <div className="col-lg-6">
            <div className="ps-lg-8 mt-6 mt-lg-0">
              <label className="mb-4 d-block">
                <div>
                  <p>
                    <strong>Categories:</strong>
                  </p>
                  <div className="d-flex flex-wrap">
                    {product.Categories.map((category, index) => (
                      <div key={index} className="m-1">
                        <span className="btn btn-outline-secondary btn-sm">
                          {category}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </label>
              <h2 className="mb-1 h1">{product.Title}</h2>
              {/* <div className="mb-4">
                <small className="text-warning">
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-fill"></i>
                  <i className="bi bi-star-half"></i>
                </small>
                <a href="#" className="ms-2">
                  (30 reviews)
                </a>
              </div> */}
              <div className="fs-4">
                <span className="fw-bold text-dark">
                  Rs{product.ProductPrice.salePrice}
                </span>
                {product.ProductPrice.salePrice !==
                  product.ProductPrice.regularPrice && (
                  <>
                    <span className="text-decoration-line-through text-danger">
                      Rs{product.ProductPrice.regularPrice}
                    </span>
                    <Off product={product} />
                  </>
                )}
              </div>
              <hr className="my-6" />
              {/* <Unrelated /> */}
              <div className="mt-3 row justify-content-start g-2 align-items-center">
                <div className="col-md-4 col-5">
                  {/* <!-- btn --> */}
                  <label
                    className="btn btn-light"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    aria-label="Compare"
                  >
                    <i className="bi bi-arrow-left-right"></i>
                  </label>
                </div>
              </div>
              <hr className="my-6" />
              <div>
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <td>Availability:</td>
                      <td>{product.InStock ? "In Stock" : "Out of Stock"}</td>
                    </tr>
                    <tr>
                      <td>Shipping Charges:</td>
                      <td>
                        <small>
                          {product.ShippingCharges ? (
                            <>Rs {product.ShippingCharges}</>
                          ) : (
                            "Free"
                          )}
                        </small>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <label className="mb-4 d-block">
                  <div>
                    <p>
                      <strong>Payment Methods:</strong>
                    </p>
                    <div className="d-flex flex-wrap">
                      {product.PaymentMethods.map((paymentMethod, index) => (
                        <div key={index} className="m-1">
                          <span className="btn btn-outline-secondary btn-sm">
                            {paymentMethod}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
