import React from "react";
import Loader from "../Loader";
import Error from "../Error";
import Product from "./Product/Product";
import SelectAll from "./Select/SelectAll/Index";

const Index = ({ loading, error, products, setProducts }) => {
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Error error={error} />
      </>
    );
  }

  return (
    <>
      <div className="card-body p-0">
        {/* <!-- table --> */}
        <div className="table-responsive">
          <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
            <thead className="bg-light">
              <tr>
                <SelectAll products={products} />
                <th>Image</th>
                <th>Proudct Name</th>
                <th>Category</th>
                <th>Stock</th>
                <th>Price</th>
                <th>Created at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <>
                  <Product product={product} setProducts={setProducts} />
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Index;
