import React, { useEffect, useState } from "react";

const Index = ({ setValidationResults, resetForm }) => {
  const [selectedOptions, setSelectedOptions] = useState(["Cash on Delivery"]);

  //Reset data when product created
  useEffect(() => {
    if (resetForm) {
      setSelectedOptions([]);
    }
  }, [resetForm]);

  //   Update data
  useEffect(() => {
    setValidationResults((prevResults) => {
      return {
        ...prevResults,
        PaymentMethods: { error: "", data: selectedOptions },
      };
    });
  }, [selectedOptions, setValidationResults]);

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === "all") {
      setSelectedOptions(["Cash on Delivery", "Easypaisa/Jazzcash"]);
    } else if (!selectedOptions.includes(selectedOption)) {
      setSelectedOptions((prevOptions) => [...prevOptions, selectedOption]);
    }
  };

  const handleRemoveOption = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.filter((item) => item !== option)
    );
  };

  return (
    <>
      {selectedOptions.map((option, index) => (
        <span
          className="my-1"
          key={index}
          style={{
            display: "inline-block",
            backgroundColor: "#e0e0e0",
            borderRadius: "5px",
            padding: "15px",
            margin: "0 5px",
            position: "relative",
          }}
        >
          {option}
          <button
            onClick={() => handleRemoveOption(option)}
            style={{
              position: "absolute",
              top: "2px",
              right: "2px",
              background: "none",
              border: "none",
              cursor: "pointer",
              padding: "2px",
              fontSize: "10px", // Adjust the font size for the close icon
            }}
          >
            X
          </button>
        </span>
      ))}

      <div className="form-group">
        <label htmlFor="paymentMethod">Select Payment Methods:</label>
        <select
          className="form-control"
          id="paymentMethod"
          name="paymentMethod"
          onChange={handleSelectChange}
        >
          <option value="Cash on Delivery">Cash on Delivery</option>
          <option value="Easypaisa/Jazzcash">Easypaisa/Jazzcash</option>
          <option value="all">All</option>
        </select>
      </div>
    </>
  );
};

export default Index;