import { createContext, useState, useEffect } from "react";
import host from "./host";

const CommentsContext = createContext();

export const CommentsProvider = ({ children }) => {

  // Create a Review
  const createReview = async (review) => {
    //API Call
    const response = await fetch(`${host}/auth/user/reviews/createReview`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(review),
    });
    const json = await response.json();
    return json;
  };

  //get list of comments
  const getComments = async (productId) => {
    //API Call
    const response = await fetch(
      `${host}/auth/user/reviews/getComments/${productId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    return json;
  };

  return (
    <CommentsContext.Provider
      value={{
        createReview,
        getComments,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};

export default CommentsContext;
