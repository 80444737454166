import React, { useContext, useState } from "react";
import Response from "../../../../Fixed/Response/Index";
import SelectContext from "../../../../context/SelectContext";

const Submit = ({ sectionName, setSectionName, products }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { createSection } = useContext(SelectContext);

  const handleSubmit = async () => {
    if (products.length === 0) {
      setErrorMessage(
        "No products selected. The section can be created with at least one product."
      );
    } else if (sectionName.trim() === "") {
      setErrorMessage("Please write section name.");
    } else {
      setLoading(true);
      const productIds = products.map((product) => ({ id: product._id }));
      const response = await createSection(productIds, sectionName);
      if (response.success) {
        alert("New section successfully created");
        setSectionName("");
      } else {
        setErrorMessage(response.error);
      }
      setLoading(false)
    }
  };

  return (
    <>
      <button className="btn btn-primary mt-4" onClick={handleSubmit}>
        Submit
      </button>
      <Response
        loading={loading}
        error={errorMessage}
        setError={setErrorMessage}
      />
    </>
  );
};

export default Submit;
