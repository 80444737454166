import React from "react";
import Actions from "./Actions/Index";

const Category = ({
  category,
  setCategories,
  categories,
  setDefaultCategories,
  defaultCategories,
}) => {
  // Function to format date in "24 Nov 2022" pattern
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <tr key={category._id}>
      <td>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="productOne"
          />
          <label className="form-check-label" htmlFor="productOne"></label>
        </div>
      </td>
      <td>
        <a href="#!">
          {" "}
          <img
            src={
              category.icon.length > 0
                ? category.icon[0].link
                : "/default-image.jpg"
            } // Replace "/default-image.jpg" with your default image path
            alt=""
            className="icon-shape icon-md rounded-circle" // Add rounded-circle class for rounded images
            style={{ width: "50px", height: "50px" }} // Set width and height for consistent sizing
          />
        </a>
      </td>
      <td>
        <a href="#" className="text-reset">
          {category.name}
        </a>
      </td>
      <td>{formatDate(category.date)}</td>
      <td>
        <Actions
          category={category}
          setCategories={setCategories}
          categories={categories}
          setDefaultCategories={setDefaultCategories}
          defaultCategories={defaultCategories}
        />
      </td>
    </tr>
  );
};

export default Category;
