import React from "react";
import Name from "./Name/Index";
import MetaData from "./MetaData/Index";

const Index = ({ categoryInfo, setCategoryInfo, category }) => {
  return (
    <>
      <h4 className="mb-4 h5 mt-5">Category Information</h4>

      <div className="row">
        <Name categoryInfo={categoryInfo} setCategoryInfo={setCategoryInfo} />
        
        <div className="mb-3 col-lg-12 ">{/* <!-- input --> */}</div>
        <MetaData
          categoryInfo={categoryInfo}
          setCategoryInfo={setCategoryInfo}
        />
      </div>
    </>
  );
};

export default Index;
