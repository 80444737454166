import React from "react";

const Validation = ({ regularPrice, salePrice }) => {
  if (Number(salePrice) > Number(regularPrice)) {
    return (
      <>
        <label className="text-danger">
          Sale price must be less than or equal to regular price
        </label>
      </>
    );
  } else {
    return (
      <>
        <label className="text-success">Fine!</label>
      </>
    );
  }
};

export default Validation;
