import React from "react";

const Delete = ({ productImages, setProductImages, rowIndex, index }) => {
  // Function to delete an image by index
  const deleteImage = (rowIndex, index) => {
    // Copy the cropped images array
    const updatedProductImages = [...productImages];

    // Get the image object to be deleted
    const imageToDelete = updatedProductImages[rowIndex * 3 + index];

    // Check the type of the image
    if (imageToDelete.type === "Old") {
      // If it's an "Old" image and deletionFlag is true, remove the flag
      if (imageToDelete.deletionFlag) {
        updatedProductImages[rowIndex * 3 + index] = {
          ...imageToDelete,
          deletionFlag: false,
        };
      } else {
        // If it's an "Old" image and deletionFlag is false, set deletionFlag to true
        updatedProductImages[rowIndex * 3 + index] = {
          ...imageToDelete,
          deletionFlag: true,
        };
      }
    } else {
      // If it's a "New" image, simply remove it from the array
      updatedProductImages.splice(rowIndex * 3 + index, 1);
    }

    // Update the croppedImages state
    setProductImages(updatedProductImages);
  };
  return (
    <>
      <button
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          background: "transparent",
          border: "none",
          cursor: "pointer",
        }}
        onClick={() => deleteImage(rowIndex, index)}
      >
        🗑️
      </button>
    </>
  );
};

export default Delete;
