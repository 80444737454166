import React from "react";
import { RiFileCopyLine } from "react-icons/ri";

const Prompt = ({ validationResults }) => {
  const copyToClipboard = () => {
    const prompt = `
    Product Name: ${validationResults.Title.data}
    Categories: ${validationResults.Categories.data}
    Generate description for this product.
    
    Things to be notable before generating description
    1- It should attract customers to the product
    2- It should not be in the form of plain paragraphs. Instead, use icons, emojis, etc to make it look better
    3- Dont write anything else before or after the description because i will directly copy past it without editing anything
    4- It must be minimum 1000 characters long
    `
    navigator.clipboard.writeText(prompt);
  };
  return (
    <div className="mb-1" onClick={copyToClipboard} title="Copy Prompt">
      <RiFileCopyLine style={{ cursor: "pointer" }} size={20} />
    </div>
  );
};

export default Prompt;
