import React from "react";
import { Link } from "react-router-dom";

const Top = () => {
  return (
    <div className="row mb-8">
      <div className="col-md-12">
        <div className="d-md-flex justify-content-between align-items-center">
          {/* <!-- page header --> */}
          <div>
            <h2>Create Categories</h2>
            {/* <!-- breacrumb --> */}
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <a href="#" className="text-inherit">
                    Dashboard
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#" className="text-inherit">
                    Categories
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                Create Categories
                </li>
              </ol>
            </nav>
          </div>
          <div>
            <Link to="/categories" className="btn btn-light">
              Back to Categories
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Top;
