import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import "./utils/styles.css";

const Uploader = ({ setSelectedImage, setMessage, selectedImage }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      handleFileChange({ target: { files: [file] } });
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      const reader = new FileReader();
      reader.readAsDataURL(croppedImage);
      reader.onloadend = () => {
        setSelectedImage({
          file: croppedImage,
          width: 1980,
          height: 821,
          base64: reader.result, // Base64 data
        });
      };
      setMessage("");
      setImageSrc(null);
    } catch (e) {
      console.error(e);
      setMessage("Error cropping the image");
    }
  };

  return (
    <>
      <div
        className="border p-5 text-center"
        style={{ border: "2px dashed #ccc" }}
        onDrop={handleDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <input
          type="file"
          className="form-control-file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          id="fileInput"
          accept="image/*"
        />
        <label htmlFor="fileInput" className="btn btn-secondary">
          Click to select an image or drag & drop here
        </label>
      </div>

      {imageSrc && (
        <>
          <div className="crop-container">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1980 / 821}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="text-center">
            <button onClick={handleSave} className="btn btn-primary mt-3">
              Set
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Uploader;
