import React, { useState } from "react";
import functionToRenameNode from "../utils/renameNode";

const Index = ({ setTreeData, selectedNode, treeData, setAction }) => {
  const [newName, setNewName] = useState("");

  const handleRename = () => {
    functionToRenameNode(
      newName,
      setTreeData,
      selectedNode,
      treeData,
      setAction
    );
  };

  // Helper function to capitalize first letter of each word
  const capitalizeFirstLetter = (string) => {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChange = (e) => {
    const value = capitalizeFirstLetter(e.target.value);
    setNewName(value);
  };

  return (
    <div className="container mt-4">
      <div className="form-group">
        <label htmlFor="newName">Enter new name here:</label>
        <input
          autoComplete="off"
          type="text"
          className="form-control"
          id="newName"
          placeholder="Enter child name"
          value={newName}
          onChange={handleChange}
        />
      </div>
      <button
        disabled={newName === "" ? true : false}
        className="btn btn-primary mt-2"
        onClick={handleRename}
      >
        Save
      </button>
    </div>
  );
};

export default Index;
