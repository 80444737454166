import React from "react";
import Image from "./Image";

const Index = ({ comment }) => {
  // Function to format date in "24 Nov 2022" pattern
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <>
      <div className="d-flex border-bottom pb-6 mb-6">
        <img
          src={comment?.userId?.profile}
          alt=""
          className="rounded-circle avatar-lg"
        />
        <div className="ms-5">
          <h6 className="mb-1">{comment?.userId?.name}</h6>
          <p className="small">
            {" "}
            <span className="text-muted"> {formatDate(comment?.Date)}</span>
          </p>
          {/* <!-- rating --> */}
          <div className=" mb-2">
            <span className="ms-3 text-dark fw-bold">{comment?.heading}</span>
          </div>
          {/* <!-- text--> */}
          <p>{comment.content}</p>
          <div>
            {comment.images.map((image, index) => (
              <>
                <Image index={index} image={image} />
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
