import React, { useState } from "react";
import Submit from "./Submit";

const Index = ({ products }) => {
  const [sectionName, setSectionName] = useState("");

  const handleSectionNameChange = (event) => {
    const newSectionName = event.target.value;
    if (newSectionName.length <= 25) {
      setSectionName(newSectionName);
    }
  };

  return (
    <>
      <div className="mt-4">
        <label htmlFor="sectionName" className="form-label">
          Write name of the section (max 25 characters)
        </label>
        <input
          type="text"
          className="form-control"
          id="sectionName"
          value={sectionName}
          onChange={handleSectionNameChange}
          maxLength="25" // This will also enforce the limit on the input field itself
        />
      </div>
      <Submit
        sectionName={sectionName}
        setSectionName={setSectionName}
        products={products}
      />
    </>
  );
};

export default Index;
