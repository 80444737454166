import React, { useEffect, useState } from "react";

const Index = ({ setValidationResults, resetForm, product }) => {
  const [charges, setCharges] = useState(product.ShippingCharges);

  //Reset data when product created
  useEffect(() => {
    if (resetForm) {
      setCharges("");
    }
  }, [resetForm]);

  //Update data
  useEffect(() => {
    setValidationResults((prevResults) => {
      return {
        ...prevResults,
        ShippingCharges: { error: "", data: charges },
      };
    });
  }, [charges, setValidationResults]);

  const handleChange = (e) => {
    setCharges(e.target.value);
  };
  return (
    <>
      <div className="form-group mt-2">
        <label htmlFor="productCode">Shipping Charges (Optional)</label>
        <input
          type="number"
          className="form-control"
          id="productCode"
          value={charges}
          placeholder="Shipping charges"
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default Index;