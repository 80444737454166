import React, { useContext, useEffect, useState } from "react";
import SelectContext from "../../../../../context/SelectContext";

const Index = ({ products }) => {
  const { toggleCheckedAll } = useContext(SelectContext);

  const checkChange = () => {
    toggleCheckedAll(products); // Pass the products list to toggleCheckedAll
  };

  return (
    <>
      <th>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            onChange={checkChange}
            id="checkAll"
          />
          <label className="form-check-label" for="checkAll"></label>
        </div>
      </th>
    </>
  );
};

export default Index;
