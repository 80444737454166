import React, { useContext, useEffect, useState } from "react";
import Header from "./Header/Index";
import Details from "./Details/Index";
import OrdersContext from "../../../context/OrdersContext";
import Products from "./Products/Index";
import OtherDetail from "./OtherDetail/Index";
import "./styles.css";
import { useLocation } from "react-router-dom";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const location = useLocation();
  const { order } = location.state;

  // Function to display error message and automatically fade out after 3 seconds
  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError("");
      }, 10000); // Change duration as needed

      return () => clearTimeout(timeout);
    }
  }, [error]);

  const handlePrint = () => {
    const printContent = document.getElementById("print");
    if (printContent) {
      const originalContents = document.body.innerHTML;
      const printableContent = printContent.innerHTML;
      document.body.innerHTML = printableContent;
      window.print();
      document.body.innerHTML = originalContents;
    } else {
      console.error("Print content not found");
    }
  };
  
  return (
    <>
      {/* Add animated progress bar */}
      {loading && (
        <div className="progress-container">
          <div className="progress-bar"></div>
        </div>
      )}
      {/* Display error message with fade-in and fade-out effect */}
      {error && (
        <div role="alert" className="alert alert-danger error-container">
          <div className="error-message">{error}</div>
        </div>
      )}

      <main className="main-content-wrapper">
        <div className="container">
          <Header handlePrint={handlePrint} />
          <div className="row ">
            <div className="col-xl-12 col-12 mb-5">
              {/* <!-- card --> */}
              <div className="card h-100 card-lg">
                <div id="print">
                  <Details
                    order={order}
                    setError={setError}
                    setLoading={setLoading}
                  />
                  <Products
                    order={order}
                    setError={setError}
                    setLoading={setLoading}
                  />
                </div>
                <OtherDetail
                  order={order}
                  setError={setError}
                  setLoading={setLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Index;
