import React from "react";

const AllBadges = ({ allBadges, setAllBadges }) => {
  // Function to remove badge from product
  const handleRemoveBadge = (index) => {
    // Create a copy of the current badges array
    const updatedBadges = [...allBadges];
    // Remove the badge at the specified index
    updatedBadges.splice(index, 1);
    // Update the state with the modified badges array
    setAllBadges(updatedBadges);
  };

  if (allBadges.length === 0) {
    return (
      <div>
        <div>Badges: No badges added for this product</div>
      </div>
    );
  }
  return (
    <>
      <div>
        Badges:
        {allBadges.map((badge, index) => (
          <div
            key={index}
            className={`badge border border-${badge.color} text-dark mx-1`}
          >
            {badge.title}
            <button
              className="btn-close btn-sm"
              onClick={() => handleRemoveBadge(index)}
            ></button>
          </div>
        ))}
      </div>
    </>
  );
};

export default AllBadges;
