const ImageUpdate = async (
  base64Images,
  modal,
  updateId,
  updateProductImages,
  callback
) => {
  try {
    // Separate the images into two arrays based on their types
    const newImages = base64Images.filter((image) => image.type === "New");
    const oldImages = base64Images.filter((image) => image.type === "Old");

    // Convert base64 strings of new images to Blob objects
    const newFiles = newImages.map((image, index) => {
      const byteCharacters = atob(image.source.split(",")[1]);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "image/jpeg" });
      return {
        file: new File([blob], `new_image_${index}.jpg`, { type: "image/jpeg" }),
        variant: image.variant
      };
    });

    // Create a FormData object and append new image files to it
    const formData = new FormData();
    newFiles.forEach((fileObj, index) => {
      formData.append(`newImages`, fileObj.file, `new_image_${index}.jpg`);
      formData.append(`variants[${index}]`, fileObj.variant); // Append the variant
    });

    // API Call for new images with oldImages query parameter
    const response = await updateProductImages(
      formData,
      modal,
      updateId,
      oldImages
    );

    if (callback) {
      callback(response);
    }
  } catch (error) {
    console.error("Error uploading the image/images:", error);
    // Handle error if needed
  }
};

export default ImageUpdate;
