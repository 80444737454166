import React, { useContext, useState } from "react";
import OrdersContext from "../../../../../context/OrdersContext";
import { useNavigate } from "react-router-dom";

const Index = ({ order, setError, setLoading }) => {
  const { updateShippingCost } = useContext(OrdersContext);
  const [cost, setCost] = useState(order.summary.shipping);

  const navigate = useNavigate();

  const handleClick = async (e) => {
    setLoading(true);
    const response = await updateShippingCost(order._id, cost);
    if (response.success) {
      navigate("/orders/orderDetail", { state: { order: response.order } });
    } else {
      setError(response.message);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setCost(Number(e.target.value));
  };
  return (
    <>
      <tr>
        <td className="border-bottom-0 pb-0"></td>
        <td className="border-bottom-0 pb-0"></td>
        <td colspan="1" className="fw-medium text-dark ">
          {/* <!-- text --> */}
          Shipping Cost
        </td>
        <td className="fw-medium text-dark  ">
          {/* <!-- Editable Shipping Cost --> */}
          <div className="input-group">
            <input
              value={cost}
              onChange={handleChange}
              type="text"
              className="form-control"
            />
            <button disabled={true} onClick={handleClick} className="btn btn-primary">
              Update
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default Index;
