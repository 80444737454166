import { createContext, useState, useEffect } from "react";
import host from "./host";

const OrdersContext = createContext();

export const OrdersProvider = ({ children }) => {
  // Orders
  const [orders, setOrders] = useState([]);
  const [defaultOrders, setDefaultOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(true);
  const [ordersError, setOrdersError] = useState(null);

  const [activeOrder, setActiveOrder] = useState({});

  // Render orders
  useEffect(() => {
    async function fetchOrders() {
      try {
        const response = await getOrders();
      } catch (error) {
        //
      }
    }

    fetchOrders();
  }, []);

  //get list of orders
  const getOrders = async () => {
    try {
      //API Call
      setLoadingOrders(true);
      const response = await fetch(`${host}/admin/orders/getOrders`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (json.success) {
        setOrders(json.data);
        setDefaultOrders(json.data);
        setLoadingOrders(false);
      } else {
        setOrdersError("Error fetching orders");
        setLoadingOrders(false);
      }
    } catch (error) {
      setOrdersError("Error fetching orders");
      setLoadingOrders(false);
    } finally {
      setLoadingOrders(false);
    }
  };

  // Update Order status
  const updateStatus = async (orderId, status) => {
    // API Call
    const response = await fetch(
      `${host}/admin/orders/updateStatus/${orderId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      }
    );
    const json = await response.json();
    return json;
  };

  // Update Order Shipping cost
  const updateShippingCost = async (orderId, cost) => {
    // API Call
    const response = await fetch(
      `${host}/admin/orders/updateShippingCost/${orderId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ cost }),
      }
    );
    const json = await response.json();
    return json;
  };

  // Update Order Note
  const updateNote = async (orderId, note) => {
    // API Call
    const response = await fetch(
      `${host}/admin/orders/updateNote/${orderId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ note }),
      }
    );
    const json = await response.json();
    return json;
  };

  return (
    <OrdersContext.Provider
      value={{
        getOrders,
        orders,
        setOrders,
        defaultOrders,
        loadingOrders,
        ordersError,
        activeOrder,
        setActiveOrder,
        updateStatus,
        updateShippingCost,
        updateNote,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export default OrdersContext;
