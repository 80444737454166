import React, { useContext, useState } from "react";
import CatProContext from "../../../../context/CatProContext";

const Delete = ({ product, setAction, setProducts }) => {
  const { deleteProduct } = useContext(CatProContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteHandler = async (id) => {
    try {
      // Show loader
      setLoading(true);
      setError(null);

      // Delete the product
      const response = await deleteProduct(id);

      // Hide loader
      setLoading(false);

      if (response.success) {
        // Remove the product from the products state
        setProducts((prevProducts) =>
          prevProducts.filter((prod) => prod._id !== id)
        );
        // Reset action
        setAction(null);
      } else {
        // Display error message
        setError(response.message);
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      // Display error message
      setError("An error occurred while deleting the product.");
      // Hide loader
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div>Deleting...</div>
      ) : error ? (
        <>
          <div>{error}</div>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => deleteHandler(product._id)}
          >
            Delete
          </button>
        </>
      ) : (
        <button
          className="btn btn-sm btn-danger"
          onClick={() => deleteHandler(product._id)}
        >
          Delete
        </button>
      )}
    </>
  );
};

export default Delete;
