import React, { useContext, useEffect, useState } from "react";
import Ratings from "./Ratings/Index";
import Comments from "./Comments/Index";
import CreateReview from "./CreateReview/Index";
import CommentsContext from "../../../../context/CommentsContext";
import CommonContext from "../../../../context/CommonContext";

const Index = ({ product }) => {
  const { user } = useContext(CommonContext);
  const { getComments } = useContext(CommentsContext);

  const [comments, setComments] = useState([]);
  const [defaultComments, setDefaultComments] = useState([]);
  const [loadingComments, setLoadingComments] = useState(true);
  const [commentsError, setCommentsError] = useState(null);

  const [review, setReview] = useState({
    heading: "",
    images: [],
    content: "",
    userId: user._id,
    productId: product._id,
  });

  // // Render comments
  useEffect(() => {
    async function fetchComments() {
      try {
        const response = await getComments(product._id);
        if (response.success) {
          setComments(response.data);
          setDefaultComments(response.data);
        } else {
          setCommentsError("Error fetching reviews");
        }
      } catch (error) {
        console.error("Error fetching reviews:", error);
        setCommentsError("Error fetching reviews");
      } finally {
        setLoadingComments(false);
      }
    }

    fetchComments();
  }, []);

  return (
    <>
      <div
        className="tab-pane fade"
        id="reviews-tab-pane"
        role="tabpanel"
        aria-labelledby="reviews-tab"
        tabIndex="0"
      >
        <div className="my-8">
          {/* <!-- row --> */}
          <div className="row">
            {/* <Ratings /> */}
            {/* <!-- col --> */}
            <div className="col-md-8">
              <div className="mb-5">
                <CreateReview
                  product={product}
                  review={review}
                  setReview={setReview}
                  setComments={setComments}
                  comments={comments}
                />
              </div>
              <Comments
                product={product}
                loadingComments={loadingComments}
                commentsError={commentsError}
                comments={comments}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
