import React from "react";
import { useDrop, useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes"; // Define your item types

const ChildDraggable = ({ child, parent, draggable }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CHILD,
    item: { childId: child, parent: parent }, // Include parent information
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    canDrag: draggable,
  });

  return (
    <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {child.name}
    </div>
  );
};

export default ChildDraggable;
