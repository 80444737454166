import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import timeMaker from "../utils/timeMaker";

const Index = ({ usersData, recordDay, setRecordDay }) => {
  // Extracting and sorting dates from usersData
  const datesArray = Array.from(
    new Set(usersData.map((user) => user.date))
  ).sort((a, b) => new Date(b) - new Date(a));

  const updateRecordDay = (date) => {
    // Filter users with the given date
    const data = dataAboutDate(date);
    setRecordDay({ day: date, data: data, date: date });
  };

  const dataAboutDate = (date) => {
    const data = usersData.filter((user) => user.date === date);
    return data;
  };

  return (
    <>
      <DropdownButton
        id="dropdown-basic-button"
        title={timeMaker(recordDay.date)}
        variant="outline-secondary"
        className="mb-2 mb-md-0"
      >
        {datesArray.map((date, index) => (
          <>
            <Dropdown.Item
              onClick={() => {
                updateRecordDay(date);
              }}
              key={index}
              className={`mb-2 bg-success text-white`}
            >
              {timeMaker(date)} ({dataAboutDate(date).length} Users)
            </Dropdown.Item>
          </>
        ))}
      </DropdownButton>
    </>
  );
};

export default Index;
