import React, { useState } from "react";

const Search = ({ setProducts, products, defaultProducts }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.trim() === "") {
      // If the search query is empty, display all original products without filtering
      setProducts(defaultProducts);
    } else {
      // Otherwise, filter the products based on the search query
      const filteredProducts = products.filter((product) =>
        new RegExp(query, "i").test(product.Categories[0])
      );
      setProducts(filteredProducts);
    }
  };

  return (
    <>
      <form className="d-flex mt-2" role="search">
        <input
          className="form-control"
          type="search"
          placeholder="Search Categories"
          aria-label="Search"
          value={searchQuery}
          onChange={handleSearch}
        />
      </form>
    </>
  );
};

export default Search;
