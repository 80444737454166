import React, { useContext, useState } from "react";
import Response from "../../../../../Fixed/Response/Index";
import SelectContext from "../../../../../context/SelectContext";

const Index = ({ productsIds, sectionId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const { updateSection } = useContext(SelectContext);

  const handleUpdate = async () => {
    setLoading(true);
    const response = await updateSection(sectionId, productsIds);
    if (!response.success) {
      setError(response.message);
    }
    setLoading(false);
  };
  return (
    <>
      <div>
        <button onClick={handleUpdate} className="btn btn-sm btn-primary">
          Save updates
        </button>
      </div>
      <Response loading={loading} error={error} setError={setError} />
    </>
  );
};

export default Index;
