import React, { useEffect, useState } from "react";
import UploadedImages from "./UploadedImages";
import ImageUploader from "./ImageUploader/Index";

const Index = ({ setValidationResults, resetForm, product }) => {
  const [productImages, setProductImages] = useState(
    product.ProductImages.map((image) => ({
      source: image.link,
      type: "Old",
      deletionFlag: false,
      identifier: image.identifier,
      variant: image.variant ?? null,
    }))
  );

  useEffect(() => {
    setProductImages(
      product.ProductImages.map((image) => ({
        source: image.link,
        type: "Old",
        deletionFlag: false,
        identifier: image.identifier,
        variant: image.variant ?? null,
      }))
    );
  }, [product]);

  //Reset data when product created
  useEffect(() => {
    if (resetForm) {
      setProductImages([]);
    }
  }, [resetForm]);

  //useEffect to updated uploaded images
  useEffect(() => {
    if (productImages.length === 0) {
      const newImages = {
        error: "Please upload some product images",
        data: productImages,
      };

      setValidationResults((prevResults) => {
        return { ...prevResults, ProductImages: newImages };
      });
    } else {
      const newImages = { error: "", data: productImages };

      setValidationResults((prevResults) => {
        return { ...prevResults, ProductImages: newImages };
      });
    }
  }, [productImages, setValidationResults]);

  return (
    <div>
      <div className="mb-3 col-lg-12 mt-5">
        <ImageUploader setProductImages={setProductImages} />
      </div>
      <div className="mt-3">
        <UploadedImages
          productImages={productImages}
          setProductImages={setProductImages}
          setValidationResults={setValidationResults}
        />
      </div>
    </div>
  );
};

export default Index;
