import React from "react";

const Heading = ({ review, setReview }) => {
  const handleChange = (e) => {
    setReview({ ...review, heading: e.target.value });
  };
  return (
    <>
      <div className="border-bottom py-4 mb-4">
        <h5>Add a headline (Optional)</h5>
        <input
          value={review.heading}
          onChange={handleChange}
          type="text"
          className="form-control"
          placeholder="What’s most important to know"
        />
      </div>
    </>
  );
};

export default Heading;
