import React, { useState, useEffect } from "react";
import io from "socket.io-client";

const SOCKET_SERVER_URL = "https://tasteful-jewel-frill.glitch.me";
// const SOCKET_SERVER_URL = "http://localhost:4000";

const Index = () => {
  const [connections, setConnections] = useState([]);
  const [loadingConnectedUsers, setLoadingConnectedUsers] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const socket = io(SOCKET_SERVER_URL);

    // Emit the session ID to the server on connection
    socket.emit("newConnection", "Admin1234");

    socket.on("connectedUsers", (users) => {
      // Remove user with username "Admin1234" from the array
      const filteredUsers = users.filter((user) => user !== "Admin1234");
      setConnections(filteredUsers);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSendMessage = () => {
    const socket = io(SOCKET_SERVER_URL);
    // Emit the message to all connected users
    socket.emit("message", message); // Emit event with message data
    setMessage(""); // Clear the message input after sending
  };

  const fetchConnectedUsers = async () => {
    try {
      setLoadingConnectedUsers(true);
      const response = await fetch(`${SOCKET_SERVER_URL}/connectedUsers`);
      const data = await response.json();
      // Remove the string "Admin1234" from the array
      const filteredConnectedUsers = data.filter(
        (user) => user !== "Admin1234"
      );
      setConnections(filteredConnectedUsers);
      setLoadingConnectedUsers(false);
    } catch (error) {
      console.error("Failed to fetch connectedUsers:", error);
      setLoadingConnectedUsers(false);
    }
  };
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-6">
        <div>
          <h4 className="mb-0 fs-5">Online Users</h4>
        </div>
        <div className="icon-shape icon-md bg-light-danger text-dark-danger rounded-circle">
          {connections.length}
        </div>
      </div>
      <div className="message-form mt-4">
        <label htmlFor="message-input">Push a message to active users</label>
        <textarea
          id="message-input"
          className="form-control"
          rows="3"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button className="btn btn-primary mt-2" onClick={handleSendMessage}>
          Send
        </button>
      </div>
      <button className="btn btn-secondary mt-4" onClick={fetchConnectedUsers}>
        Fetch Online Users
      </button>
      <div className="connected-users mt-4">
        <h4>Connected Users ({connections.length})</h4>
        {loadingConnectedUsers && "Loading..."}
        {connections.map((user, index) => (
          <div key={index} className="user-info">
            <span>{user}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default Index;
