import React, { useContext, useState } from "react";
import CatProContext from "../../../../../context/CatProContext";

const Index = ({ productsIds, setProductsIds }) => {
  const { defaultProducts, loadingProducts, productsError } =
    useContext(CatProContext);

  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleDeleteProduct = (productId) => {
    const updatedProducts = productsIds.filter(
      (id) => id !== productId
    );
    setProductsIds(updatedProducts);
  };

  const getProductById = (id) => {
    return defaultProducts.find(product => product._id === id);
  };

  return (
    <>
      <div className="accordion" id="productDetailsAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              onClick={handleToggleExpand}
            >
              Delete Products
            </button>
          </h2>
          <div className={`accordion-collapse ${expanded ? "show" : ""}`}>
            <div className="accordion-body">
              {expanded && (
                <div>
                  {productsIds.map((productId) => {
                    const product = getProductById(productId);
                    return product ? (
                      <div key={product._id} className="mb-3">
                        <h3>Title: {product.Title}</h3>
                        <p>Categories: {product.Categories.join(", ")}</p>
                        <p>Shipping Charges: {product.ShippingCharges}</p>
                        <p>
                          Payment Methods: {product.PaymentMethods.join(", ")}
                        </p>
                        <button
                          className="btn btn-danger"
                          onClick={() => handleDeleteProduct(product._id)}
                        >
                          Delete
                        </button>
                      </div>
                    ) : null;
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
