import React, { useEffect } from "react";
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Index = ({ brand }) => {
  useEffect(() => {
    // Ensure Bootstrap's dropdown functionality is initialized
    const bootstrap = require('bootstrap');
    document.querySelectorAll('.dropdown-profile').forEach((dropdown) => {
      new bootstrap.Dropdown(dropdown);
    });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("adminAuth");
    window.location.reload();
  }

  return (
    <>
      <li className="dropdown ms-4">
        <a
          href="#"
          role="button"
          className="dropdown-profile"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="../assets/images/avatar/profile.png"
            alt=""
            className="avatar avatar-md rounded-circle"
          />
        </a>

        <div className="dropdown-menu dropdown-menu-end p-0">
          <div className="lh-1 px-5 py-4 border-bottom">
            <h5 className="mb-1 h6">
              {brand === "osc" ? "Owaisy Mall" : "Minnie Strudio"} Admin
            </h5>
            <small>info@owaisy.com</small>
          </div>

          <ul className="list-unstyled px-2 py-3">
            <li>
              <a className="dropdown-item" href="#">
                Home
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Profile
              </a>
            </li>

            <li>
              <a className="dropdown-item" href="#">
                Settings
              </a>
            </li>
          </ul>
          <div className="border-top px-5 py-3">
            <a onClick={handleLogout} href="#">Log Out</a>
          </div>
        </div>
      </li>
    </>
  );
};

export default Index;
