import React, { useState } from "react";
import SubmitBadges from "./SubmitBadges";

const CreateNew = ({
  product,
  setAllBadges,
  setDisplayProducts,
  allBadges,
}) => {
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("danger");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTitleChange = (e) => {
    const title = e.target.value;
    const capitalizedTitle = title.replace(/\b\w/g, (char) =>
      char.toUpperCase()
    );
    setTitle(capitalizedTitle);
  };

  const handleColorChange = (e) => {
    setColor(e.target.value);
  };

  const handleAddBadge = () => {
    if (title === "") {
      setError("Please enter a title");
    } else if (title.length > 12) {
      setError("Maximum 12 characters allowed for badge title");
    } else {
      setAllBadges((prevState) => [
        ...prevState,
        {
          title: title,
          color: color,
        },
      ]);
      setTitle("");
      setError("")
    }
  };

  return (
    <>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-12 mb-2">
            <input
              type="text"
              id="badgeTitle"
              placeholder="Badge Title"
              class="form-control mb-2"
              onChange={handleTitleChange}
              value={title}
              autoComplete="off"
            />
          </div>
          <div class="col-lg-4 col-md-12 mb-2">
            <select onChange={handleColorChange} class="form-control mb-2">
              <option value="danger">Red</option>
              <option value="success">Green</option>
              <option value="warning">Yellow</option>
            </select>
          </div>
          <div class="col-lg-4 col-md-12 mb-2">
            <button
              class="btn btn-primary btn-sm"
              onClick={() => {
                handleAddBadge(product._id);
              }}
            >
              Add Badge
            </button>
          </div>
          <div class="col-lg-4 col-md-12 mb-2">
            <SubmitBadges
              product={product}
              setDisplayProducts={setDisplayProducts}
              allBadges={allBadges}
              setLoading={setLoading}
              setError={setError}
            />
            {loading && (
              <>
                <label className="mx-2">Adding Badges...</label>
              </>
            )}
            {error && (
              <>
                <label className="mx-2">{error}</label>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNew;
