import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Email from "./Username";
import Password from "./Password";
import Submit from "./Submit";
import CommonContext from "../../context/CommonContext";

const Index = () => {
  const [info, setInfo] = useState({ email: "", password: "" });
  const { brand } = useContext(CommonContext);

  return (
    <section className="my-lg-14 mt-10">
      <div id="top" className="push-down container">
        {/* <!-- row --> */}
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
            {/* <!-- img --> */}
            <img
              src="../assets/images/svg-graphics/signin-g.svg"
              alt=""
              className="img-fluid"
            />
          </div>
          {/* <!-- col --> */}
          <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
            <div className="mb-lg-9 mb-5">
              <h1 className="mb-1 h2 fw-bold">
                Dear Admin, please Sign in to{" "}
                {brand === "osc"
                  ? "Owaisy Mall"
                  : brand === "minnie"
                  ? "Minnie Studio"
                  : "No brand"} Dashboard
              </h1>
              <p>Welcome back! Enter admin username and password</p>
            </div>

            <form>
              <div className="row g-3">
                {/* <!-- row --> */}
                <Email info={info} setInfo={setInfo} />
                <Password info={info} setInfo={setInfo} />

                <div className="d-flex justify-content-between">
                  {/* <!-- form check --> */}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                    {/* <!-- label -->  */}
                    <label className="form-check-label" for="flexCheckDefault">
                      Remember me
                    </label>
                  </div>
                </div>
                {/* <!-- btn --> */}
                <Submit info={info} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
