import React, { useState, useEffect } from "react";
import Days from "./Days/Index";
import timeMaker from './utils/timeMaker'

// Define your SOCKET_SERVER_URL
// const SOCKET_SERVER_URL = "http://localhost:4000";
const SOCKET_SERVER_URL = "https://tasteful-jewel-frill.glitch.me";

const Index = () => {
  const [usersData, setUsersData] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [error, setError] = useState("");
  const [recordDay, setRecordDay] = useState({
    day: "Today",
    data: [], // Initialize as an empty array to hold today's data
    date: new Date().toLocaleDateString(), // Set initial date to today's date
  });

  // Function to convert time string to seconds
  const convertTimeToSeconds = (timeString) => {
    const [minutes, seconds] = timeString.split(", ");
    const minutesInSeconds = parseInt(minutes) * 60;
    const secondsOnly = parseInt(seconds.split(" ")[0]);
    return minutesInSeconds + secondsOnly;
  };

  const fetchUsersRecord = async () => {
    setLoadingUsers(true);
    setError("");
    try {
      const response = await fetch(`${SOCKET_SERVER_URL}/usersData`);
      const data = await response.json();
  
      if (data.success) {
        const filteredUsers = data.data.filter(
          (user) => user.username !== "Admin1234"
        );
  
        const sortedUsers = filteredUsers.sort((a, b) => {
          const timeA = convertTimeToSeconds(a.totalOnlineTime);
          const timeB = convertTimeToSeconds(b.totalOnlineTime);
          return timeB - timeA;
        });
  
        setUsersData(sortedUsers);
  
        // Extract all dates from the sorted users
        const dates = sortedUsers.map(user => user.date);
  
        // Find the latest date
        const latestDate = dates.reduce((latest, current) => 
          current > latest ? current : latest, dates[0]
        );
  
        // Filter users with the latest date
        const latestDateData = sortedUsers.filter(
          (user) => user.date === latestDate
        );
  
        setRecordDay({
          day: latestDate, // Update the day to the latest date string
          data: latestDateData,  // Update data to the latest date records
          date: latestDate // Update the date to the latest date string
        });
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError(error.message);
    }
    setLoadingUsers(false);
  };
  
  if (loadingUsers) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <>
      <div className="mb-2">
        <button className="btn btn-secondary mb-2" onClick={fetchUsersRecord}>
          Fetch Users Record
        </button>
      </div>
      {usersData.length>0 && (
        <>
          <div className="today-users mt-4">
            <Days
              usersData={usersData}
              recordDay={recordDay}
              setRecordDay={setRecordDay}
            />
            <h4>
              {timeMaker(recordDay.date)} Record ({recordDay.data.length} Users)
            </h4>
            {recordDay.data && (
              <div className="user-info">
                {recordDay.data.map((user, index) => (
                  <div key={index}>
                    <span>{user.username}: </span>
                    <span>{user.totalOnlineTime}</span>
                    <br />
                    <br />
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Index;
