import React from "react";

const Username = ({ info, setInfo }) => {
  const handleChange = (e) => {
    setInfo({ ...info, email: e.target.value });
  };
  return (
    <>
      <div className="col-12">
        {/* <!-- input --> */}
        <input
          value={info.email}
          onChange={handleChange}
          type="text"
          className="form-control"
          id="inputEmail4"
          placeholder="User name"
          required
        />
      </div>
    </>
  );
};

export default Username;
