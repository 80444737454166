import React, { useState } from "react";

const Index = ({ categoryInfo, setCategoryInfo }) => {

  const handleTitleChange = (e) => {
    setCategoryInfo({ ...categoryInfo, metaTitle: e.target.value });
  };

  const handleDescriptionChange = (e) => {
    setCategoryInfo({ ...categoryInfo, metaDescription: e.target.value });
  };

  return (
    <>
      <div className="mb-3 col-lg-12 mt-5 ">
        <h4 className="mb-4 h5">Meta Data</h4>
        {/* <!-- input --> */}
        <div className="mb-3">
          <label className="form-label">Meta Title (Optional)</label>
          <input
            type="text"
            className="form-control"
            placeholder="Title"
            onChange={handleTitleChange}
            value={categoryInfo.metaTitle}
          />
        </div>

        {/* <!-- input --> */}
        <div className="mb-3">
          <label className="form-label">Meta Description (Optional)</label>
          <textarea
            className="form-control"
            rows="3"
            placeholder="Meta Description"
            onChange={handleDescriptionChange}
            value={categoryInfo.metaDescription}
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default Index;
