import React from "react";

const Error = ({error}) => {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center push-down"
        style={{ minHeight: "200px" }}
        id="top"
      >
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    </>
  );
};

export default Error;
