import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Title from "./Title/Index";
import Categories from "./Categories/Index.js";
import Sizes from "./Sizes/Index.js";
import ProductImages from "./ProductImages/Index";
import ProductDescription from "./ProductDescription/Index";
import InStock from "./InStock/Index";
import PaymentMethods from "./PaymentMethods/Index.js";
import ProductCode from "./ProductCode/Index.js";
import ShippingCharges from "./ShippingCharges/Index.js";
import ProductPrice from "./ProductPrice/Index";
import uploadProduct from "./uploadProduct.js";
import CatProContext from "../../../context/CatProContext.js";
import base64ToBlobObj from "./utils/base64ToBlobObj";

const Index = () => {
  const { createProduct } = useContext(CatProContext);

  const [validationResults, setValidationResults] = useState({
    Title: { error: "Title field is required", data: "" },
    Categories: { error: "", data: "" },
    Sizes: { error: "", data: "" },
    ProductImages: { error: "Please upload some product images", data: "" },
    ProductDescription: { error: "Please describe product", data: "" },
    InStock: { error: "", data: true },
    PaymentMethods: { error: "", date: ["Cash on Delivery"] },
    ProductCode: { error: "", date: "" },
    ShippingCharges: { error: "", date: 0 },
    ProductPrice: { error: "Product price section required", data: "" },
  });

  const [resetForm, setResetForm] = useState(false);
  const [submitResponse, setSubmitResponse] = useState("");

  const handleSubmit = async () => {
    // Pass necessary parameters to the imported function
    await uploadProduct(
      validationResults,
      base64ToBlobObj,
      createProduct,
      setSubmitResponse,
      setValidationResults,
      setResetForm
    );
  };

  return (
    <main className="main-content-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-12">
            <div className="card mb-6 card-lg">
              <div className="card-body p-6 ">
                <h4 className="mb-4 h5">Product Information</h4>
                <div className="row">
                  <Title
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                  />
                  <Categories
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                  />
                  <Sizes
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                  />
                  <ProductImages
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                  />
                  <ProductDescription
                    setValidationResults={setValidationResults}
                    validationResults={validationResults}
                    resetForm={resetForm}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="card mb-6 card-lg">
              <div className="card-body p-6">
                <InStock
                  setValidationResults={setValidationResults}
                  resetForm={resetForm}
                />
              </div>
            </div>
            <div className="card mb-6 card-lg">
              <div className="card-body p-6">
                <PaymentMethods
                  setValidationResults={setValidationResults}
                  resetForm={resetForm}
                />
                <ProductCode
                  setValidationResults={setValidationResults}
                  resetForm={resetForm}
                />
                <ShippingCharges
                  setValidationResults={setValidationResults}
                  resetForm={resetForm}
                />
              </div>
            </div>
            <ProductPrice
              setValidationResults={setValidationResults}
              resetForm={resetForm}
            />
            <div className="d-grid">
              <Link to="#" onClick={handleSubmit} className="btn btn-primary">
                Create Product
              </Link>
              {submitResponse && (
                <div>
                  <h3>Response</h3>
                  {submitResponse}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Index;
