import React from "react";

const Password = ({ info, setInfo }) => {
  const handleChange = (e) => {
    setInfo({ ...info, password: e.target.value });
  };
  return (
    <>
      <div className="col-12">
        {/* <!-- input --> */}
        <div className="password-field position-relative">
          <input
            value={info.password}
            onChange={handleChange}
            type="password"
            id="fakePassword"
            placeholder="Enter Password"
            className="form-control"
            required
          />
          <span>
            <i id="passwordToggler" className="bi bi-eye-slash"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default Password;
