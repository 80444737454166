import React from "react";
import ImageUploader from "./UploadImage/Index";
import SlidersList from "./SlidersList/Index"

const Index = () => {
  return (
    <main className="main-content-wrapper">
      <div className="container">
        <ImageUploader />
        <SlidersList />
      </div>
    </main>
  );
};

export default Index;
