import React, { useState } from "react";
import ImageCropper from "../ImageCropper/Index";

const Index = ({ setProductImages }) => {
  const [image, setImage] = useState({ image: null, variant: null });

  const handleImage = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      // Update the state with the new image source
      setImage({ image: reader.result, variant: null });
    };
    reader.readAsDataURL(file);
  };

  const onFileChange = (e) => {
    const file = e.target.files[0];
    handleImage(file);
  };

  const onDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleImage(file);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <>
      {/* heading */}
      <h4 className="mb-3 h5">Product Images (Required)</h4>

      {/* Drag and Drop Zone */}
      <div
        className="dropzone border-dashed rounded-2"
        onDrop={onDrop}
        onDragOver={onDragOver}
      >
        <label htmlFor="fileInput" className="d-block">
          <span>Choose File or drag & drop here</span>
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            onChange={onFileChange}
            style={{ display: "none" }}
          />
        </label>
      </div>

      {image.image && image.image !== "image" && (
        <>
          <ImageCropper
            imageSrc={image.image}
            setProductImages={setProductImages}
            setImage={setImage}
          />
        </>
      )}
    </>
  );
};

export default Index;
