import React from "react";

const Delete = ({ productImages, setProductImages, rowIndex, index }) => {
  // Function to delete an image by index
  const deleteImage = (rowIndex, index) => {
    const updatedProductImages = [...productImages];
    updatedProductImages.splice(rowIndex * 3 + index, 1);
    setProductImages(updatedProductImages);
  };
  return (
    <>
      <button
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          background: "transparent",
          border: "none",
          cursor: "pointer",
        }}
        onClick={() => deleteImage(rowIndex, index)}
      >
        🗑️
      </button>
    </>
  );
};

export default Delete;
