import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes"; // Define your item types
import ParentDropZone from "./ParentDropZone";
import ChildDraggable from "./ChildDraggable";
import ChildDropZone from "./ChildDropZone";

const Index = ({ parents, childs, addChild, subsChild }) => {
  const [draggingParent, setDraggingParent] = useState(null);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.CHILD,
    drop: (item) => addChild(item.childId, draggingParent),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const handleParentDrag = (parent) => {
    setDraggingParent(parent);
  };

  return (
    <>
      <div className="col-lg-6 col-12 parent-container" ref={drop}>
        <h3 className="parent-title">Parents</h3>
        {parents.map((parent) => (
          <div key={parent._id} onDragEnter={() => handleParentDrag(parent)}>
            <h5>{parent.name}</h5>
            <ParentDropZone parent={parent} />
            <ul className="child-list">
              {parent.childs.map((child) => (
                <li key={child.childId._id} className="child-item">
                  <ChildDraggable
                    child={child.childId}
                    parent={parent}
                    draggable={true}
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div className="col-lg-6 col-12 unassociated-child-container">
        <ChildDropZone subsChild={subsChild}>
          <h3>Unassociated Childs</h3>
          <ul className="unassociated-child-list">
            {childs.map((child) => (
              <li key={child._id} className="unassociated-child-item">
                <ChildDraggable child={child} />
              </li>
            ))}
          </ul>
        </ChildDropZone>
      </div>
    </>
  );
};

export default Index;
