import React, { useContext, useState } from "react";
import CommonContext from "../../../context/CommonContext";

const Index = () => {
  const [source, setSource] = useState("");
  const { liveTV } = useContext(CommonContext);
  const [loading, setLoading] = useState(false);

  const handlePost = async () => {
    setLoading(true);
    const response = await liveTV(source);
    setLoading(false);
  };

  const handleStatus = async () => {
    setLoading(true);
    const response = await liveTV("");
    setLoading(false);
  };

  const handlePtvSports = () => {
    setSource(
      '<iframe loading="lazy" src="//stream.crichd.vip/update/ptv.php" width="100%" height="500px" marginheight="0" marginwidth="0" scrolling="no" frameborder="0" allowfullscreen="" allow="encrypted-media"></iframe>'
    );
  };

  const handleStarSports = () => {
    setSource(
      '<iframe loading="lazy" src="//stream.crichd.vip/update/star1hi.php" width="100%" height="500px" marginheight="0" marginwidth="0" scrolling="no" frameborder="0" allowfullscreen="" allow="encrypted-media"></iframe>'
    );
  };

  const handleSCricket = () => {
    setSource(
      '<iframe loading="lazy" src="//stream.crichd.vip/update/sscricket.php" width="100%" height="500px" marginheight="0" marginwidth="0" scrolling="no" frameborder="0" allowfullscreen="" allow="encrypted-media"></iframe>'
    );
  };

  const handleSkySportsCricket = () => {
    setSource(
      '<iframe loading="lazy" src="//stream.crichd.vip/update/skys2.php" width="100%" height="500px" marginheight="0" marginwidth="0" scrolling="no" frameborder="0" allowfullscreen="" allow="encrypted-media"></iframe>'
    );
  };

  const handleWillowHd = () => {
    setSource(
      '<iframe loading="lazy" src="//stream.crichd.vip/update/willowcricket.php" width="100%" height="500px" marginheight="0" marginwidth="0" scrolling="no" frameborder="0" allowfullscreen="" allow="encrypted-media"></iframe>'
    );
  };

  const handleASports = () => {
    setSource(
      '<iframe loading="lazy" src="//stream.crichd.vip/update/asportshd.php" width="100%" height="500px" marginheight="0" marginwidth="0" scrolling="no" frameborder="0" allowfullscreen="" allow="encrypted-media"></iframe>'
    );
  };
  return (
    <>
      <div className="col-lg-12 col-12 mb-6">
        <div className="card h-100 card-lg">
          <div className="card-body p-6">
            <div className="form-group mb-5">
              <center>
                <div onClick={handlePost} className="btn btn-secondary mb-3">
                  Update Source
                </div>
                <div
                  onClick={handleStatus}
                  className="btn btn-secondary mb-3 mx-2"
                >
                  Update Status
                </div>
                <br />
                {loading && <span>Updating...</span>}
              </center>
              <div className="row">
                <div className="col-6">
                  <div onClick={handlePtvSports} className="btn btn-primary">
                    PTV Sports
                  </div>
                </div>
                <div className="col-6">
                  <div onClick={handleStarSports} className="btn btn-primary">
                    Star Sports
                  </div>
                </div>
                <div className="col-6 mt-2">
                  <div onClick={handleSCricket} className="btn btn-primary">
                    S Cricket
                  </div>
                </div>
                <div className="col-6 mt-2">
                  <div
                    onClick={handleSkySportsCricket}
                    className="btn btn-primary"
                  >
                    Sky Sports Cricket
                  </div>
                </div>
                <div className="col-6 mt-2">
                  <div onClick={handleWillowHd} className="btn btn-primary">
                    Willow HD
                  </div>
                </div>
                <div className="col-6 mt-2">
                  <div onClick={handleASports} className="btn btn-primary">
                    A Sports
                  </div>
                </div>
              </div>
              <br />
              <label className="mb-2">Preview</label>
              <div
                dangerouslySetInnerHTML={{ __html: source }}
                className="video-player"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
