import React from "react";

const Index = ({ categories }) => {
  
  return (
    <>
      <div className=" border-top d-md-flex justify-content-between align-items-center px-6 py-6">
        <span>Showing all {categories.length} Parents</span>
        <nav className="mt-2 mt-md-0">
          <ul className="pagination mb-0 ">
            <li className="page-item disabled">
              <label className="page-link ">Previous</label>
            </li>
            <li className="page-item">
              <label className="page-link active">1</label>
            </li>
            <li className="page-item disabled">
              <label className="page-link">Next</label>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Index;
