import React, { useState } from "react";

const Index = ({ categoryInfo, setCategoryInfo }) => {

  const handleChange = (e) => {
    const inputText = e.target.value;
    const formattedText = inputText
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
    setCategoryInfo({ ...categoryInfo, name: formattedText });
  };
  return (
    <>
      <div className="mb-3 col-lg-6">
        <label className="form-label">Category Name (Required)</label>
        <input
          type="text"
          className="form-control"
          placeholder="Category Name"
          value={categoryInfo.name}
          onChange={handleChange}
        />
      </div>
    </>
  );
};

export default Index;
