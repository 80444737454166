import React, { useContext, useState } from "react";
import Heading from "./Heading";
import Images from "./Images";
import Content from "./Content";
import Submite from "./Submite";

const Index = ({ product, review, setReview, setComments, comments }) => {
  return (
    <>
      <div>
        <h3 className="mb-5">Create Review</h3>
        <Heading review={review} setReview={setReview} />
        <Images review={review} setReview={setReview} />
        <Content review={review} setReview={setReview} />
        <Submite
          review={review}
          setReview={setReview}
          setComments={setComments}
          comments={comments}
        />
      </div>
    </>
  );
};

export default Index;
