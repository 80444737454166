import React, { useContext, useEffect } from "react";
import SelectContext from "../../../context/SelectContext";
import { Link } from "react-router-dom";

const Index = () => {
  const { checkedProducts, setCheckedProducts, setCheckedAll } =
    useContext(SelectContext);

  useEffect(() => {
    setCheckedProducts([]);
    setCheckedAll(false);
  }, [setCheckedProducts, setCheckedAll]);
  return (
    <div className="mx-2">
      {checkedProducts.length > 0 && (
        <>
          <div>{checkedProducts.length} products selected </div>
        </>
      )}
      {checkedProducts.length > 0 && (
        <>
          <div>
            <Link
              to="/products/createSections"
              state={{ products: checkedProducts }}
              className="btn btn-sm btn-primary mx-2"
            >
              Create Sections
            </Link>
            <Link
              to="/products/createBadges"
              state={{ products: checkedProducts }}
              className="btn btn-sm btn-primary"
            >
              Create Badges
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default Index;
