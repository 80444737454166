import React, { useEffect, useState } from "react";

const Index = ({ setValidationResults, resetForm }) => {
  const [inputValue, setInputValue] = useState("");
  const [sizes, setSize] = useState([]);

  //Reset data when product created
  useEffect(() => {
    if (resetForm) {
      setSize([]);
    }
  }, [resetForm]);

  useEffect(() => {
    const newSizes = { error: "", data: sizes };

    setValidationResults((prevResults) => {
      return { ...prevResults, Sizes: newSizes };
    });
  }, [sizes, setValidationResults]);

  const handleInputChange = (e) => {
    setInputValue(capitalizeFirstLetter(e.target.value));
  };

  const handleAddSize = () => {
    if (inputValue.trim() !== "") {
      if (!sizes.includes(inputValue.trim())) {
        setSize((prevKeywords) => [...prevKeywords, inputValue.trim()]);
      }
      setInputValue("");
    }
  };

  const handleRemoveSize = (index) => {
    setSize((prevKeywords) => prevKeywords.filter((_, i) => i !== index));
  };

  const capitalizeFirstLetter = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div>
      <div className="mt-4" style={{ marginBottom: "10px" }}>
        <strong>Sizes (Optional):</strong>
        {sizes.map((keyword, index) => (
          <span
            key={index}
            style={{
              display: "inline-block",
              backgroundColor: "#e0e0e0",
              borderRadius: "5px",
              padding: "15px",
              margin: "0 5px",
              position: "relative",
            }}
          >
            {keyword}
            <button
              onClick={() => handleRemoveSize(index)}
              style={{
                position: "absolute",
                top: "2px",
                right: "2px",
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "2px",
                fontSize: "10px", // Adjust the font size for the close icon
              }}
            >
              X
            </button>
          </span>
        ))}
      </div>
      <input
        type="text"
        className="form-control"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Type a size"
        style={{ marginBottom: "10px" }}
      />
      <button onClick={handleAddSize} className="btn btn-success btn-sm">
        Add Size
      </button>
    </div>
  );
};

export default Index;
