import React, { useState } from "react";
import Delete from "./Delete";
import Edit from "./Edit";

const Index = ({ product, setProducts }) => {
  const [action, setAction] = useState(null);

  return (
    <>
      <Delete
        product={product}
        setAction={setAction}
        setProducts={setProducts}
      />

      <Edit product={product} setAction={setAction} />
    </>
  );
};

export default Index;
