import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import TopBar from "./Components/Fixed/TopBar/Index";
import DesktopLeft from "./Components/Fixed/Left/Index";
import MobileLeft from "./Components/Fixed/MobileLeft/Index";
import Home from "./Components/Pages/Home/Index";
import Products from "./Components/Pages/Products/Index";
import AddProduct from "./Components/Pages/Products/AddProduct/Index";
import UpdateProduct from "./Components/Pages/Products/UpdateProduct/Index";
import Categories from "./Components/Pages/Categories/Index";
import Order from "./Components/Pages/Orders/Index";
import OrderDetail from "./Components/Pages/Orders/OrderDetail/Index";
import Vendors from "./Components/Pages/Vendors/Index";
import Reviews from "./Components/Pages/Reviews/Index";
import Customers from "./Components/Pages/Customers/Index";
import UpdateCategory from "./Components/Pages/Categories/UpdateCategory/Index";
import ProductDetails from "./Components/Pages/SingleProduct/Index";
import AddCategory from "./Components/Pages/Categories/AddCategory/Index";
import CreateCategories from "./Components/Pages/Categories/CreateCategories/Index";
import ManageCategories from "./Components/Pages/Categories/ManageCategories/Index";
import CreateSections from "./Components/Pages/Products/CreateSections/Index";
import UpdateSection from "./Components/Pages/Products/Sections/UpdateSection/Index";
import CreateBadges from "./Components/Pages/Products/CreateBadges/Index";
import SliderImages from "./Components/Pages/SliderImages/Index";
import Login from "./Components/Pages/Login/Index";
import CategoriesCreation from "./Components/Pages/Products/Categories/Index";

function App() {
  const adminAuth = localStorage.getItem("adminAuth");
  return (
    <>
      <>
        {adminAuth && (
          <div>
            <TopBar />
            <div className="main-wrapper">
              {/* <!-- navbar vertical --> */}
              <DesktopLeft />
              <MobileLeft />

              <Routes>
                <Route exact path="" element={<Home />}></Route>
                <Route exact path="/products" element={<Products />}></Route>
                <Route
                  exact
                  path="/products/addProduct"
                  element={<AddProduct />}
                ></Route>
                <Route
                  exact
                  path="/products/updateProduct"
                  element={<UpdateProduct />}
                ></Route>
                <Route
                  exact
                  path="/categories"
                  element={<Categories />}
                ></Route>

                <Route
                  exact
                  path="/categories/addCategory"
                  element={<AddCategory />}
                ></Route>

                <Route
                  exact
                  path="/products/categories-creation"
                  element={<CategoriesCreation />}
                ></Route>

                <Route
                  exact
                  path="/categories/CreateCategories"
                  element={<CreateCategories />}
                ></Route>
                <Route
                  exact
                  path="/categories/manageCategories"
                  element={<ManageCategories />}
                ></Route>

                <Route
                  exact
                  path="/categories/updateCategory"
                  element={<UpdateCategory />}
                ></Route>
                <Route exact path="/orders" element={<Order />}></Route>
                <Route
                  exact
                  path="/orders/orderDetail"
                  element={<OrderDetail />}
                ></Route>
                <Route exact path="/vendors" element={<Vendors />}></Route>
                <Route exact path="/reviews" element={<Reviews />}></Route>
                <Route exact path="/customers" element={<Customers />}></Route>
                <Route
                  exact
                  path="/productsDetails/:productId"
                  element={<ProductDetails />}
                ></Route>
                <Route
                  exact
                  path="/products/createSections"
                  element={<CreateSections />}
                ></Route>
                <Route
                  exact
                  path="/products/updateSection"
                  element={<UpdateSection />}
                ></Route>
                <Route
                  exact
                  path="/products/createBadges"
                  element={<CreateBadges />}
                ></Route>
                <Route
                  exact
                  path="/products/sliderImages"
                  element={<SliderImages />}
                ></Route>
              </Routes>
            </div>
          </div>
        )}
        {!adminAuth && <Login />}
      </>
    </>
  );
}

export default App;
