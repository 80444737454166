import React from "react";
import { Link } from "react-router-dom";

const Edit = ({ category, setAction }) => {
  const editHandler = (id) => {
    // setAction(null);
  };

  return (
    <Link
      className="btn btn-sm btn-warning"
      state={{
        category: category,
      }}
      to="/categories/updateCategory"
      onClick={() => {
        editHandler(category._id);
      }}
    >
      Update
    </Link>
  );
};

export default Edit;
