import React, { useState } from "react";

const Filters = ({ setCategories, defaultCategories }) => {
  const [selectedOption, setSelectedOption] = useState("ascending");

  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    const allCategories = defaultCategories

    switch (selectedValue) {
      case "ascending":
        // Sort categories in ascending order
        setCategories(defaultCategories);
        break;
      case "descending":
        // Sort categories in descending order
        setCategories([...allCategories].reverse());
        break;
    }
  };

  return (
    <div className="col-lg-2 col-md-4 col-12">
      <select
        className="form-select"
        value={selectedOption}
        onChange={handleSortChange}
      >
        <option value="ascending">Default</option>
        <option value="descending">Descending</option>
      </select>
    </div>
  );
};

export default Filters;
