import { createContext, useState, useEffect } from "react";
import host from "./host";

const ImagesContext = createContext();

export const ImagesProvider = ({ children }) => {
  // Upload Image
  const uploadImagesOnCloudStorage = async (formData, modal, uploadId) => {
    //API Call
    const response = await fetch(
      `${host}/images/imageUpload/uploadImagesOnCloudStorage?modal=${modal}&uploadId=${uploadId}`,
      {
        method: "POST",
        body: formData,
      }
    );
    const json = await response.json();
    return json;
  };

  return (
    <ImagesContext.Provider value={{ uploadImagesOnCloudStorage }}>
      {children}
    </ImagesContext.Provider>
  );
};

export default ImagesContext;
