import moment from "moment-timezone";

const dateConverter = (startDate, timeEnd, type, timezone) => {
  const newStartDate = moment.tz(startDate, timezone);
  const newEndDate = moment.tz(timeEnd, timezone);
  let result = newStartDate.diff(newEndDate, type);
  return result;
};

const timeMaker = (startDate) => {
  const timezone = "Asia/Karachi"; // Pakistan Standard Time
  // Extract only the date portion (YYYY-MM-DD) in the given timezone
  const formattedStartDate = moment
    .tz(startDate, timezone)
    .format("YYYY-MM-DD");
  const currentDateInTimezone = moment
    .tz(Date.now(), timezone)
    .format("YYYY-MM-DD");

  const days = dateConverter(
    formattedStartDate,
    currentDateInTimezone,
    "days",
    timezone
  );
  if (days === 0) {
    return "Today";
  } else {
    return Math.abs(days) + " Days ago";
  }
};

export default timeMaker;
