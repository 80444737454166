import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import CatProContext from "../../context/CatProContext";
import Content from "./Contnet";

const Index = () => {
  // Context
  const { brand } = useContext(CatProContext);
  return (
    <main className="main-content-wrapper">
      <section className="container">
        {/* <!-- row --> */}
        <div className="row mb-8">
          <div className="col-md-12">
            {/* <!-- card --> */}
            <div
              className="card bg-light border-0 rounded-4"
              style={{
                backgroundImage: "url(../assets/images/slider/slider-11.jpg)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "right",
              }}
            >
              <div className="card-body p-lg-12">
                <h1>
                  Welcome back!{" "}
                  {brand === "osc"
                    ? "Owaisy Shopping Center"
                    : brand === "minnie"
                    ? "Minnie Stuudio"
                    : "No brand"}
                </h1>
                <p>
                  Hello Admin! Check out the updated regularly to stay tuned
                  with your store
                </p>
                <div className="row">
                  <div className="col-12 col-md-4 mb-2 mb-md-0">
                    <Link
                      to="/products/addProduct"
                      className="btn btn-primary w-100"
                    >
                      Create Product
                    </Link>
                  </div>
                  <div className="col-12 col-md-4 mt-2 mt-md-0">
                    <Link
                      to="/products/sliderImages"
                      className="btn btn-primary w-100"
                    >
                      Upload Slider Images
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- table --> */}
        <Content />
      </section>
    </main>
  );
};

export default Index;
