import React from "react";

const Error = ({ error }) => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      </div>
    </>
  );
};

export default Error;
