import React, { useContext } from "react";
import SelectContext from "../../../../../context/SelectContext";

const Index = ({ productsIds, setProductsIds }) => {
  const { checkedProducts } = useContext(SelectContext);

  const handleAddProducts = () => {
    // Extract only _id fields from checkedProducts
    const checkedProductIds = checkedProducts.map((product) => product._id);

    // Create a new array including unique ids
    const updatedProductsIds = [...productsIds];
    checkedProductIds.forEach((id) => {
      if (!updatedProductsIds.includes(id)) {
        updatedProductsIds.push(id);
      }
    });

    // Update the state with the new productsIds array
    setProductsIds(updatedProductsIds);
  };

  return (
    <div>
      <div className="mt-5">
        {checkedProducts.length > 0 && (
          <>
            <label>({checkedProducts.length} Products Selected)</label>
            <label>
              <button
                className="mx-2 btn btn-sm btn-primary"
                onClick={handleAddProducts}
              >
                Add
              </button>
            </label>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
