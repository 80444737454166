import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Badges from "./Badges/Index";

const Index = () => {
  const location = useLocation();
  const { products } = location.state;
  const [displayProducts, setDisplayProducts] = useState(products);
  const [expanded, setExpanded] = useState(true);
  const [expandId, setExpandId] = useState(products[0]._id);

  const handleToggleExpand = (productId) => {
    setExpanded(!expanded);
    setExpandId(productId);
  };

  return (
    <>
      <main className="main-content-wrapper">
        <div className="container">
          <h1>Create Badges</h1>
          {displayProducts.map((product) => (
            <div className="accordion" id="productDetailsAccordion">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    type="button"
                    onClick={() => {
                      handleToggleExpand(product._id);
                    }}
                  >
                    {product.Title}
                  </button>
                </h2>
                <div className={`accordion-collapse`}>
                  <div className="accordion-body">
                    {expanded && expandId === product._id && (
                      <div className="card card-body">
                        <p>Categories: {product.Categories.join(", ")}</p>
                        <p>
                          Stock Information:{" "}
                          {product.InStock ? "Available" : "Out of Stock"}
                        </p>
                        <p>
                          Payment Methods: {product.PaymentMethods.join(", ")}
                        </p>
                        <p>
                          Regular Price: {product.ProductPrice.regularPrice}
                        </p>
                        <p>Sale Price: {product.ProductPrice.salePrice}</p>
                        <Badges
                          product={product}
                          setDisplayProducts={setDisplayProducts}
                        />
                        <div>
                          <h5>Product Images</h5>
                          <div className="row">
                            {product.ProductImages.map((image, index) => (
                              <div
                                key={index}
                                className="col-md-3"
                                onClick={() => console.log(image.link)}
                              >
                                <img
                                  src={image.link}
                                  alt={`Product ${index + 1}`}
                                  className="img-thumbnail"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    </>
  );
};

export default Index;
