import React, { useContext } from "react";
import BadgesContext from "../../../../context/BadgesContext";

const SubmitBadges = ({
  product,
  setDisplayProducts,
  allBadges,
  setLoading,
  setError,
}) => {
  const { updateBadges } = useContext(BadgesContext);
  const uploadBadges = async (productId) => {
    setLoading(true);
    const response = await updateBadges(allBadges, productId);
    if (response.success) {
      const updatedProduct = response.updatedProduct;
      // Assuming setDisplayProducts is a state update function
      updateProducts(updatedProduct);
      setError("Badges updated successfully");
    } else {
      setError(response.message);
    }
    setLoading(false);
  };

  const updateProducts = (updatedProduct) => {
    setDisplayProducts((prevProducts) => {
      // Find the index of the updated product in the previous products array
      const updatedIndex = prevProducts.findIndex(
        (product) => product._id === updatedProduct._id
      );
      if (updatedIndex !== -1) {
        // Create a new array with the updated product replacing the old one
        const updatedProducts = [...prevProducts];
        updatedProducts[updatedIndex] = updatedProduct;
        return updatedProducts;
      } else {
        // If the updated product is not found, return the previous products array as is
        return prevProducts;
      }
    });
  };

  return (
    <>
      <button
        class="btn btn-primary btn-sm"
        onClick={() => {
          uploadBadges(product._id);
        }}
      >
        Upload Badges
      </button>
    </>
  );
};

export default SubmitBadges;
