import React from "react";
import Actions from "../Actions/Index";
import SelectedSingle from "../Select/SelectSingle/Index";
import { Link } from "react-router-dom";
import Image from "./QuickView/Index";

const Product = ({ product, setProducts }) => {
  // Function to format date in "24 Nov 2022" pattern
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  return (
    <>
      <tr key={product._id}>
        <SelectedSingle product={product} />
        <Image product={product} setProducts={setProducts} />
        <td>
          <Link
            to={`/productsDetails/${product._id}`}
            state={{ product: product }}
            className="text-reset"
          >
            {product.Title}
          </Link>
        </td>
        <td>
          {product.Categories.length > 0
            ? product.Categories[0]
            : "Multiple Categories Detected"}
        </td>
        <td>
          <span
            className={`badge bg-light-${
              product.InStock ? "primary" : "danger"
            } text-dark-${product.InStock ? "primary" : "danger"}`}
          >
            {product.InStock ? "In Stock" : "Out of Stock"}
          </span>
        </td>
        <td>Rs{product.ProductPrice.salePrice}</td>
        <td>{formatDate(product.Date)}</td>
        <td style={{ display: "flex", alignItems: "center" }}>
          <Actions product={product} setProducts={setProducts} />
        </td>
      </tr>
    </>
  );
};

export default Product;
