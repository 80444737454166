import React from "react";
import { Link } from "react-router-dom";

const Info = ({ product, products }) => {
  return (
    <>
      <div className="text-small mb-1">
        <label to="" className="text-decoration-none text-muted"></label>
      </div>
      <h2 className="fs-6">
        {/* Badges */}
        <div className="d-flex flex-wrap mb-2">
          {product.Badges?.map((badge, index) => (
            <div
              key={index}
              className={`badge border border-${badge.color} text-dark mx-1 mb-1`}
            >
              {badge.title}
            </div>
          ))}
        </div>

        {/* Title */}
        <div>
          <Link
            to={`/productsDetails/${product._id}`}
            state={{ product: product, products: products }}
            className="text-inherit text-decoration-none"
          >
            {product.Title}
          </Link>
        </div>
      </h2>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <div>
          <span className="text-dark">Rs{product.ProductPrice.salePrice}</span>{" "}
          {product.ProductPrice.salePrice !==
            product.ProductPrice.regularPrice && (
            <>
              <span className="text-decoration-line-through text-danger">
                Rs{product.ProductPrice.regularPrice}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Info;
