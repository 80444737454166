import React, { useState } from "react";

const Image = ({ index, image }) => {
  const [fullSize, setFullSize] = useState(false);

  const closeModal = () => {
    setFullSize(false);
  };
  
  return (
    <>
      <div key={index} className="border icon-shape icon-lg border-2 ms-1 ">
        {/* <!-- img --> */}
        <img
          src={image.link}
          onClick={() => {
            setFullSize(true);
          }}
          alt=""
          className="img-fluid "
        />
      </div>

      {fullSize && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={closeModal}
        >
          <img
            src={image.link}
            alt="Full Screen Image"
            style={{
              width: "200%",
              maxHeight: "90%",
              objectFit: "contain", // Maintain aspect ratio without cropping
              borderRadius: "5px",
            }}
          />
        </div>
      )}
    </>
  );
};

export default Image;
