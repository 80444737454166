import React, { useState } from "react";
import { FaSync } from "react-icons/fa";

const Search = ({ setOrders, orders, defaultOrders, getOrders }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.trim() === "") {
      // If the search query is empty, display all original orders without filtering
      setOrders(defaultOrders);
    } else {
      // Otherwise, filter the orders based on the search query
      const filteredOrders = orders.filter((order) =>
        new RegExp(query, "i").test(order.shippingAddress.city)
      );
      setOrders(filteredOrders);
    }
  };

  const handleRefresh = () => {
    getOrders();
  };

  return (
    <>
      <div className="col-lg-4 col-md-6 col-12 mb-2 mb-lg-0">
        <form className="d-flex" role="search">
          <input
            className="form-control"
            type="search"
            placeholder="Search Orders by address (City)"
            aria-label="Search"
            value={searchQuery}
            onChange={handleSearch}
          />
          <button
            type="button"
            onClick={handleRefresh}
            className="btn btn-link"
          >
            <FaSync />
          </button>
        </form>
      </div>
    </>
  );
};

export default Search;
