import React, { useState } from "react";

const Index = ({ products, setProducts }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleDeleteProduct = (productId) => {
    const updatedProducts = products.filter(
      (product) => product._id !== productId
    );
    setProducts(updatedProducts);
  };
  return (
    <>
      <div className="accordion" id="productDetailsAccordion">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              onClick={handleToggleExpand}
            >
              See products details
            </button>
          </h2>
          <div className={`accordion-collapse ${expanded ? "show" : ""}`}>
            <div className="accordion-body">
              {expanded && (
                <div>
                  {products.map((product) => (
                    <div key={product._id} className="mb-3">
                      <h3>Title: {product.Title}</h3>
                      <p>Categories: {product.Categories.join(", ")}</p>
                      <p>Shipping Charges: {product.ShippingCharges}</p>
                      <p>
                        Payment Methods: {product.PaymentMethods.join(", ")}
                      </p>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteProduct(product._id)}
                      >
                        Delete
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
