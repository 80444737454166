import React from "react";
import ConnectedUsers from "./ConnectedUsers/Index";
import Record from "./Record/Index";

const Index = () => {
  return (
    <>
      <div className="col-lg-12 col-12 mb-6">
        <div className="card h-100 card-lg">
          <div className="card-body p-6">
            <ConnectedUsers />
            <hr />
            <Record />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
