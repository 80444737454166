import React from "react";
import functionToAddChild from "../utils/addChild";

const Index = ({
  products,
  setTreeData,
  selectedNode,
  setSelectedNode,
  treeData,
  setAction,
}) => {
  const getUniqueCategories = (products) => {
    const uniqueCategories = new Set();

    products.forEach((product) => {
      product.Categories.forEach((category) => {
        uniqueCategories.add(category);
      });
    });

    return Array.from(uniqueCategories);
  };

  const getCategoriesFromTree = (node) => {
    let categories = [];
    const traverse = (node) => {
      categories.push(node.name);
      if (node.children) {
        node.children.forEach((child) => traverse(child));
      }
    };
    traverse(node);
    return categories;
  };

  const uniqueCategories = getUniqueCategories(products);
  const existingCategories = getCategoriesFromTree(treeData);
  const availableCategories = uniqueCategories.filter(
    (category) => !existingCategories.includes(category)
  );

  const handleAddChild = (childName) => {
    const leaf = true;
    functionToAddChild(
      childName,
      setTreeData,
      selectedNode,
      treeData,
      setAction,
      leaf
    );
    setSelectedNode(null);
  };

  return (
    <div>
      {availableCategories.map((category, index) => (
        <label key={index} className="btn btn-outline-primary m-1">
          {category}{" "}
          {selectedNode && !selectedNode.data.leaf && (
            <label
              onClick={() => {
                handleAddChild(category);
              }}
              className="mx-2 btn btn-secondary btn-sm"
            >
              Set
            </label>
          )}
        </label>
      ))}
    </div>
  );
};

export default Index;
