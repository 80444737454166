const getUniqueCategories = (products) => {
    const uniqueCategories = new Set();

    products.forEach((product) => {
      product.Categories.forEach((category) => {
        uniqueCategories.add(category);
      });
    });

    return Array.from(uniqueCategories);
  };

  export default getUniqueCategories
