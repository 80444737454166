import React from "react";

const Error = () => {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "200px" }}
      >
        <div className="alert alert-danger" role="alert">
          Some backend issue occured while loading the reviews
        </div>
      </div>
    </>
  );
};

export default Error;
