import React from "react";

const Index = ({ order }) => {
  return (
    <>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-6">
          <h6>Shipping Address</h6>
          <p className="mb-1 lh-lg">
            {order.shippingAddress.completeAddress}
            <br />
            {order.shippingAddress.city}, {order.shippingAddress.district},{" "}
            {order.shippingAddress.country}
            <br />
            Zip Code: {order.shippingAddress.zipCode} <br />
            {order.shippingAddress.mobile}
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
