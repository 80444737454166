import React from "react";
import Search from "./Search";
import Filters from "./Filters/Index";

const Index = ({ setProducts, products, defaultProducts, getProducts }) => {
  return (
    <>
      <div className="px-6 py-6 ">
        <div className="row justify-content-between">
          {/* <!-- form --> */}
          <Search
            setProducts={setProducts}
            products={products}
            defaultProducts={defaultProducts}
            getProducts={getProducts}
          />
          {/* <!-- select option --> */}
          <Filters
            setProducts={setProducts}
            defaultProducts={defaultProducts}
            products={products}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
