import React, { useContext, useState } from "react";
import Top from "./Top";
import Image from "./Image/Index";
import Information from "./CategoryInfo/Index";
import Submit from "./Submit";
import CategoriesContext from "../../../context/CategoriesContext";
import Response from "../../../Fixed/Response/Index";

const Index = () => {
  const [categoryInfo, setCategoryInfo] = useState({
    image: null,
    name: "",
    metaTitle: "",
    metaDescription: "",
  });

  const { categories, setCategories, loading, error } = useContext(CategoriesContext);

  return (
    <>
      <main className="main-content-wrapper">
        <div className="container">
          <Top />
          <div className="row">
            <div className="col-lg-12 col-12">
              <div className="card mb-6 shadow border-0">
                <div className="card-body p-6 ">
                  <Image
                    categoryInfo={categoryInfo}
                    setCategoryInfo={setCategoryInfo}
                  />
                  <Information
                    categoryInfo={categoryInfo}
                    setCategoryInfo={setCategoryInfo}
                    categories={categories}
                  />
                  <div className="col-lg-12">
                    <Submit
                      categoryInfo={categoryInfo}
                      setCategoryInfo={setCategoryInfo}
                      setCategories={setCategories}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Response loading={loading} error={error} />
    </>
  );
};

export default Index;
