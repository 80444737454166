import React, { useContext, useEffect, useState } from "react";
import Comment from "./Comment/Index";
import Loader from "./Loader";
import Error from "./Error";

const Index = ({ product, loadingComments, commentsError, comments }) => {
  if (loadingComments) {
    return <Loader />;
  }

  if (commentsError) {
    return <Error />;
  }

  return (
    <>
      <div className="mb-10">
        <h3 className="mb-5">Reviews</h3>
        {comments.length === 0 ? (
          <>No reviews yet</>
        ) : (
          comments.map((comment) => <Comment comment={comment} />)
        )}
        {/* <div>
          <a href="#" className="btn btn-outline-gray-400 text-muted">
            Read More Reviews
          </a>
        </div> */}
      </div>
    </>
  );
};

export default Index;
