import React from "react";
import { useState } from "react";

const Images = ({ product, selectedImage, setSelectedImage }) => {
  // Extracting all the links from each object in the ProductImages array
  const thumbnailImages = product.ProductImages.map(image => image.link);
  const handleThumbnailClick = (image) => {
    setSelectedImage(image);
  };

  const handleMouseLeave = (e) => {
    // Reset background position when the mouse leaves
    const zoomedContainer = e.currentTarget;
    zoomedContainer.style.backgroundPosition = "center center";
  };

  const handleMouseMove = (e) => {
    const zoomedContainer = e.currentTarget;
    const { left, top, width, height } =
      zoomedContainer.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;

    zoomedContainer.style.backgroundPosition = `${x}% ${y}%`;
    zoomedContainer.style.backgroundSize = "200% 200%"; // Zoom effect
  };

  return (
    <>
      <div className="col-lg-6">
        <div className="product productModal" id="productModal">
          <div
            className="zoom"
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{
              backgroundImage: `url(${selectedImage})`,
              backgroundSize: "cover",
            }}
          >
            <img
              src={
                selectedImage
              }
              alt=""
            />
          </div>
          <div className="product-tools">
            <div className="thumbnails row g-3" id="productModalThumbnails">
              {thumbnailImages.map((image, index) => (
                <div
                  key={index}
                  className={`col-3 ${
                    selectedImage === image ? "tns-nav-active" : ""
                  }`}
                  onClick={() => handleThumbnailClick(image)}
                >
                  <div className="thumbnails-img">
                    <img src={image} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Images;
