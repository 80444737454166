import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Images from "./Zoom/Images";
import Off from "./Zoom/Off";

const Zoom = ({ product, id }) => {
  const [selectedImage, setSelectedImage] = useState(
    product.ProductImages.length > 0 ? product.ProductImages[0].link : ""
  );

  // Watch for changes in the product or selectedImage state
  useEffect(() => {
    // Update selectedImage whenever the product changes
    setSelectedImage(
      product.ProductImages.length > 0 ? product.ProductImages[0].link : ""
    );
  }, [product]);

  // Ref for the close button element
  const closeButtonRef = useRef(null);

  return (
    <>
      <div style={{zIndex:"2000"}} className="modal fade" id={id} tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-8">
              <div className="position-absolute top-0 end-0 me-3 mt-3">
                <button
                  ref={closeButtonRef}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="row">
                <Images
                  product={product}
                  selectedImage={selectedImage}
                  setSelectedImage={setSelectedImage}
                />
                <div className="col-lg-6">
                  <div className="ps-lg-8 mt-6 mt-lg-0">
                    <label className="mb-4 d-block">
                      <div>
                        <p>
                          <strong>Categories:</strong>
                        </p>
                        <div className="d-flex flex-wrap">
                          {product.Categories.map((category, index) => (
                            <div key={index} className="m-1">
                              <span className="btn btn-outline-secondary btn-sm">
                                {category}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </label>
                    <h2 className="mb-1 h1">{product.Title}</h2>
                    <div className="fs-4">
                      <span className="fw-bold text-dark">
                        Rs{product.ProductPrice.salePrice}
                      </span>
                      {product.ProductPrice.salePrice !==
                        product.ProductPrice.regularPrice && (
                        <>
                          <span className="text-decoration-line-through text-danger">
                            Rs{product.ProductPrice.regularPrice}
                          </span>
                          <Off product={product} />
                        </>
                      )}
                    </div>
                    <hr className="my-6" />
                    <div className="mt-3 row justify-content-start g-2 align-items-center">
                      <div className="col-md-4 col-5">
                        {/* <!-- btn --> */}
                        <label
                          className="btn btn-light"
                          href="#"
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          aria-label="Compare"
                        >
                          <i className="bi bi-arrow-left-right"></i>
                        </label>
                      </div>
                    </div>
                    <hr className="my-6" />
                    <div>
                      <table className="table table-borderless">
                        <tbody>
                          <tr>
                            <td>Availability:</td>
                            <td>
                              {product.InStock ? "Available" : "Out of Stock"}
                            </td>
                          </tr>
                          <tr>
                            <td>Shipping Charges:</td>
                            <td>
                              <small>
                                {product.ShippingCharges ? (
                                  <>Rs {product.ShippingCharges}</>
                                ) : (
                                  "Free"
                                )}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <label className="mb-4 d-block">
                        <div>
                          <p>
                            <strong>Payment Methods:</strong>
                          </p>
                          <div className="d-flex flex-wrap">
                            {product.PaymentMethods.map(
                              (paymentMethod, index) => (
                                <div key={index} className="m-1">
                                  <span className="btn btn-outline-secondary btn-sm">
                                    {paymentMethod}
                                  </span>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Zoom;
