import React, { useContext, useEffect } from "react";
import "./styles.css";

const Index = ({ loading, error, setError }) => {
  // Function to display error message and automatically fade out after 3 seconds
  useEffect(() => {
    if (error) {
      const timeout = setTimeout(() => {
        setError("");
      }, 10000); // Change duration as needed

      return () => clearTimeout(timeout);
    }
  }, [error]);

  return (
    <>
      {/* Add animated progress bar */}
      {loading && (
        <div className="progress-container">
          <div className="progress-bar"></div>
        </div>
      )}
      {/* Display error message with fade-in and fade-out effect */}
      {error && (
        <div role="alert" className="alert alert-danger error-container">
          <div className="error-message">{error}</div>
        </div>
      )}
    </>
  );
};

export default Index;
