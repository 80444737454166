import React, { useContext } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./styles.css";
import OrdersContext from "../../../context/OrdersContext";

const Order = ({ order }) => {
  const { setActiveOrder } = useContext(OrdersContext);

  const navigate = useNavigate();

  // Function to format date in "24 Nov 2022" pattern
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const handleOrder = () => {
    setActiveOrder(order);
    navigate("/orders/orderDetail");
  };

  return (
    <>
      <tr key={order._id}>
        <td>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="productOne"
            />
            <label className="form-check-label" for="productOne"></label>
          </div>
        </td>
        <td>
          <div className="customer-info">
            <img src={order.userId?.profile} alt="" className="profile-image" />
            <label className="customer-name">{order.userId?.name} </label>
          </div>
        </td>

        <td>
          {order.products.length} product
          {order.products.length > 1 ? "s" : ""}
        </td>
        <td>
          {order.status === "Order has been rejected" ? (
            <label className="bg-danger p-2 text-white">{order.status}</label>
          ) : order.status === "Delivered successfully!" ? (
            <label className="bg-success p-2 text-white">{order.status}</label>
          ) : (
            <label className="bg-warning p-2 text-white">{order.status}</label>
          )}
        </td>

        <td>
          <div className="shipping-address">
            <div>{order.shippingAddress.completeAddress}</div>
            <div>
              {order.shippingAddress.city}, {order.shippingAddress.district},{" "}
              {order.shippingAddress.country}
            </div>
            <div>Zip Code: {order.shippingAddress.zipCode}</div>
          </div>
        </td>

        <td>Rs {order.summary.grandTotal}</td>

        <td>{formatDate(order.date)}</td>
        <td>
          <Link state={{ order: order }} to="/orders/orderDetail">
            <FaArrowRight className="text-success" />
          </Link>
        </td>
      </tr>
    </>
  );
};

export default Order;
