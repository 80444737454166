import React from "react";
import Type from "./Type/Index";
import Name from "./Name/Index";
import MetaData from "./MetaData/Index";

const Index = ({ categoryInfo, setCategoryInfo }) => {
  return (
    <>
      <h4 className="mb-4 h5 mt-5">Category Information</h4>

      <div className="row">
        <Type categoryInfo={categoryInfo} setCategoryInfo={setCategoryInfo} />
        <Name categoryInfo={categoryInfo} setCategoryInfo={setCategoryInfo} />
        {/* <!-- input --> */}
        <div className="mb-3 col-lg-12 ">
          
          <div className="py-4" id="editor"></div>
        </div>

        {/* <!-- input --> */}
        <div className="mb-3 col-lg-12 ">{/* <!-- input --> */}</div>
        <MetaData
          categoryInfo={categoryInfo}
          setCategoryInfo={setCategoryInfo}
        />
      </div>
    </>
  );
};

export default Index;
