import React from "react";

const Index = ({
  selectedNode,
  treeData,
  setTreeData,
  setSelectedNode,
  setAction,
}) => {
  const handleDeleteNode = () => {
    if (!selectedNode) return;

    const removeNode = (node, nodeNameToRemove) => {
      if (node.name === nodeNameToRemove) return null;
      if (node.children) {
        node.children = node.children
          .map((child) => removeNode(child, nodeNameToRemove))
          .filter((child) => child !== null);
      }
      return node;
    };

    const updatedTree = removeNode({ ...treeData }, selectedNode.data.name);
    setTreeData(updatedTree);
    setSelectedNode(null);
    setAction(null);
  };

  return (
    <>
      <button className="btn btn-danger" onClick={handleDeleteNode}>
        Delete Node {selectedNode.data.name}
      </button>
    </>
  );
};

export default Index;
