import React from "react";

const Tabs = () => {
  return (
    <>
      <ul className="nav nav-pills nav-lb-tab" id="myTab" role="tablist">
        {/* <!-- nav item --> */}
        <li className="nav-item" role="presentation">
          {/* <!-- btn -->  */}
          <button
            className="nav-link active"
            id="product-tab"
            data-bs-toggle="tab"
            data-bs-target="#product-tab-pane"
            type="button"
            role="tab"
            aria-controls="product-tab-pane"
            aria-selected="true"
          >
            Product Details
          </button>
        </li>
        {/* <!-- nav item --> */}
        <li className="nav-item" role="presentation">
          {/* <!-- btn --> */}
          <button
            className="nav-link"
            id="details-tab"
            data-bs-toggle="tab"
            data-bs-target="#details-tab-pane"
            type="button"
            role="tab"
            aria-controls="details-tab-pane"
            aria-selected="false"
          >
            Information
          </button>
        </li>
        {/* <!-- nav item --> */}
        <li className="nav-item" role="presentation">
          {/* <!-- btn -->  */}
          <button
            className="nav-link"
            id="reviews-tab"
            data-bs-toggle="tab"
            data-bs-target="#reviews-tab-pane"
            type="button"
            role="tab"
            aria-controls="reviews-tab-pane"
            aria-selected="false"
          >
            Reviews
          </button>
        </li>
        {/* <!-- nav item --> */}
        <li className="nav-item" role="presentation">
          {/* <!-- btn -->  */}
          <button
            className="nav-link"
            id="sellerInfo-tab"
            data-bs-toggle="tab"
            data-bs-target="#sellerInfo-tab-pane"
            type="button"
            role="tab"
            aria-controls="sellerInfo-tab-pane"
            aria-selected="false"
            disabled
          >
            Seller Info
          </button>
        </li>
      </ul>
    </>
  );
};

export default Tabs;
