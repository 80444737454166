import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Title from "./Title/Index";
import Categories from "./Categories/Index.js";
import Sizes from "./Sizes/Index.js";
import ProductImages from "./ProductImages/Index";
import ProductDescription from "./ProductDescription/Index";
import InStock from "./InStock/Index";
import PaymentMethods from "./PaymentMethods/Index.js";
import ProductCode from "./ProductCode/Index.js";
import ShippingCharges from "./ShippingCharges/Index.js";
import ProductPrice from "./ProductPrice/Index";
import ImageUpdate from "./Functions/ImageUpdate.js";
import updateProduct from "./Functions/updateProduct.js";
import CatProContext from "../../../context/CatProContext.js";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const {product} = location.state

  const { productUpdate, updateProductImages } = useContext(CatProContext);
  const [validationResults, setValidationResults] = useState({
    Title: { error: "Title field is required", data: "" },
    Categories: { error: "", data: "" },
    Sizes: { error: "", data: "" },
    ProductImages: { error: "Please upload some product images", data: "" },
    ProductDescription: { error: "Please describe product", data: "" },
    InStock: { error: "", data: true },
    PaymentMethods: { error: "", date: ["Cash on Delivery"] },
    ProductCode: { error: "", date: "" },
    ShippingCharges: { error: "", date: 0 },
    ProductPrice: { error: "Product price section required", data: "" },
  });

  const [resetForm, setResetForm] = useState(false);
  const [submitResponse, setSubmitResponse] = useState("");

  const handleSubmit = async () => {
    // Pass necessary parameters to the imported function
    await updateProduct(
      validationResults,
      productUpdate,
      ImageUpdate,
      updateProductImages,
      setSubmitResponse,
      setValidationResults,
      setResetForm,
      product._id,
      // Callback function to update the product data
      (updatedProduct) => {
        // Update the product data in the state
        navigate("/products/updateProduct", {
          state: { product: updatedProduct },
        });
      }
    );
  };

  return (
    <main className="main-content-wrapper">
      {/* <!-- container --> */}
      <div className="container">
        {/* <!-- row --> */}
        <div className="row">
          <div className="col-lg-8 col-12">
            {/* <!-- card --> */}
            <div className="card mb-6 card-lg">
              {/* <!-- card body --> */}
              <div className="card-body p-6 ">
                <h4 className="mb-4 h5">Updating the product</h4>
                <div className="row">
                  <Title
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                    product={product}
                  />
                  <Categories
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                    product={product}
                  />
                  <Sizes
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                    product={product}
                  />
                  <div className="mt-5">
                    Colors:
                    {product.Colors?.map((color, index) => (
                      <div>{color}</div>
                    ))}
                  </div>
                  <ProductImages
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                    product={product}
                  />
                  <ProductDescription
                    setValidationResults={setValidationResults}
                    resetForm={resetForm}
                    product={product}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="card mb-6 card-lg">
              <div className="card-body p-6">
                <InStock
                  setValidationResults={setValidationResults}
                  resetForm={resetForm}
                  product={product}
                />
              </div>
            </div>
            <div className="card mb-6 card-lg">
              <div className="card-body p-6">
                <PaymentMethods
                  setValidationResults={setValidationResults}
                  resetForm={resetForm}
                  product={product}
                />
                <ProductCode
                  setValidationResults={setValidationResults}
                  resetForm={resetForm}
                  product={product}
                />
                <ShippingCharges
                  setValidationResults={setValidationResults}
                  resetForm={resetForm}
                  product={product}
                />
              </div>
            </div>
            <ProductPrice
              setValidationResults={setValidationResults}
              resetForm={resetForm}
              product={product}
            />
            {/* <!-- button --> */}
            <div className="d-grid">
              <button onClick={handleSubmit} className="btn btn-primary">
                Update Product
              </button>
              {submitResponse && (
                <div>
                  <h3>Response</h3>
                  {submitResponse}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Index;
