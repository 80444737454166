import React from "react";

const Off = ({ product }) => {
  const calculateOff = () => {
    const discountPercentage =
      ((product.ProductPrice.regularPrice - product.ProductPrice.salePrice) /
        product.ProductPrice.regularPrice) *
      100;
    return discountPercentage;
  };
  return (
    <>
      <span>
        <small className="fs-6 ms-2 text-danger">
          {calculateOff().toFixed(0)}% Off
        </small>
      </span>
    </>
  );
};

export default Off;
