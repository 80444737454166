import React from "react";
import Loader from "../Loader";
import Error from "../Error";
import Category from "./Category";

const Index = ({
  loading,
  error,
  categories,
  setCategories,
  setDefaultCategories,
  defaultCategories,
}) => {
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <div className="card-body p-0">
      {/* <!-- table --> */}
      <div className="table-responsive">
        <table className="table table-centered table-hover text-nowrap table-borderless mb-0 table-with-checkbox">
          <thead className="bg-light">
            <tr>
              <th>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="checkAll"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="checkAll"
                  ></label>
                </div>
              </th>
              <th>Image</th>
              <th>Cateogry Name</th>
              <th>Created at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* Iterate through categories hierarchy */}
            {categories.map((category) => (
              <Category
                category={category}
                setCategories={setCategories}
                categories={categories}
                setDefaultCategories={setDefaultCategories}
                defaultCategories={defaultCategories}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Index;
