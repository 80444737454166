import { createContext, useState, useEffect } from "react";
import host from "./host";

const BadgesContext = createContext();

export const BadgesProvider = ({ children }) => {
  // Update Badges
  const updateBadges = async (badges, updateId) => {
    // API Call
    const response = await fetch(
      `${host}/admin/badges/updateBadges/${updateId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(badges),
      }
    );
    const json = await response.json();
    return json;
  };

  return (
    <BadgesContext.Provider value={{ updateBadges }}>
      {children}
    </BadgesContext.Provider>
  );
};

export default BadgesContext;
