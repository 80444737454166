import React, { useContext, useEffect, useState } from "react";
import CatProContext from "../../../context/CatProContext";
import CategoriesContext from "../../../context/CategoriesContext";
import Loader from "./Loader";
import Error from "./Error";
import Main from "./Main";

const Index = () => {
  const { defaultProducts, loadingProducts, productsError } =
    useContext(CatProContext);
  const [initialTree, setInitialTree] = useState(null);
  const [loadingTree, setLoadingTree] = useState(false);
  const [treeDataError, setTreeDataError] = useState("");
  const { fetchCategoryTree } = useContext(CategoriesContext);

  // Render Tree Data
  useEffect(() => {
    async function fetchTreeData() {
      setLoadingTree(true);
      const response = await fetchCategoryTree();
      if (response.success) {
        setTreeDataError("");
        setInitialTree(response.categoryTree.treeData);
      } else {
        setTreeDataError("Some Error Occured");
      }
      setLoadingTree(false);
    }

    fetchTreeData();
  }, []);

  if (loadingProducts || loadingTree) {
    return <Loader />;
  }

  if (productsError || treeDataError) {
    return <Error />;
  }

  if (initialTree) {
    return (
      <>
        <Main
          initialTree={initialTree}
          products={defaultProducts}
          setInitialTree={setInitialTree}
        />
      </>
    );
  }
};

export default Index;
