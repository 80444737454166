import React, { useContext, useEffect, useState } from "react";
import ProductInfo from "./ProductInfo/Index";
import RelatedProducts from "./RelatedProducts/Index";
import Zoom from "../../Pages/Modals/Zoom";
import ZoomSection from "./ZoomSection/Index";
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import Error from "./Error";
import CatProContext from "../../context/CatProContext";

const Index = () => {
  const { productId } = useParams();
  // Context
  const { defaultProducts, getProducts, loadingProducts, productsError } =
    useContext(CatProContext);

  const [product, setProduct] = useState(null);

  if (loadingProducts) {
    console.log("Loading the products");
  } else {
    console.log("Products fetched successfully!!!");
  }
  useEffect(() => {
    if (defaultProducts.length === 0) {
      getProducts();
    }
  }, [defaultProducts, getProducts]);

  useEffect(() => {
    if (defaultProducts.length > 0) {
      // Find the product from the defaultProducts array
      const foundProduct = defaultProducts.find(
        (prod) => prod._id === productId
      );
      if (foundProduct) {
        setProduct(foundProduct);
      }
    }
  }, [defaultProducts, productId]);

  // Scroll to the top
  useEffect(() => {
    let bestSellTop = document.getElementById("top").offsetTop;

    // Calculate the difference from top and minus it
    const difference = window.innerWidth > 600 ? 150 : 80;
    bestSellTop = bestSellTop - difference;

    window.scrollTo({ top: bestSellTop, behavior: "smooth" });
  }, [productId]);

  if (loadingProducts || defaultProducts.length === 0) {
    return <Loader />;
  }

  if (productsError) {
    return (
      <>
        <Error error="Some backend issue occured" />
      </>
    );
  }

  if (product === null) {
    return (
      <>
        <Error error="Invalid product id" />
      </>
    );
  }

  return (
    <>
      <main className="main-content-wrapper">
        <div className="container">
          <div id="top">
            <div>
              <ZoomSection product={product} />
            </div>
            <ProductInfo product={product} />
            <RelatedProducts product={product} products={defaultProducts} />
            <Zoom product={product} />
          </div>
        </div>
      </main>
    </>
  );
};

export default Index;
