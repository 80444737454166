import React, { useState } from "react";
import functionToAddChild from "../utils/addChild";

const Index = ({
  setTreeData,
  selectedNode,
  setSelectedNode,
  treeData,
  setAction,
}) => {
  const [childName, setChildName] = useState("");

  const handleAddChild = () => {
    const leaf = false;
    functionToAddChild(
      childName,
      setTreeData,
      selectedNode,
      treeData,
      setAction,
      leaf
    );
    setSelectedNode(null);
  };

  // Helper function to capitalize first letter of each word
  const capitalizeFirstLetter = (string) => {
    return string
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleChange = (e) => {
    const value = capitalizeFirstLetter(e.target.value);
    setChildName(value);
  };

  return (
    <div className="container mt-4">
      <div className="form-group">
        <label htmlFor="childName">Enter Child name here:</label>
        <input
          autoComplete="off"
          type="text"
          className="form-control"
          id="childName"
          placeholder="Enter child name"
          value={childName}
          onChange={handleChange}
        />
      </div>
      <button className="btn btn-primary mt-2" onClick={handleAddChild}>
        Save
      </button>
    </div>
  );
};

export default Index;
