const functionToRenameNode = (
  newName,
  setTreeData,
  selectedNode,
  treeData,
  setAction
) => {
  if (isNameUnique(treeData, newName)) {
    const updatedChart = renameChild(treeData, selectedNode.data.name, newName);

    setTreeData(updatedChart);
    setAction(null);
  } else {
    alert("Name must be unique within the entire tree structure.");
  }
};

// Helper function to check if a name is unique within the treeData
const isNameUnique = (tree, nameToCheck) => {
  if (tree.name === nameToCheck) {
    return false; // Found duplicate name at current node
  }

  if (tree.children) {
    for (let child of tree.children) {
      if (!isNameUnique(child, nameToCheck)) {
        return false; // Found duplicate name in one of the children
      }
    }
  }

  return true; // Name is unique in this subtree
};

//   Helper function
const renameChild = (tree, nodeName, newName) => {
  if (tree.name === nodeName) {
    const updatedNode = {
      ...tree,
      name: newName,
    };
    return updatedNode;
  }

  if (tree.children) {
    return {
      ...tree,
      children: tree.children.map((child) =>
        renameChild(child, nodeName, newName)
      ),
    };
  }

  return tree;
};

export default functionToRenameNode;
