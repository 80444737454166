import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import TopBar from "./TopBar/Index";
import ListOfProducts from "./List/Index";
import Bottom from "./Bottom/Index";
import CatProContext from "../../context/CatProContext";
import Checked from "./Checked/Index"

const Index = () => {
  const {
    getProducts,
    products,
    setProducts,
    defaultProducts,
    loadingProducts,
    productsError,
  } = useContext(CatProContext);

  return (
    <main className="main-content-wrapper">
      <div className="container">
        <Header />
        {/* <!-- row --> */}
        <div className="row ">
          <div className="col-xl-12 col-12 mb-5">
            {/* <!-- card --> */}
            <div className="card h-100 card-lg">
              <TopBar
                setProducts={setProducts}
                products={products}
                defaultProducts={defaultProducts}
                getProducts={getProducts}
              />
              <Checked />
              <ListOfProducts
                loading={loadingProducts}
                error={productsError}
                products={products}
                setProducts={setProducts}
              />
              <Bottom products={products} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Index;
