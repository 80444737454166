import React from "react";
import { useDrop, useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes"; // Define your item types

const ChildDropZone = ({ children, subsChild }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.CHILD,
    drop: (droppedItem) => subsChild(droppedItem.childId, droppedItem.parent),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  return <div ref={drop}>{children}</div>;
};

export default ChildDropZone;
