import React from "react";
import Status from "./Status/Index";

const Index = ({ order, setLoading, setError }) => {
  return (
    <>
      <div className="d-md-flex justify-content-between">
        <div className="d-flex flex-wrap align-items-center mb-2 mb-md-0">
          <h2 className="mb-0">Order ID: {order._id}</h2>
        </div>
        {/* <!-- select option --> */}
        <div className="d-md-flex">
          <Status order={order} setLoading={setLoading} setError={setError} />
          {/* <!-- button --> */}
          
        </div>
      </div>
    </>
  );
};

export default Index;
