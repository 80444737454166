const matchingProducts = (categories, products) => {
  // Initialize arrays to hold relevant and irrelevant products
  let relevantProducts = [];
  let irrelevantProducts = [];

  // Iterate over the products array
  products.forEach((product) => {
    // Check if any category of the product matches with the given categories
    const isRelevant = categories.some((category) =>
      product.Categories.includes(category)
    );

    // Categorize products into relevant and irrelevant
    if (isRelevant) {
      // Check if the product is not already in the relevantProducts array
      if (!relevantProducts.some((p) => p._id === product._id)) {
        relevantProducts.push(product);
      }
    } else {
      irrelevantProducts.push(product);
    }
  });

  // Concatenate irrelevantProducts with relevantProducts
  relevantProducts = [...relevantProducts, ...irrelevantProducts];
  return relevantProducts
};

export default matchingProducts