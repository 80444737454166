const updateProduct = async (
  validationResults,
  updateProduct,
  imageUpdate,
  updateProductImages,
  setSubmitResponse,
  setValidationResults,
  setResetForm,
  updateId,
  updateProductCallback // Callback function to update product data
) => {
  // Check for errors in validationResults
  const errors = Object.entries(validationResults).filter(
    ([key, value]) => value.error !== ""
  );

  if (errors.length > 0) {
    // Display errors if any
    const errorList = errors.map(([key, value]) => (
      <li key={key}>
        <strong>{key}:</strong>{" "}
        {typeof value === "object" ? value.error : value}
      </li>
    ));
    setSubmitResponse(
      <ul className="list-unstyled text-danger">{errorList}</ul>
    );
  } else {
    // No errors, gather data and call createProduct function
    setSubmitResponse(
      <div className="text-success">Updating the product. Please wait...</div>
    );
    // Gather data from each component
    const titleData = validationResults.Title.data;
    const categoriesData = validationResults.Categories.data;
    const sizesData = validationResults.Sizes.data;
    const productImagesData = validationResults.ProductImages.data;
    const productDescriptionData = validationResults.ProductDescription.data;
    const inStockData = validationResults.InStock.data;
    const paymentMethods = validationResults.PaymentMethods.data;
    const productCode = validationResults.ProductCode.data;
    const shippingCharges = validationResults.ShippingCharges.data;
    const productPriceData = validationResults.ProductPrice.data;

    // Combine data into a single object
    const productData = {
      Title: titleData,
      Categories: categoriesData,
      Sizes: sizesData,
      ProductDescription: productDescriptionData,
      InStock: inStockData,
      PaymentMethods: paymentMethods,
      ProductCode: productCode,
      ShippingCharges: shippingCharges,
      ProductPrice: productPriceData,
    };
    // Call createProduct function with the gathered data
    const response = await updateProduct(updateId, productData);

    //If error occurs in mongodb side
    if (!response.success) {
      setSubmitResponse(<div className="text-danger">{response.error}</div>);
    } else {
      //mongodb side successfull. Now upload product images
      setSubmitResponse(
        <div className="text-success">
          Text data updated. Now updating files on cloud storage...
        </div>
      );
      const handleUploadResponse = (response) => {
        // Handle the response here
        if (!response.success) {
          setSubmitResponse(
            <div className="text-danger">{response.error}</div>
          );
        } else {
          // Display success response
          setSubmitResponse(
            <div className="text-success">Product Updated successfully!</div>
          );
          updateProductCallback(response.data);
        }

        // return updated product here
      };

      // Now upload the image/images
      const modal = "products";
      await imageUpdate(
        productImagesData,
        modal,
        updateId,
        updateProductImages,
        handleUploadResponse
      );
    }
  }
};

export default updateProduct;
