import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Products from "./Products/Index";
import Name from "./Name/Index";

const Index = () => {
  const location = useLocation();
  const { products } = location.state;
  const [displayProducts, setDisplayProducts] = useState(products);

  return (
    <main className="main-content-wrapper">
      <div className="container">
        <div className="mb-4">
          {displayProducts.length} products selected to create section
        </div>
        <Products products={displayProducts} setProducts={setDisplayProducts} />
        <Name products={displayProducts} />
      </div>
    </main>
  );
};

export default Index;
