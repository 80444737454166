import React, { useContext, useState, useEffect } from "react";
import Tree from "react-d3-tree";
import AddChildComponent from "./AddChild/Index";
import Update from "./Update/Index";
import GenuineCategories from "./GenuineCategories/Index";
import Rename from "./RenameNode/Index";
import DeleteNode from "./DeleteNode/Index";

const Main = ({ initialTree, products, setInitialTree }) => {
  const [treeData, setTreeData] = useState(initialTree);
  const [selectedNode, setSelectedNode] = useState(null);
  const [action, setAction] = useState(null);
  const [isTreeDataChanged, setIsTreeDataChanged] = useState(false);

  useEffect(() => {
    setIsTreeDataChanged(
      JSON.stringify(treeData) !== JSON.stringify(initialTree)
    );
  }, [treeData, initialTree]);

  const handleNodeClick = (node) => {
    setSelectedNode(node);
  };

  return (
    <>
      <main className="main-content-wrapper">
        <div className="container">
          <Update
            treeData={treeData}
            isTreeDataChanged={isTreeDataChanged}
            setInitialTree={setInitialTree}
          />
          <GenuineCategories
            products={products}
            setTreeData={setTreeData}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            treeData={treeData}
            setAction={setAction}
          />
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "100%", height: "80%", border: "1px solid #ccc" }}
            >
              <Tree
                data={treeData}
                orientation="vertical"
                translate={{ x: 300, y: 100 }}
                onNodeClick={handleNodeClick}
              />
            </div>

            {selectedNode && (
              <div style={{ marginTop: "20px" }}>
                {!selectedNode.data.leaf && (
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setAction("Add Child");
                    }}
                  >
                    Add Child to {selectedNode.data.name}
                  </button>
                )}

                {selectedNode.data.name !== "Tree Starts" &&
                  !selectedNode.data.leaf && (
                    <>
                      <button
                        className="btn btn-primary mx-2"
                        onClick={() => {
                          setAction("Rename Node");
                        }}
                      >
                        Rename Node {selectedNode.data.name}
                      </button>
                    </>
                  )}

                {selectedNode.data.name !== "Tree Starts" && (
                  <>
                    <DeleteNode
                      selectedNode={selectedNode}
                      treeData={treeData}
                      setTreeData={setTreeData}
                      setSelectedNode={setSelectedNode}
                      setAction={setAction}
                    />
                  </>
                )}
              </div>
            )}

            {action === "Add Child" && (
              <AddChildComponent
                setTreeData={setTreeData}
                selectedNode={selectedNode}
                setSelectedNode={setSelectedNode}
                treeData={treeData}
                setAction={setAction}
              />
            )}
            {action === "Rename Node" && (
              <Rename
                setTreeData={setTreeData}
                selectedNode={selectedNode}
                treeData={treeData}
                setAction={setAction}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Main;
