const updateParentsWithChildObjects = (parents, childs) => {
    // Copying the parents array to avoid mutating the original state
    const updatedParents = parents.map(parent => {
      const updatedChilds = parent.childs.map(child => {
        // Finding the child object by id
        const childObject = childs.find(c => c._id === child.childId);
        // If child object found, replace childId with the child object
        if (childObject) {
          return { ...child, childId: childObject };
        }
        return child; // If not found, return the original child
      });
      return { ...parent, childs: updatedChilds }; // Return parent with updated childs array
    });
  
    // Removing associated child objects from the childs array
    const unassociatedChilds = childs.filter(child => {
      // Check if the child is not associated with any parent
      return !updatedParents.some(parent => parent.childs.some(c => c.childId._id === child._id));
    });
  
    return { updatedParents, unassociatedChilds };
  };

  export default updateParentsWithChildObjects
  
  
  