import React, { useContext } from "react";
import OrdersContext from "../../../../../../context/OrdersContext";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import EmailContext from "../../../../../../context/EmailContext";

const Index = ({ order, setLoading, setError }) => {
  const { updateStatus } = useContext(OrdersContext);
  const { sendEmail } = useContext(EmailContext);

  const navigate = useNavigate();

  const handleChange = async (e) => {
    setLoading(true);
    const status = e.target.value;
    const response = await updateStatus(order._id, status);
    if (response.success) {
      navigate("/orders/orderDetail", { state: { order: response.order } });

      if (status === "Order confirmed! On the way") {
        // Send mail to this user
        await sendEmail(
          order.userId.email,
          "Order Confirmed!",
          `Dear customer, your order with order ID ${order._id} has been confirmed. The order is expected to be delivered within 5-7 working days. Thanks!`
        );
      } else if (status === "Order has been rejected") {
        // Send mail to this user
        await sendEmail(
          order.userId.email,
          "Order Rejected :(",
          `Dear customer, your order with order ID ${order._id} has been rejected.`
        );
      } else if (status === "Delivered successfully!") {
        // Send mail to this user
        await sendEmail(
          order.userId.email,
          "Order Delivered",
          `Dear customer, your order with order ID ${order._id} has been delivered successfully!. Thanks for shopping with OWAISY.`
        );
      }
    } else {
      setError(response.message);
    }
    setLoading(false);
  };
  return (
    <>
      <DropdownButton
        id="dropdown-basic-button"
        title={order.status}
        variant="outline-secondary"
        className="mb-2 mb-md-0"
      >
        {order.status === "Waiting for confirmation" && (
          <>
            <Dropdown.Item
              className="bg-success text-white mb-2"
              onClick={() =>
                handleChange({
                  target: { value: "Order confirmed! On the way" },
                })
              }
            >
              Accept
            </Dropdown.Item>
            <Dropdown.Item
              className="bg-danger text-white mb-2"
              onClick={() =>
                handleChange({ target: { value: "Order has been rejected" } })
              }
            >
              Reject
            </Dropdown.Item>
          </>
        )}

        {order.status === "Order confirmed! On the way" && (
          <>
            <Dropdown.Item
              className="bg-success text-white mb-2"
              onClick={() =>
                handleChange({
                  target: { value: "Delivered successfully!" },
                })
              }
            >
              Delivered!
            </Dropdown.Item>
          </>
        )}
      </DropdownButton>
    </>
  );
};

export default Index;
