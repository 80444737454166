import React from "react";
import Search from "./Search";
import Filters from "./Filters";

const Index = ({ setCategories, categories, defaultCategories }) => {
  return (
    <>
      <div className="px-6 py-6 ">
        <div className="row justify-content-between">
          {/* <!-- form --> */}
          <Search
            setCategories={setCategories}
            categories={categories}
            defaultCategories={defaultCategories}
          />
          {/* <!-- select option --> */}
          <Filters
            setCategories={setCategories}
            defaultCategories={defaultCategories}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
