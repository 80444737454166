import React, { useState } from "react";
import Response from "./Response/Index";

const Submit = ({ info }) => {
  const [error, setError] = useState("");

  const handleClick = async () => {
    if (
      info.email === "dashboard@owaisy" &&
      info.password === "team_owaisy786123"
    ) {
      localStorage.setItem("adminAuth", true);
      window.location.reload(); 
    } else {
      setError("User name or password is incorrect");
      localStorage.setItem("adminAuth", false);
    }
  };
  return (
    <>
      <div className="col-12 d-grid">
        {" "}
        <div onClick={handleClick} className="btn btn-primary">
          Sign In
        </div>
        <div className="mt-2">
          <Response error={error} />
        </div>
      </div>
    </>
  );
};

export default Submit;
