import React, { useContext, useEffect, useState } from "react";
import CatProContext from "../../../../../context/CatProContext";

const Index = ({ categoryInfo, setCategoryInfo }) => {
  // State to manage whether to display the parent category selector
  const [showParentCategorySelector, setShowParentCategorySelector] =
    useState(false);

  const { getCategories } = useContext(CatProContext);
  const [parents, setParents] = useState([]);

  useEffect(() => {
    const fetchParentCategories = async () => {
      try {
        // Call getCategories function
        const response = await getCategories();

        // Filter out only the categories with type "Parent"
        const parentCategories = response.categories.filter(
          (category) => category.type === "Parent"
        );

        setParents(parentCategories);
      } catch (error) {
        // Handle error if any
        console.error("Error fetching parent categories:", error);
      }
    };

    // Call the function to fetch parent categories when component mounts
    fetchParentCategories();

    // If getCategories function changes infrequently and you want to fetch new data
    // only when it changes, add getCategories to the dependency array
  }, [getCategories]);

  // Function to handle category type change
  const handleCategoryTypeChange = (e) => {
    const selectedCategory = e.target.value;
    // Show or hide parent category selector based on the selected category type
    if (selectedCategory === "Parent") {
      setShowParentCategorySelector(false);
    } else {
      setShowParentCategorySelector(true);
    }
    setCategoryInfo({ ...categoryInfo, type: selectedCategory });
  };

  // Function to handle parent change
  const handleParentChange = (e) => {
    const selectedParent = e.target.value;
    setCategoryInfo({ ...categoryInfo, parentCategory: selectedParent });
  };

  return (
    <>
      <div className="mb-3 col-lg-6">
        <label className="form-label">Category Type</label>
        <select
          className="form-select"
          value={categoryInfo.type}
          onChange={handleCategoryTypeChange}
        >
          <option>Select Category Type</option>
          <option value="Parent">Parent</option>
          <option value="Child" disabled={parents.length === 0 ? true : false}>
            Child
          </option>
        </select>
      </div>
      {showParentCategorySelector && (
        <div className="mb-3 col-lg-6" style={{ transition: "opacity 0.5s" }}>
          <label className="form-label">Select Its Parent Category</label>
          <select
            className="form-select"
            value={categoryInfo.parentCategory}
            onChange={handleParentChange}
          >
            <option value="" selected>
              Select a parent
            </option>
            {parents.map((parentItem) => (
              <option key={parentItem.name} value={parentItem.name}>
                {parentItem.name}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
};

export default Index;
