import React, { useState, useEffect } from "react";
import "./crop.css"; // Import a separate CSS file for styling
import Actions from "./Actions/Index";

const UploadedImages = ({
  productImages,
  setProductImages,
  setValidationResults,
}) => {
  // State to keep track of the currently selected image index
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  // State to store the images locally
  const [localProductImages, setLocalProductImages] = useState([]);

  // UseEffect to reset the selectedImageIndex when croppedImages change
  useEffect(() => {
    setSelectedImageIndex(null);
    setLocalProductImages(productImages);
  }, [productImages]);

  // Function to chunk the array into rows
  const chunkArray = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  };

  // Chunk the cropped images into rows
  const rows = chunkArray(localProductImages, 3);

  return (
    <div>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} style={{ display: "flex", marginBottom: "10px" }}>
          {row.map((productImage, index) => (
            <div
              key={index}
              style={{
                border:
                  rowIndex === selectedImageIndex
                    ? "2px solid red"
                    : "2px solid transparent",
                marginRight: index < row.length - 1 ? "10px" : "0",
                flex: 1,
                position: "relative",
              }}
              onClick={() => setSelectedImageIndex(rowIndex)}
            >
              <img
                src={productImage.image}
                alt={`Product Image ${rowIndex * 3 + index}`}
                style={{ width: "100%" }}
              />
              {selectedImageIndex === rowIndex && (
                <>
                  <Actions
                    productImages={productImages}
                    currentImage={productImage}
                    setProductImages={setProductImages}
                    rowIndex={rowIndex}
                    index={index}
                  />
                </>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default UploadedImages;
