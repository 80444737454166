import { createContext, useState, useEffect } from "react";
import host from "./host";

const CatProContext = createContext();

export const CartProvider = ({ children }) => {
  // Brands
  // osc
  // minnie
  const brand = "osc";

  // Products
  const [products, setProducts] = useState([]);
  const [defaultProducts, setDefaultProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [productsError, setProductsError] = useState(null);

  // Render Products
  useEffect(() => {
    async function fetchProducts() {
      try {
        const response = await getProducts();
      } catch (error) {
        //
      }
    }

    fetchProducts();
  }, []);

  const createProduct = async (productData) => {
    // Create a FormData object
    const formData = new FormData();

    // Append text data
    formData.append("Title", productData.Title);
    formData.append("Categories", JSON.stringify(productData.Categories)); // Serialize Categories as a JSON string
    formData.append("Sizes", JSON.stringify(productData.Sizes)); // Serialize Sizes as a JSON string if it's an array
    formData.append("ProductDescription", productData.ProductDescription);
    formData.append("InStock", productData.InStock);
    formData.append(
      "PaymentMethods",
      JSON.stringify(productData.PaymentMethods)
    ); // Serialize PaymentMethods as a JSON string if it's an array
    formData.append("ShippingCharges", productData.ShippingCharges);
    // Serialize ProductPrice as a JSON string
    formData.append("ProductPrice", JSON.stringify(productData.ProductPrice));
    // Serialize ProductCode as a JSON string
    formData.append("ProductCode", JSON.stringify(productData.ProductCode));

    // Append product images (with blobs and variants)
    productData.ProductImages.forEach((image, index) => {
      formData.append(`ProductImages[${index}][blobObj]`, image.blobObj);
      formData.append(`ProductImages[${index}][variant]`, image.variant);
    });

    // API Call
    const response = await fetch(`${host}/admin/products/createProduct`, {
      method: "POST",
      body: formData,
    });

    const json = await response.json();
    return json;
  };

  //get list of Products
  const getProducts = async () => {
    try {
      //API Call
      setLoadingProducts(true);
      const response = await fetch(`${host}/admin/products/getProducts`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (json.success) {
        setProducts(json.data);
        setDefaultProducts(json.data);
        setLoadingProducts(false);
      } else {
        setProductsError("Error fetching products");
        setLoadingProducts(false);
      }
    } catch (error) {
      setProductsError(error.message);
      console.log(error);
      setLoadingProducts(false);
    } finally {
      setLoadingProducts(false);
    }
  };

  //get list of Parent Categories
  const getCategories = async () => {
    //API Call
    const response = await fetch(`${host}/admin/categories/getCategories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    return json;
  };

  // Delete Product
  const deleteProduct = async (productId) => {
    // API Call
    const response = await fetch(
      `${host}/admin/products/deleteProduct/${productId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    return json;
  };

  // Update Product
  const productUpdate = async (updateId, updatedProduct) => {
    // API Call
    const response = await fetch(
      `${host}/admin/products/updateProduct/${updateId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedProduct),
      }
    );
    const json = await response.json();
    return json;
  };

  // Upload Image
  const uploadImagesOnCloudStorage = async (formData, modal, uploadId) => {
    //API Call
    const response = await fetch(
      `${host}/images/imageUpload/uploadImagesOnCloudStorage?modal=${modal}&uploadId=${uploadId}`,
      {
        method: "POST",
        body: formData,
      }
    );
    const json = await response.json();
    return json;
  };

  // Update Images
  const updateProductImages = async (formData, modal, updateId, oldImages) => {
    //API Call for new images with oldImages query parameter
    const response = await fetch(
      `${host}/images/imageUpdate/updateImagesOnCloudStorage?modal=${modal}&updateId=${updateId}&oldImages=${JSON.stringify(
        oldImages
      )}`,
      {
        method: "POST",
        body: formData,
      }
    );
    const json = await response.json();
    return json;
  };

  return (
    <CatProContext.Provider
      value={{
        createProduct,
        getProducts,
        deleteProduct,
        productUpdate,
        updateProductImages,
        uploadImagesOnCloudStorage,
        getCategories,
        brand,
        products,
        setProducts,
        defaultProducts,
        loadingProducts,
        productsError,
      }}
    >
      {children}
    </CatProContext.Provider>
  );
};

export default CatProContext;
