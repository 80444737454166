import React, { useState } from "react";
import Rename from "./Rename/Index"

const Index = ({ product, setProducts }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <td>
        <a onClick={() => setShowModal(true)}>
          <img
            src={
              product.ProductImages.length > 0
                ? product.ProductImages[0].link
                : ""
            }
            alt=""
            className="icon-shape icon-md"
          />
        </a>
        {showModal && (
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block", zIndex: "2000" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div>
                    <div>{product.Title}</div>
                    <div><Rename product={product} setProducts={setProducts} /></div>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowModal(false)}
                  ></button>
                </div>
                <div className="modal-body text-center">
                  {" "}
                  <img
                    src={
                      product.ProductImages.length > 0
                        ? product.ProductImages[0].link
                        : ""
                    }
                    alt=""
                    className="img-fluid"
                    style={{ maxWidth: "100%", maxHeight: "100vh" }} // Full-size image
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </td>
    </>
  );
};

export default Index;
