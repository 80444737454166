const uploadProduct = async (
  validationResults,
  base64ToBlobObj,
  createProduct,
  setSubmitResponse,
  setValidationResults,
  setResetForm
) => {
  // Check for errors in validationResults
  const errors = Object.entries(validationResults).filter(
    ([key, value]) => value.error !== ""
  );

  if (errors.length > 0) {
    // Display errors if any
    const errorList = errors.map(([key, value]) => (
      <li key={key}>
        <strong>{key}:</strong>{" "}
        {typeof value === "object" ? value.error : value}
      </li>
    ));
    setSubmitResponse(
      <ul className="list-unstyled text-danger">{errorList}</ul>
    );
  } else {
    // No errors, gather data and call createProduct function
    setSubmitResponse(
      <div className="text-success">Uploading the product. Please wait...</div>
    );
    // Gather data from each component
    const titleData = validationResults.Title.data;
    const categoriesData = validationResults.Categories.data;
    const sizesData = validationResults.Sizes.data;
    const productImagesData = validationResults.ProductImages.data;
    const productDescriptionData = validationResults.ProductDescription.data;
    const inStockData = validationResults.InStock.data;
    const paymentMethods = validationResults.PaymentMethods.data;
    const productCode = validationResults.ProductCode.data;
    const shippingCharges = validationResults.ShippingCharges.data;
    const productPriceData = validationResults.ProductPrice.data;

    // Convert base64 images to Blob objects
    const productImages = await base64ToBlobObj(productImagesData);

    // Combine data into a single object
    const productData = {
      Title: titleData,
      Categories: categoriesData,
      Sizes: sizesData,
      ProductImages: productImages, // Array of objects with blobObj and variant
      ProductDescription: productDescriptionData,
      InStock: inStockData,
      PaymentMethods: paymentMethods,
      ProductCode: productCode,
      ShippingCharges: shippingCharges,
      ProductPrice: productPriceData,
    };

    // Call createProduct function with the gathered data
    const response = await createProduct(productData);

    //If error occurs in mongodb side
    if (!response.success) {
      setSubmitResponse(<div className="text-danger">{response.error}</div>);
    } else {
      setSubmitResponse(
        <div className="text-success">Data has been uploaded to database</div>
      );

      setValidationResults({
        Title: { error: "Title field is required", data: "" },
        Categories: { error: "", data: "" },
        Sizes: { error: "", data: "" },
        ProductImages: { error: "Please upload some product images", data: "" },
        ProductDescription: { error: "Please describe product", data: "" },
        InStock: { error: "", data: true },
        PaymentMethods: { error: "", date: ["Cash on Delivery"] },
        ProductCode: { error: "", date: "" },
        ShippingCharges: { error: "", date: 0 },
        ProductPrice: { error: "Product price section required", data: "" },
      });

      setResetForm(true);

      // Automatically set setResetForm(false) after 5 seconds
      setTimeout(() => {
        setResetForm(false);
      }, 5000);
    }
  }
};

export default uploadProduct;
