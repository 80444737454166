import React, { useContext, useState } from "react";
import SliderContext from "../../../context/SliderContext";

const Index = () => {
  const { sliders, setSliders, loadingSliders, slidersError, deleteSlider } =
    useContext(SliderContext);

  const [deleteLoading, setDeleteLoading] = useState({
    status: false,
    sliderId: null,
  });
  const [errorDeletion, setErrorDeletion] = useState({
    message: "",
    sliderId: null,
  });

  const handleDelete = async (sliderId) => {
    setDeleteLoading({ status: true, sliderId: sliderId });
    const response = await deleteSlider(sliderId);
    if (response.success) {
      // Filter out the deleted slider
      setSliders(sliders.filter((slider) => slider._id !== sliderId));
    } else {
      setErrorDeletion({ message: "Some error occured", sliderId: sliderId });
    }
    setDeleteLoading({ status: false, sliderId: null });
  };

  // Display loader if loading sliders
  if (loadingSliders) {
    return (
      <div className="text-center">
        <p>Loading slider images...</p>
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // Display error if there's an error fetching sliders
  if (slidersError) {
    return (
      <div className="text-center">
        <p>Error loading sliders: {slidersError}</p>
      </div>
    );
  }

  // Display sliders
  return (
    <div className="row mt-5">
      {sliders.map((slider, index) => (
        <div key={index} className="col-12 position-relative">
          <div className="slider-container mb-5">
            {/* Display slider image */}
            <img
              src={slider.Image[0].link}
              className={`img-fluid`}
              alt={`Slider ${index + 1}`}
            />
            {/* Display delete button */}
            <button
              className="btn btn-danger position-absolute top-0 start-0"
              onClick={() => handleDelete(slider._id)}
              disabled={
                deleteLoading.status && deleteLoading.sliderId === slider._id
              }
            >
              Delete
            </button>
          </div>
          {/* Display loading indicator */}
          {deleteLoading.status && deleteLoading.sliderId === slider._id && (
            <div className="position-absolute top-50 start-50 translate-middle text-center bg-white rounded p-3">
              <div className="spinner-border text-danger" role="status">
                <span className="visually-hidden">Deleting...</span>
              </div>
              <p className="text-danger mt-2">Deleting. Please wait...</p>
            </div>
          )}

          {/* Display error message if deletion fails */}
          {errorDeletion.sliderId === slider._id && (
            <p className="text-danger">{errorDeletion.message}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default Index;
