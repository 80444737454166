import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import CatProContext from "../../context/CatProContext";

const Index = () => {
  const [activeLink, setActiveLink] = useState("");

  const { brand } = useContext(CatProContext);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <nav
      className="navbar-vertical-nav offcanvas offcanvas-start navbar-offcanvac"
      tabIndex="-1"
      id="offcanvasExample"
    >
      <div className="navbar-vertical">
        <div className="px-4 py-5">
          <Link to="" className="navbar-brand">
            {brand === "osc" ? (
              <>
                <img
                  style={{ width: "160px", height: "auto" }}
                  src="assets/images/logo/logo.jpg"
                  alt=""
                />
              </>
            ) : (
              <>
                <img
                  style={{ width: "100px", height: "auto" }}
                  src="assets/images/logo/logo2.jpg"
                  alt=""
                />
              </>
            )}
          </Link>
        </div>
        <div className="navbar-vertical-content flex-grow-1" data-simplebar="">
          <ul className="navbar-nav flex-column" id="sideNavbar">
            <li className="nav-item ">
              <Link
                className={`nav-link ${activeLink === "" ? "active" : ""}`}
                onClick={() => handleLinkClick("")}
                to=""
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    {" "}
                    <i className="bi bi-house"></i>
                  </span>
                  <span className="nav-link-text">Dashboard</span>
                </div>
              </Link>
            </li>
            <li className="nav-item mt-6 mb-3">
              <span className="nav-label">Store Managements</span>
            </li>
            <li className="nav-item ">
              <Link
                className={`nav-link ${
                  activeLink === "/products" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("/products")}
                to="/products"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    {" "}
                    <i className="bi bi-cart"></i>
                  </span>
                  <span className="nav-link-text">Products</span>
                </div>
              </Link>
            </li>
            {/* <li className="nav-item ">
              <Link
                className={`nav-link ${
                  activeLink === "/categories" ? "active" : ""
                }`}
                onClick={() => handleLinkClick("/categories")}
                to="/categories"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    {" "}
                    <i className="bi bi-list-task"></i>
                  </span>
                  <span className="nav-link-text">Categories</span>
                </div>
              </Link>
            </li> */}
            <li className="nav-item">
              <a
                className="nav-link   collapsed "
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#navCategoriesOrders"
                aria-expanded="false"
                aria-controls="navCategoriesOrders"
              >
                <div className="d-flex align-items-center">
                  <span className="nav-link-icon">
                    {" "}
                    <i className="bi bi-bag"></i>
                  </span>
                  <span className="nav-link-text">Orders</span>
                </div>
              </a>
              <div
                id="navCategoriesOrders"
                className="collapse "
                data-bs-parent="#sideNavbar"
              >
                <ul className="nav flex-column">
                  <li className="nav-item ">
                    <Link
                      className={`nav-link ${
                        activeLink === "/orders" ? "active" : ""
                      }`}
                      onClick={() => handleLinkClick("/orders")}
                      to="/orders"
                    >
                      All Orders
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {/* Coming Soon's */}
            <li className="nav-item mt-6 mb-3">
              <span className="nav-label">Sellers/Vendors</span>{" "}
              <span className="badge bg-light-info text-dark-info">
                Coming Soon
              </span>
            </li>
            <li className="nav-item mt-6 mb-3">
              <span className="nav-label">Customers</span>{" "}
              <span className="badge bg-light-info text-dark-info">
                Coming Soon
              </span>
            </li>
            <li className="nav-item mt-6 mb-3">
              <span className="nav-label">Reviews</span>{" "}
              <span className="badge bg-light-info text-dark-info">
                Coming Soon
              </span>
            </li>
            {/* <Later activeLink={activeLink} handleLinkClick={handleLinkClick} /> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Index;
