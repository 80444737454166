import React from "react";

const Loader = () => {
  return (
    <>
      <main className="main-content-wrapper">
        <div className="container">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px" }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Data is loading, please wait......</span>
            </div>
            <div className="ms-2">Data is loading, please wait......</div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Loader;
