import React from "react";
import ShippingCost from "./ShippingCost/Index";
import { Link } from "react-router-dom";

const Index = ({ order, setError, setLoading }) => {
  const products = order.products;
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            {/* <!-- Table --> */}
            <table className="table mb-0 text-nowrap table-centered">
              {/* <!-- Table Head --> */}
              <thead className="bg-light">
                <tr>
                  <th>Products</th>
                  <th>Color</th>
                  <th>Size</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              {/* <!-- tbody --> */}
              <tbody>
                {products.map((product, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <Link
                          to="/productsDetails"
                          state={{ product: product.productId }}
                          className="text-inherit"
                        >
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                src={product.productId?.ProductImages[0]?.link}
                                alt=""
                                className="icon-shape icon-lg"
                              />
                            </div>
                            <div className="ms-lg-4 mt-2 mt-lg-0">
                              <h5 className="mx-4 mb-0 h6">
                                {product.productId?.Title}
                              </h5>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td>
                        <span className="text-body">
                          {product.color}
                        </span>
                      </td>
                      <td>
                        <span className="text-body">
                          {product.size}
                        </span>
                      </td>
                      <td>
                        <span className="text-body">
                          Rs {product.productId?.ProductPrice.salePrice}
                        </span>
                      </td>
                      <td>{product.quantity}</td>
                      <td>
                        Rs{" "}
                        {Number(product.productId?.ProductPrice.salePrice) *
                          Number(product.quantity)}
                      </td>
                    </tr>
                  </>
                ))}
                <tr>
                  <td className="border-bottom-0 pb-0"></td>
                  <td className="border-bottom-0 pb-0"></td>
                  <td colspan="1" className="fw-medium text-dark ">
                    {/* <!-- text --> */}
                    Sub Total :
                  </td>
                  <td className="fw-medium text-dark ">
                    {/* <!-- text --> */}
                    Rs {order.summary.subTotal}
                  </td>
                </tr>
                <ShippingCost
                  order={order}
                  setError={setError}
                  setLoading={setLoading}
                />

                <tr>
                  <td></td>
                  <td></td>
                  <td colspan="1" className="fw-semi-bold text-dark ">
                    {/* <!-- text --> */}
                    Grand Total
                  </td>
                  <td className="fw-semi-bold text-dark ">
                    {/* <!-- text --> */}
                    Rs {order.summary.grandTotal}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
