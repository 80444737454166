import React, { useContext, useEffect, useState } from "react";
import Top from "./Top";
import CategoriesContext from "../../../context/CategoriesContext";
import Response from "../../../Fixed/Response/Index";
import Categories from "./Categories/Index";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import updateParentsWithChildObjects from "./Categories/parentsChilds";

const Index = () => {
  const { parents, childs, loading, error, setError } =
    useContext(CategoriesContext);

  const p = [
    {
      _id: "1",
      name: "Footwear",
      childs: [{ childId: "4" }],
      type: "Parent",
    },
    {
      _id: "2",
      name: "Clothing",
      childs: [],
      type: "Parent",
    },
  ];

  const c = [
    {
      _id: "3",
      name: "Joggers",
      type: "Child",
    },
    {
      _id: "4",
      name: "Slippers",
      type: "Child",
    },
    {
      _id: "5",
      name: "Washing Wear",
      type: "Child",
    },
  ];

  const { updatedParents, unassociatedChilds } = updateParentsWithChildObjects(
    p,
    c
  );

  const [upParents, setUpParents] = useState(updatedParents);
  const [upChilds, setUpChilds] = useState(unassociatedChilds);

  const dropedParents = (child, parent) => {
    // Remove the dropped child from upChilds
    const updatedChilds = upChilds.filter((c) => c._id !== child._id);

    // Remove the child from all parents except the dropped parent
    const updatedParentsWithoutChild = upParents.map((p) => {
      const updatedChilds = p.childs.filter((c) => c.childId._id !== child._id);
      return { ...p, childs: updatedChilds };
    });

    // Add the dropped child to the parent's child array
    const updatedParentsWithChild = updatedParentsWithoutChild.map((p) => {
      if (p._id === parent._id) {
        return {
          ...p,
          childs: [...p.childs, { childId: child }],
        };
      }
      return p;
    });

    // Update state with the new child array and parent array
    setUpChilds(updatedChilds);
    setUpParents(updatedParentsWithChild);
  };

  const droppedChilds = (child, parent) => {
    // Check if parent is undefined
    if (!parent) {
      // If parent is undefined, do nothing
      return;
    }

    // Remove the child from the parent's childs array
    const updatedParentsWithoutChild = upParents.map((p) => {
      if (p._id === parent._id) {
        const updatedChilds = p.childs.filter(
          (c) => c.childId._id !== child._id
        );
        return { ...p, childs: updatedChilds };
      }
      return p;
    });

    // Add the child back to the upChilds state
    setUpChilds([...upChilds, child]);

    // Update the state with the parent array excluding the child
    setUpParents(updatedParentsWithoutChild);
  };

  return (
    <main className="main-content-wrapper">
      <div className="container">
        <Top />
        <div className="row">
          <DndProvider backend={HTML5Backend}>
            <Categories
              parents={upParents}
              childs={upChilds}
              setChilds={setUpChilds}
              setParents={setUpParents}
              addChild={dropedParents}
              subsChild={droppedChilds}
            />
          </DndProvider>
        </div>
      </div>
      <Response loading={loading} error={error} setError={setError} />
    </main>
  );
};

export default Index;
