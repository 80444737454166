import React, { useContext, useEffect, useState } from "react";
import Uploader from "./Uploader";
import Categories from "./Categories/Index";
import SelectedImage from "./SelectedImage";
import Submit from "./Submit";
import CatProContext from "../../../context/CatProContext";
import getUniqueCategories from "./createCategories.js";

const Index = () => {
  const { products, loadingProducts, productsError } =
    useContext(CatProContext);
  const uniqueCategories = getUniqueCategories(products);

  const [selectedImage, setSelectedImage] = useState(null);
  const [category, setCategory] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (!loadingProducts && !productsError) setCategory(uniqueCategories[0]);
  }, [loadingProducts, productsError]);

  return (
    <>
      <Uploader
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        setMessage={setMessage}
      />
      <Categories categories={uniqueCategories} setCategory={setCategory} />
      <SelectedImage selectedImage={selectedImage} />
      <Submit
        message={message}
        selectedImage={selectedImage}
        category={category}
        setMessage={setMessage}
        setSelectedImage={setSelectedImage}
      />
    </>
  );
};

export default Index;
