import React from "react";
import Delete from "./Delete";
import Variants from "./Variants";

const Index = ({
  productImages,
  currentImage,
  setProductImages,
  rowIndex,
  index,
}) => {
  return (
    <>
      <Delete
        productImages={productImages}
        setProductImages={setProductImages}
        rowIndex={rowIndex}
        index={index}
      />
      <Variants
        productImages={productImages}
        currentImage={currentImage}
        setProductImages={setProductImages}
        rowIndex={rowIndex}
        index={index}
      />
    </>
  );
};

export default Index;
