import React, { useEffect, useState } from "react";

const Index = ({ setValidationResults, resetForm, product }) => {
  const [data, setData] = useState(product.ProductCode);

  //Reset data when product created
  useEffect(() => {
    if (resetForm) {
      setData({ code: "", link: "" });
    }
  }, [resetForm]);

  //Update data
  useEffect(() => {
    setValidationResults((prevResults) => {
      return {
        ...prevResults,
        ProductCode: { error: "", data: data },
      };
    });
  }, [data, setValidationResults]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="form-group mt-2">
      <label htmlFor="productCode">Product Code (Optional)</label>
      <input
        type="text"
        className="form-control"
        id="productCode"
        name="code"
        value={data.code}
        placeholder="Product Code"
        onChange={handleChange}
      />
      <label className="mt-2" htmlFor="referenceLink">
        Reference Link (Optional)
      </label>
      <input
        type="text"
        className="form-control"
        id="referenceLink"
        name="link"
        value={data.link}
        placeholder="Product Reference Link"
        onChange={handleChange}
      />
    </div>
  );
};

export default Index;