import React from "react";
import "./styles.css";

const Index = ({ product }) => {
  // Function to format date in "24 Nov 2022" pattern
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };
  return (
    <>
      <div
        className="tab-pane fade show active"
        id="product-tab-pane"
        role="tabpanel"
        aria-labelledby="product-tab"
        tabIndex="0"
      >
        <div className="my-8">
          <div className="mb-5">
            {/* <!-- text --> */}
            <h4 className="mb-1">Description</h4>
            <label className="mb-4 d-block">
              <div>
                <p>
                  <strong>Categories:</strong>
                </p>
                <div className="d-flex flex-wrap">
                  {product.Categories.map((category, index) => (
                    <div key={index} className="m-1">
                      <span className="btn btn-outline-secondary btn-sm">
                        {category}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </label>
            <div
              className="mb-0"
              dangerouslySetInnerHTML={{ __html: product.ProductDescription }}
            />
          </div>
          {/* <!-- content --> */}
          <div className="mb-5">
            <h5 className="mb-1">Stock Information</h5>
            <p className="mb-0">
              {product.InStock ? (
                <>
                  <label className="text-success">
                    This product is available in the stock.
                  </label>
                </>
              ) : (
                <>
                  <label className="text-danger">
                    This product is out of stock
                  </label>
                </>
              )}
            </p>
          </div>
          {/* <div className="mb-5">
            <h5 className="mb-1">Tags</h5>
            <p>Keywords are used to potentially search a specific product</p>
            <div className="keyword-tabs">
              {product.Keywords.map((keyword, index) => (
                <span key={index} className="keyword-tab">
                  {keyword}
                </span>
              ))}
            </div>
          </div> */}
          <div>
            <h5 className="mb-1">Created at</h5>
            <p className="mb-0">
              This product was created at {formatDate(product.Date)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
