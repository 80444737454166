import React, { useContext, useState } from "react";
import ImageUpload from "../../../../../utils/ImageUpload";
import { useNavigate } from "react-router-dom";
import CommentsContext from "../../../../../context/CommentsContext";
import ImagesContext from "../../../../../context/ImagesContext";
import CommonContext from "../../../../../context/CommonContext";

const Submite = ({ review, setReview, setComments, comments }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { createReview } = useContext(CommentsContext);
  const { uploadImagesOnCloudStorage } = useContext(ImagesContext);
  const { user } = useContext(CommonContext);
  const navigate = useNavigate();

  const handleClick = async () => {
    if (user) {
      if (!review.content) {
        setError("Written review required");
      } else {
        setError("");
        setLoading(true);
        const response = await createReview({
          heading: review.heading,
          content: review.content,
          userId: review.userId,
          productId: review.productId,
        });
        if (response.success) {
          // Now start uploading images
          if (review.images.length > 0) {
            const uploadImages = await ImageUpload(
              uploadImagesOnCloudStorage,
              review.images,
              "Reviews",
              response.review._id
            );
            if (uploadImages.success) {
              // Update comments/reviews
              const images = uploadImages.data;
              response.review.images = images; // Assign images data to response.review.images
              setComments([response.review, ...comments]);
              setError("");
              setReview({
                heading: "",
                images: [],
                content: "",
                userId: review.userId,
                productId: review.productId,
              });
            } else {
              setError("Some error occured while posting your review");
            }
          } else {
            // Update comments/reviews
            setComments([response.review, ...comments]);
            setError("");
            setReview({
              heading: "",
              images: [],
              content: "",
              userId: review.userId,
              productId: review.productId,
            });
          }
        } else {
          setError(response.error);
        }
        // Finally setLoadindg false
        setLoading(false);
      }
    } else {
      navigate("/store/account/signin");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-end" onClick={handleClick}>
        <button type="button" className="btn btn-primary">
          Submit Review
        </button>
        {loading && (
          <label className="spinner-border text-primary mx-2"></label>
        )}
      </div>
      {error && (
        <div className="alert alert-danger mt-2" role="alert">
          {error}
        </div>
      )}
    </>
  );
};

export default Submite;
