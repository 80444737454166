import React, { useContext, useState } from "react";
import OrdersContext from "../../../../context/OrdersContext";
import { useNavigate } from "react-router-dom";

const Index = ({ order, setError, setLoading }) => {
  const { updateNote } = useContext(OrdersContext);
  const [note, setNote] = useState(order.note);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setNote(e.target.value);
  };

  const handleClick = async (e) => {
    setLoading(true);
    const response = await updateNote(order._id, note);
    if (response.success) {
      navigate("/orders/orderDetail", { state: { order: response.order } });
    } else {
      setError(response.message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="card-body p-6">
        <div className="row">
          <div className="col-md-6 mb-4 mb-lg-0">
            <h6>Payment Info</h6>
            <span>Cash on Delivery</span>
          </div>
          <div className="col-md-6">
            <h5>Tracking Status</h5>
            <textarea
            disabled
              value={order.status}
              onChange={handleChange}
              className="form-control mb-3"
              rows="3"
              placeholder="Write note for order"
            ></textarea>
            {/* <label onClick={handleClick} className="btn btn-primary">
              Update Tracking Status
            </label> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
