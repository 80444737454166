import React from "react";

const Content = ({ review, setReview }) => {
  const handleChange = (e) => {
    setReview({ ...review, content: e.target.value });
  };
  return (
    <>
      <div className=" py-4 mb-4">
        {/* <!-- heading --> */}
        <h5>Add a written review (Required)</h5>
        <textarea
          value={review.content}
          onChange={handleChange}
          className="form-control"
          rows="3"
          placeholder="What did you like or dislike? What did you use this product for?"
        ></textarea>
      </div>
    </>
  );
};

export default Content;
