import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import CategoriesContext from "../../../../context/CategoriesContext";

const Delete = ({
  category,
  setAction,
  categories,
  setCategories,
  setDefaultCategories,
  defaultCategories,
}) => {
  const { deleteCategory } = useContext(CategoriesContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const deleteHandler = async (id) => {
    try {
      // Show loader
      setLoading(true);
      setError(null);

      // Delete the product
      const response = await deleteCategory(id);

      // Hide loader
      setLoading(false);

      if (response.success) {
        // Filter out the deleted category from the current categories state
        const updatedCategories = categories.filter((cat) => cat._id !== category._id);
      
        // Update categories state with the filtered categories
        setCategories(updatedCategories);
      
        // Filter out the deleted category from the default categories state
        const updatedDefaultCategories = defaultCategories.filter((cat) => cat._id !== category._id);
      
        // Update defaultCategories state with the filtered default categories
        setDefaultCategories(updatedDefaultCategories);
      
        // Reset action
        setAction(null);
      }
       else {
        // Display error message
        setError(response.message);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      // Display error message
      setError("An error occurred while deleting the category.");
      // Hide loader
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div>Deleting...</div>
      ) : error ? (
        <>
          <div>{error}</div>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => deleteHandler(category._id)}
          >
            Delete
          </button>
        </>
      ) : (
        <Link
          className="btn btn-sm btn-danger"
          onClick={() => deleteHandler(category._id)}
        >
          Delete
        </Link>
      )}
    </>
  );
};

export default Delete;
