import { createContext } from "react";
import host from "./host";

const EmailContext = createContext();

export const EmailProvider = ({ children }) => {
  // Create a user
  const emailVarification = async (email, brand) => {
    //API Call
    const response = await fetch(
      `${host}/auth/emails/emailVerify/emailVarification`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, brand }),
      }
    );
    const json = await response.json();
    return json;
  };

  // OT sender
  const sendOTP = async (email) => {
    //API Call
    const response = await fetch(`${host}/auth/emails/emailVerify/sendOTP`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const json = await response.json();
    return json;
  };

  // email message sender
  const sendEmail = async (email, subject, message) => {
    //API Call
    const response = await fetch(`${host}/auth/emails/sendMails/sendMail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, subject, message }),
    });
    const json = await response.json();
    return json;
  };

  return (
    <EmailContext.Provider value={{ emailVarification, sendOTP, sendEmail }}>
      {children}
    </EmailContext.Provider>
  );
};

export default EmailContext;
