import React, { useState } from "react";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import Error from "./Error";
import { Modal } from "bootstrap";
import Info from "./Info";
import Zoom from "../../../Modals/Zoom";

const Products = ({ products, loadingProducts, productsError, id }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const openQuickView = (product) => {
    setSelectedProduct(product);

    // Delay opening the modal by 100 milliseconds to ensure state update
    setTimeout(() => {
      const quickViewModal = document.getElementById(id);
      if (quickViewModal) {
        const bootstrapModal = new Modal(quickViewModal); // Use Modal directly
        bootstrapModal.show();
      }
    }, 100);
  };
  if (loadingProducts) {
    return (
      <>
        <Loader />
      </>
    );
  }

  if (productsError) {
    return (
      <>
        <Error />
      </>
    );
  }
  return (
    <>
      {/* <!-- col --> */}
      {products.map((product) => (
        <div className="col" key={product._id}>
          <div className="card card-product">
            <div className="card-body">
              <div className="text-center position-relative ">
                {/* <div className=" position-absolute top-0 start-0">
                  <span className="badge bg-danger">Sale</span>
                </div> */}
                <div className=" position-absolute top-0 start-0"></div>
                <label to="">
                  {" "}
                  <img
                    src={
                      product.ProductImages.length > 0
                        ? product.ProductImages[0].link
                        : ""
                    }
                    alt="Owaisy Shopping Center"
                    className="mb-3 img-fluid"
                    crossOrigin="anonymous"
                  />
                </label>
                <div className="card-product-action">
                  <label
                    to=""
                    className="btn-action"
                    onClick={() => openQuickView(product)}
                  >
                    <i
                      className="bi bi-eye"
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      title="Quick View"
                    ></i>
                  </label>

                  <label
                    to=""
                    className="btn-action"
                    data-bs-toggle="tooltip"
                    data-bs-html="true"
                    title="Compare"
                  >
                    <i className="bi bi-arrow-left-right"></i>
                  </label>
                </div>
              </div>
              <Info product={product} products={products} />
            </div>
          </div>
        </div>
      ))}
      {/* <!-- col --> */}
      {selectedProduct && <Zoom product={selectedProduct} id={id} />}
    </>
  );
};

export default Products;
