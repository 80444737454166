import React, { useContext } from "react";
import CatProContext from "../../../../../context/CatProContext";

const Index = ({ categories, setCategory }) => {
  const { products } = useContext(CatProContext);

  const getUniqueCategories = (products) => {
    const uniqueCategories = new Set();

    products.forEach((product) => {
      product.Categories.forEach((category) => {
        uniqueCategories.add(category);
      });
    });

    return Array.from(uniqueCategories);
  };

  const uniqueCategories = getUniqueCategories(products);

  const handleAddKeyword = (category) => {
    if (!categories.includes(category) && categories.length === 0) {
      setCategory((prevKeywords) => [...prevKeywords, category]);
    }
  };

  return (
    <>
      <div className="mb-2">
        <h6>Available Categories:</h6>
        {uniqueCategories.map((category, index) => (
          <span
            key={index}
            style={{
              display: "inline-block",
              backgroundColor: "#e0e0e0",
              borderRadius: "5px",
              padding: "15px",
              margin: "2px 5px",
              position: "relative",
              fontSize: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleAddKeyword(category);
            }}
          >
            {category}
          </span>
        ))}
      </div>
    </>
  );
};

export default Index;
