import React from 'react'
import { useDrop, useDrag } from "react-dnd";
import { ItemTypes } from "./ItemTypes"; // Define your item types
import ChildDraggable from "./ChildDraggable"
// import "./styles.css"

const ParentDropZone = ({ parent }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
      accept: ItemTypes.CHILD,
      drop: () => console.log("Drop in parent zone"), // Handle drop event as needed
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    });
  
    return (
      <div ref={drop}>
        {parent.childs.map((child) => (
          <div key={child._id}>
            <ChildDraggable child={child} />
          </div>
        ))}
      </div>
    );
  };

export default ParentDropZone
