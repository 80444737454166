import React, { useContext, useState } from "react";
import ImaegeUpload from "../../../utils/ImageUpload";
import CatProContext from "../../../context/CatProContext";
import CategoriesContext from "../../../context/CategoriesContext";

const Submit = ({ categoryInfo, setCategoryInfo, category }) => {
  // State variables for errors and success message
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  //Context
  const { uploadImagesOnCloudStorage } = useContext(CatProContext);
  const {
    updateCategory,
    setCategories,
    setDefaultCategories,
    defaultCategories,
  } = useContext(CategoriesContext);

  const handleSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        // No validation errors, proceed with submission
        setSuccessMessage("Updating the category...");
        const response = await updateCategory(categoryInfo, category._id);
        if (response.success) {
          // Category creation successful
          // Now decide wheather to update image or not
          if (categoryInfo.image.type === "New") {
            setSuccessMessage("Now uploading image data on cloud storage...");
            const image = [categoryInfo.image.link];
            await ImaegeUpload(
              uploadImagesOnCloudStorage,
              image,
              "Categories",
              category._id,
              handleImageUpload
            );
          } else {
            // Update the categories state by replacing the existing category with the updated one
            const updatedCategories = defaultCategories.map((cat) =>
              cat._id === response.updatedCategory._id
                ? response.updatedCategory
                : cat
            );

            setCategories(updatedCategories);
            setDefaultCategories(updatedCategories);

            setSuccessMessage("Category Updated Successfully");
          }
        } else {
          // Category creation failed, update errors state
          setErrors({ server: response.message || "Category updating failed" });
          setSuccessMessage("");
        }
      } catch (error) {
        console.error("Error updating category:", error);
        // Handle API call error
        setErrors({ server: "Something went wrong. Please try again later." });
        setSuccessMessage("");
      }
      console.log(categoryInfo);
    } else {
      // Validation errors found, update errors state
      setErrors(validationErrors);
      setSuccessMessage("");
    }
  };

  const handleImageUpload = (response) => {
    if (response.success) {
      setSuccessMessage("Category Updated Successfully");
      setErrors({});
    } else {
      // Category creation failed, update errors state
      setErrors({
        server: response.message,
      });
    }
  };

  const validateForm = () => {
    let errors = {};

    // Validation checks
    // if (!categoryInfo.image) {
    //   errors.image = "Image is required";
    // }
    if (!categoryInfo.name.trim()) {
      errors.name = "Name is required";
    }

    return errors;
  };

  //
  return (
    <>
      <button className="btn btn-primary ms-2" onClick={handleSubmit}>
        Update
      </button>
      {/* Display error messages */}
      {Object.keys(errors).length > 0 && (
        <div className="text-danger mt-2">
          {Object.values(errors).map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}
      {/* Display success message */}
      {successMessage && (
        <div className="text-success mt-2">{successMessage}</div>
      )}
    </>
  );
};

export default Submit;
