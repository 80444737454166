import React from "react";

const Index = ({ order }) => {
  return (
    <>
      <div className="col-lg-4 col-md-4 col-12">
        <div className="mb-6">
          <h6>Customer Details</h6>
          <p className="mb-1 lh-lg">
            {order.userId.name}
            <br />
            {order.userId.email}
            <br />
            {order.userId.phone}
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
