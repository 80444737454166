import React from "react";

const SelectedImage = ({ selectedImage }) => {
  return (
    <>
      {selectedImage && (
        <div className="text-center">
          <img
            src={selectedImage.base64}
            alt="Selected"
            className="img-fluid my-3"
            style={{ maxWidth: "100%" }}
          />
        </div>
      )}
    </>
  );
};

export default SelectedImage;
