import React from "react";

const Error = () => {
  return (
    <>
      <main className="main-content-wrapper">
        <div className="container">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px" }}
          >
            <div className="alert alert-danger" role="alert">
              Some error occured while loading the data
            </div>
          </div>
        </div>
      </main>
    </>
  );
};  

export default Error;
