import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import Products from "./Products/Index";
import Save from "./Save/Index"
import MoreProducts from "./MoreProducts/Index"
import AddProducts from "./AddProducts/Index"

const Index = () => {
  const location = useLocation();
  const { section } = location.state;

  const [productsIds, setProductsIds] = useState(section.products);

  return (
    <main className="main-content-wrapper">
      <div className="container">
        <div className="mb-4">
        {section.sectionName} ({productsIds.length} Products)
        </div>
        <Save productsIds={productsIds} sectionId={section._id} />
        <Products productsIds={productsIds} setProductsIds={setProductsIds} />
        <AddProducts productsIds={productsIds} setProductsIds={setProductsIds} />
        <MoreProducts productsIds={productsIds} />
      </div>
    </main>
  );
};

export default Index;
