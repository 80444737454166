import React, { useState } from "react";
import CreateNew from "./CreateNew";
import AllBadges from "./AllBadges";

const Index = ({ product, setDisplayProducts }) => {
  const [allBadges, setAllBadges] = useState(product.Badges || []);

  return (
    <>
      <AllBadges allBadges={allBadges} setAllBadges={setAllBadges} />
      <div className="mt-4">
        <CreateNew
          product={product}
          setAllBadges={setAllBadges}
          setDisplayProducts={setDisplayProducts}
          allBadges={allBadges}
        />
      </div>
    </>
  );
};

export default Index;
