import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import "./utils/styles.css";

const Index = ({ imageSrc, setProductImages, setImage }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleSave = async () => {
    try {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      const reader = new FileReader();
      reader.readAsDataURL(croppedImage);
      reader.onloadend = () => {
        setProductImages((prevProductImages) => [
          ...prevProductImages,
          { source: reader.result, type: "New", variant: null },
        ]);
      };

      // Reset the image state
      setImage(null);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {imageSrc && (
        <>
          <div className="crop-container">
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={1200 / 1200}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
          <div className="text-center">
            <button onClick={handleSave} className="btn btn-primary mt-3">
              Upload Image
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
