import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import TopBar from "./TopBar/Index";
import ListOfCategories from "./List/Index";
import Bottom from "./Bottom/Index";
import CategoriesContext from "../../context/CategoriesContext";
import Response from "../../Fixed/Response/Index"

const Index = () => {
  const {
    categories,
    setCategories,
    defaultCategories,
    setDefaultCategories,
    loading,
    error,
  } = useContext(CategoriesContext);

  return (
    <>
      <main className="main-content-wrapper">
        <div className="container">
          <Header />
          {/* <!-- row --> */}
          <div className="row ">
            <div className="col-xl-12 col-12 mb-5">
              {/* <!-- card --> */}
              <div className="card h-100 card-lg">
                <TopBar
                  setCategories={setCategories}
                  categories={categories}
                  defaultCategories={defaultCategories}
                />
                {/* <!-- card body --> */}
                <ListOfCategories
                  loading={loading}
                  error={error}
                  categories={categories}
                  setCategories={setCategories}
                  defaultCategories={defaultCategories}
                  setDefaultCategories={setDefaultCategories}
                />
                <Bottom categories={categories} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Response loading={loading} error={error} />
    </>
  );
};

export default Index;
