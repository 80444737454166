import React from "react";
import ProductDetails from "./Details/Index"
import Tabs from "./Tabs";
import Information from "./Information/Index"
import CustomerReviews from "./Reviews/Index"

const Index = ({ product }) => {
  return (
    <section className="mt-lg-14 mt-8 ">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Tabs />
            {/* <!-- tab content --> */}
            <div className="tab-content" id="myTabContent">
              <ProductDetails product={product} />
              <Information product={product} />
              <CustomerReviews product={product} />
              <div
                className="tab-pane fade"
                id="sellerInfo-tab-pane"
                role="tabpanel"
                aria-labelledby="sellerInfo-tab"
                tabIndex="0"
              >
                ...
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Index;
