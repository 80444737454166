import React, { useContext, useState } from "react";
import CategoriesContext from "../../../../context/CategoriesContext";

const Index = ({ treeData, isTreeDataChanged, setInitialTree }) => {
  const { createCategory } = useContext(CategoriesContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleUpdate = async () => {
    setLoading(true);
    const response = await createCategory(treeData);
    if (!response.success) {
      setError(response.message);
    } else {
      setInitialTree(treeData)
      setError("");
    }
    setLoading(false);
  };
  return (
    <div className="mb-2">
      <button
        onClick={handleUpdate}
        className="btn btn-primary"
        disabled={!isTreeDataChanged}
      >
        Update
      </button>
      {loading && <div>Updating data....</div>}
      {error && <div>{error}</div>}
    </div>
  );
};

export default Index;
