import { createImage, getRadianAngle } from "./utils";

// utils/cropImage.js
export const getCroppedImg = (imageSrc, pixelCrop) => {
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const width = 1200;
    const height = 1200;
    canvas.width = width;
    canvas.height = height;

    // Draw the cropped image
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      width,
      height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        if (!file) {
          reject(new Error("Canvas is empty"));
          return;
        }
        file.name = "cropped.jpg";
        resolve(file);
      }, "image/jpeg");
    });
  };

  return getCroppedImg(imageSrc, pixelCrop);
};

export default getCroppedImg;
