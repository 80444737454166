import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import SelectContext from "../../../context/SelectContext";

const Index = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { deleteSection, setDefaultSections, setSections, defaultSections } =
    useContext(SelectContext);

  const toggleCollapse = (sectionName) => {
    setActiveSection(activeSection === sectionName ? null : sectionName);
  };

  const deleteHandle = async (sectionId) => {
    setLoading(true);
    const response = await deleteSection(sectionId);
    if (response.success) {
      // Filter out the deleted section from defaultSections
      const updatedSections = defaultSections.filter(
        (section) => section._id !== sectionId
      );
      // Update the state
      setDefaultSections(updatedSections);
      setSections(updatedSections);
    } else {
      setError(response.message);
    }
    setLoading(false);
  };

  return (
    <div className="accordion" id="sectionsAccordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="sectionsHeading">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sectionsCollapse"
            aria-expanded="true"
            aria-controls="sectionsCollapse"
          >
            Sections
          </button>
        </h2>
        <div
          id="sectionsCollapse"
          className="accordion-collapse collapse hide"
          aria-labelledby="sectionsHeading"
          data-bs-parent="#sectionsAccordion"
        >
          <div className="accordion-body">
            {defaultSections.map((section, index) => (
              <div key={index}>
                <button
                  className="btn btn-link"
                  type="button"
                  onClick={() => toggleCollapse(section.sectionName)}
                >
                  {section.sectionName}
                </button>
                {activeSection === section.sectionName && (
                  <div>
                    <p>Section Name: {section.sectionName}</p>
                    <p>
                      Products: {section.products.length} products associated
                      with this section
                    </p>
                    <button
                      onClick={() => {
                        deleteHandle(section._id);
                      }}
                      className="btn btn-sm btn-danger"
                    >
                      Delete {section.sectionName}
                    </button>
                    <Link
                    state={{section: section}}
                      to="/products/updateSection"
                      className="btn btn-sm btn-primary"
                    >
                      Add or remove products
                    </Link>
                    {loading && (
                      <>
                        <label className="mx-2">Deleting section...</label>
                      </>
                    )}
                    {error && (
                      <>
                        <label className="mx-2 text-danger">{error}</label>
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
