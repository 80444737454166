import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import TopBar from "./TopBar/Index";
import ListOfProducts from "./List/Index";
import Bottom from "./Bottom/Index";
import OrdersContext from "../../context/OrdersContext";

const Index = () => {
  const {
    getOrders,
    orders,
    setOrders,
    defaultOrders,
    loadingOrders,
    ordersError,
  } = useContext(OrdersContext);

  return (
    <main className="main-content-wrapper">
      <div className="container">
        <Header />
        <div className="row ">
          <div className="col-xl-12 col-12 mb-5">
            <div className="card h-100 card-lg">
              <TopBar
                setOrders={setOrders}
                orders={orders}
                defaultOrders={defaultOrders}
                getOrders={getOrders}
              />
              <ListOfProducts
                loading={loadingOrders}
                error={ordersError}
                orders={orders}
                setOrders={setOrders}
              />
              <Bottom orders={orders} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Index;
