import React from "react";
import Name from "./Name/Index";
import MetaData from "./MetaData/Index";

const Index = ({ categoryInfo, setCategoryInfo, categories }) => {
  return (
    <>
      <h4 className="mb-4 h5 mt-5">Available Categoriess</h4>

      {/* Render names in a row */}
      <div className="row mb-3">
        {categories.length === 0
          ? "No parent created yet"
          : categories.map((item, index) => (
              <div key={index} className="col-auto">
                <span className="badge bg-primary">{item.name}</span>
              </div>
            ))}
      </div>

      <div className="row">
        <Name categoryInfo={categoryInfo} setCategoryInfo={setCategoryInfo} />

        <div className="mb-3 col-lg-12 ">{/* <!-- input --> */}</div>
        <MetaData
          categoryInfo={categoryInfo}
          setCategoryInfo={setCategoryInfo}
        />
      </div>
    </>
  );
};

export default Index;
