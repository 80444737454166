import React from "react";
import { Link } from "react-router-dom";
import DisplaySections from "./Sections/Index";

const Header = () => {
  return (
    <>
      <div className="row mb-8">
        <div className="col-md-12">
          {/* <!-- page header --> */}
          <div className="d-md-flex justify-content-between align-items-center">
            <div>
              <h2>Products</h2>
              {/* <!-- breacrumb --> */}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <a href="#" className="text-inherit">
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Products
                  </li>
                </ol>
              </nav>
            </div>
            <div className="d-flex align-items-center">
              <Link to="/products/categories-creation" className="btn btn-primary mx-2">
                Categories
              </Link>
              <DisplaySections />
              <Link to="/products/addProduct" className="btn btn-primary ms-2">
                Add Product
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
