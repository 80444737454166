import React from "react";

const Index = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="details-tab-pane"
        role="tabpanel"
        aria-labelledby="details-tab"
        tabIndex="0"
      >
        <div className="my-8">
          <div className="row">
            <div className="col-12">
              <h4 className="mb-4">Details</h4>
            </div>
            {/* <div className="col-12 col-lg-6">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>Weight</th>
                    <td>1000 Grams</td>
                  </tr>
                  <tr>
                    <th>Ingredient Type</th>
                    <td>Vegetarian</td>
                  </tr>
                  <tr>
                    <th>Brand</th>
                    <td>Dmart</td>
                  </tr>
                  <tr>
                    <th>Item Package Quantity</th>
                    <td>1</td>
                  </tr>
                  <tr>
                    <th>Form</th>
                    <td>Larry the Bird</td>
                  </tr>
                  <tr>
                    <th>Manufacturer</th>
                    <td>Dmart</td>
                  </tr>
                  <tr>
                    <th>Net Quantity</th>
                    <td>340.0 Gram</td>
                  </tr>
                  <tr>
                    <th>Product Dimensions</th>
                    <td>9.6 x 7.49 x 18.49 cm</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-12 col-lg-6">
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <th>ASIN</th>
                    <td>SB0025UJ75W</td>
                  </tr>
                  <tr>
                    <th>Best Sellers Rank</th>
                    <td>#2 in Fruits</td>
                  </tr>
                  <tr>
                    <th>Date First Available</th>
                    <td>30 April 2022</td>
                  </tr>
                  <tr>
                    <th>Item Weight</th>
                    <td>500g</td>
                  </tr>
                  <tr>
                    <th>Generic Name</th>
                    <td>Banana Robusta</td>
                  </tr>
                </tbody>
              </table>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
