import { createContext, useState, useEffect } from "react";
import host from "./host";

const SelectContext = createContext();

export const SelectProvider = ({ children }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedProducts, setCheckedProducts] = useState([]);

  // Sections Data
  const [sections, setSections] = useState([]);
  const [defaultSections, setDefaultSections] = useState([]);
  const [loadingSections, setLoadingSections] = useState(true);
  const [sectionsError, setSectionsError] = useState(null);

  // Function to toggle the checked state of a specific product
  const toggleCheckedSingle = (product) => {
    setCheckedProducts((prevCheckedProducts) => {
      if (
        prevCheckedProducts.some(
          (checkedProduct) => checkedProduct._id === product._id
        )
      ) {
        // If product is already checked, remove it from the list
        return prevCheckedProducts.filter(
          (checkedProduct) => checkedProduct._id !== product._id
        );
      } else {
        // If product is not checked, add it to the list
        return [...prevCheckedProducts, product];
      }
    });
  };

  // Function to toggle the checked state of all products
  const toggleCheckedAll = (products) => {
    setCheckedAll((prevCheckedAll) => !prevCheckedAll);
    if (!checkedAll) {
      setCheckedProducts(products);
    } else {
      setCheckedProducts([]);
    }
  };

  //Create Section
  const createSection = async (products, sectionName) => {
    //API Call
    const response = await fetch(`${host}/admin/sections/createSection`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ products, sectionName }),
    });
    const json = await response.json();
    return json;
  };

  // Render Sections
  useEffect(() => {
    async function fetchSections() {
      try {
        const response = await getSections();
        if (response.success) {
          setSections(response.data);
          setDefaultSections(response.data);
        } else {
          setSectionsError("Error fetching sections");
        }
      } catch (error) {
        setSectionsError("Error fetching sections");
      } finally {
        setLoadingSections(false);
      }
    }

    fetchSections();
  }, []);

  //get list of Products
  const getSections = async () => {
    //API Call
    const response = await fetch(`${host}/admin/sections/getSections`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    return json;
  };

  // Delete Section
  const deleteSection = async (sectionId) => {
    // API Call
    const response = await fetch(
      `${host}/admin/sections/deleteSection/${sectionId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    return json;
  };

  // Update a section
  const updateSection = async (sectionId, products) => {
    // API Call
    const response = await fetch(
      `${host}/admin/sections/updateSection/${sectionId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ products }),
      }
    );
    const json = await response.json();
    return json;
  };

  return (
    <SelectContext.Provider
      value={{
        checkedProducts,
        setCheckedAll,
        setCheckedProducts,
        toggleCheckedSingle,
        toggleCheckedAll,
        createSection,
        defaultSections,
        loadingSections,
        sectionsError,
        deleteSection,
        setDefaultSections,
        setSections,
        updateSection,
      }}
    >
      {children}
    </SelectContext.Provider>
  );
};

export default SelectContext;
