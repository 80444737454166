import { createContext, useState, useEffect } from "react";
import host from "./host";

const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [defaultCategories, setDefaultCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Render Parents
  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await getCategories();
      } catch (error) {
        //
      }
    }

    fetchCategories();
  }, []);

  //get list of Categories
  const getCategories = async () => {
    setLoading(true);
    try {
      //API Call
      const response = await fetch(`${host}/admin/categories/getCategories`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (json.success) {
        // Set all categories
        setCategories(json.categories);
        setDefaultCategories(json.categories);
      } else {
        setError("Error while fetching categories");
      }
    } catch (error) {
      setError("Some backend issue occured");
    }
    setLoading(false);
  };

  //Create Category
  const createCategory = async (treeData) => {
    //API Call
    const response = await fetch(`${host}/admin/categories/createCategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ treeData }),
    });
    const json = await response.json();
    return json;
  };

  // Function to fetch category tree
  const fetchCategoryTree = async () => {
    try {
      const response = await fetch(
        `${host}/admin/categories/fetchCategoryTree`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      return json;
    } catch (error) {
      throw error; // Propagate the error upwards
    }
  };

  // Delete a category
  const deleteCategory = async (categoryId) => {
    // API Call
    const response = await fetch(
      `${host}/admin/categories/deleteCategory/${categoryId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    return json;
  };

  // Update Category
  const updateCategory = async (updatedCategory, updateId) => {
    // API Call
    const response = await fetch(
      `${host}/admin/categories/updateCategory/${updateId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ updatedCategory }),
      }
    );
    const json = await response.json();
    return json;
  };

  return (
    <CategoriesContext.Provider
      value={{
        getCategories,
        setCategories,
        categories,
        defaultCategories,
        setDefaultCategories,
        loading,
        error,
        setError,
        createCategory,
        fetchCategoryTree,
        deleteCategory,
        updateCategory,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesContext;
