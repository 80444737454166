import { createContext, useState, useEffect } from "react";
import host from "./host";

const SliderContext = createContext();

export const SliderProvider = ({ children }) => {
  // Sliders
  const [sliders, setSliders] = useState([]);
  const [loadingSliders, setLoadingSliders] = useState(true);
  const [slidersError, setSlidersError] = useState(null);

  // Render sliders
  useEffect(() => {
    async function fetchSliders() {
      try {
        await getSliders();
      } catch (error) {
        //
      }
    }

    fetchSliders();
  }, []);

  //get list of sliders
  const getSliders = async () => {
    try {
      //API Call
      setLoadingSliders(true);
      const response = await fetch(`${host}/admin/sliders/getSliders`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      if (json.success) {
        setSliders(json.data);
        setLoadingSliders(false);
      } else {
        setSlidersError("Error fetching sliders");
        setLoadingSliders(false);
      }
    } catch (error) {
      setSlidersError(error.message);
      setLoadingSliders(false);
    } finally {
      setLoadingSliders(false);
    }
  };

  // Delete Slider
  const deleteSlider = async (sliderId) => {
    // API Call
    const response = await fetch(
      `${host}/admin/sliders/deleteSlider/${sliderId}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const json = await response.json();
    return json;
  };

  //Create Slider Category
  const createCategory = async (category) => {
    //API Call
    const response = await fetch(`${host}/admin/sliders/createCategory`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({category}),
    });
    const json = await response.json();
    return json;
  };

  return (
    <SliderContext.Provider
      value={{
        getSliders,
        sliders,
        setSliders,
        loadingSliders,
        slidersError,
        deleteSlider,
        createCategory,
      }}
    >
      {children}
    </SliderContext.Provider>
  );
};

export default SliderContext;
